import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLImageLayer from "ol/layer/Image";

const ImageLayer = ({ source, zIndex = 0 , minZoom, maxZoom}) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        const layer = new OLImageLayer({
            source,
            zIndex,
        });

        if (minZoom) {
            layer.setMinZoom(minZoom)
        }

        if (maxZoom) {
            layer.setMaxZoom(maxZoom)
        }

        map.addLayer(layer);
        layer.setZIndex(zIndex);

        return () => {
            if (map) {
                map.removeLayer(layer);
            }
        };
    }, [map, minZoom, maxZoom]);

    return null;
};

export default ImageLayer;
