import { Box, Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import React from 'react';
import SearchComponent from '../../components/SearchComponent';
import { useNavigate } from 'react-router-dom';

const MapMenu = ({ onItemClick }) => {
  const navigate = useNavigate()
  return (
    <div style={{ position: 'absolute', left: 10, top: 10, width: 350 }}>
      <Card sx={{ display: 'flex', flexDirection: 'column', borderRadius: 1, maxWidth: 400, opacity: 0.9 }}>
        <CardMedia
          component='img'
          sx={{ width: 200, alignSelf: 'left', margin: 1 }}
          image='/logo/cfdpLogo.png'
          alt='Live from space album cover'
          style={{ objectFit: 'fill', filter: 'grayscale(0%)', opacity: 1 , cursor: 'pointer'}}
          onClick={() => navigate('/')}
        />
        <CardContent>
          <Divider style={{ marginBottom: 10 }} />
          <SearchComponent onItemClick={onItemClick} style={{ marginTop: 10 }} />
        </CardContent>
      </Card>
    </div>

  );
};
export default MapMenu;
