// routes
import { PATH_DASHBOARD } from 'routes/paths';
import SvgIconStyle from 'components/SvgIconStyle';
import Iconify from 'components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  people: getIcon('ic_user_group'),
  regions: getIcon('ic_banking'),
  reports: getIcon('ic_booking'),
  blog: getIcon('ic_blog'),
};

export const MODULES = {
  dashboard: 'Dashboard',
  tasks: 'Tasks',
  search: 'Search',
  areas: 'Areas',
  management: 'Management',
  settings: 'Settings'
}

 const navConfig =  [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: MODULES.dashboard,
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.app, icon: ICONS.dashboard }],
  },
  {
    subheader: MODULES.tasks,
    items: [
      {
        title: 'Task',
        path: PATH_DASHBOARD.tasks.root,
        icon: <Iconify icon={'eva:folder-fill'} width={24} height={24} />,
      },
    ],
  },
  {
    subheader: MODULES.search,
    items: [
      {
        title: 'Advanced Search',
        path: PATH_DASHBOARD.search.root,
        icon: <Iconify icon={'eva:search-fill'} width={24} height={24} />,
      },
    ],
  },
  {
    subheader: MODULES.areas,
    items: [
      {
        title: 'Municipal',
        path: PATH_DASHBOARD.areas.root,
        icon: <Iconify icon={'eva:list-fill'} width={24} height={24} />,
      },
      {
        title: 'Partners',
        path: PATH_DASHBOARD.areas.partners,
        icon: <Iconify icon={'eva:list-fill'} width={24} height={24} />,
      },
      {
        title: 'CF',
        path: PATH_DASHBOARD.areas.indCf,
        icon: <Iconify icon={'eva:list-fill'} width={24} height={24} />,
      },
      {
        title: 'All CF',
        path: PATH_DASHBOARD.areas.allCf,
        icon: <Iconify icon={'dashicons:analytics'} width={24} height={24} />,
      },
    ],
  },
  {
    subheader: MODULES.management,
    items: [
      {
        title: 'Blog',
        path: PATH_DASHBOARD.blogs.root,
        icon: ICONS.blog,
      },
      {
        title: 'User',
        // path: PATH_DASHBOARD.blogs.root,
        path: PATH_DASHBOARD.users.index,
        icon: ICONS.user,
      },
      // {
      //   title: 'Event',
      //   path: PATH_DASHBOARD.events.root,
      //   icon:  <Iconify icon={'clarity:event-line'} width={24} height={24} />,
      // },
    ],
  },
  {
    subheader: MODULES.settings,
    items: [
      {
        title: 'Settings',
        path: '#',
        icon: <Iconify icon={'eva:file-text-fill'} width={24} height={24} />,
        children: [{ title: 'Species', path: PATH_DASHBOARD.dataCollection.dropdown.species }],
      },
    ],
  }
]

export default navConfig;
