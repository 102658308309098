import { createAsyncThunk } from '@reduxjs/toolkit';
import { OPERATOR } from 'backend/url_helper';
import APIServices from 'apiUtils/APIServices';

export const getOperators = createAsyncThunk('operators/getOperators', async (payload, thunkAPI) => {
  try {
    const api = new APIServices(OPERATOR);
    const { success, data: responseData } = await api.get();
    if (success) {
      return thunkAPI.fulfillWithValue(responseData);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return thunkAPI.rejectWithValue('Something Went wrong.');
});
