export const provinceNames = [
  'Province No. 1',
  'Madhesh Pradesh',
  'Bagmati Pradesh',
  'Gandaki Pradesh',
  'Lumbini Pradesh',
  'Karnali Pradesh',
  'Sudurpashchim Pradesh',
];

export const unique = (arr) => arr.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

export const partners = ['GFN', 'FECOFUN', 'CIPRED'];

export const roles = ['General User', 'Data Reporter', 'Data Admin', 'Admin'];

export const status = ['Offline', 'Online', 'Terminated', 'pending'];

export const nepaliEthnicity = [
  {
    id: 'BKTD',
    name: 'Brahmin / Kshetri / Thakuri / Dasnami',
  },
  {
    id: 'TRIBAL_A',
    name: 'Indigenous People ( A )',
  },
  {
    id: 'TRIBAL_B',
    name: 'Indigenous People ( B )',
  },
  {
    id: 'MADHESI',
    name: 'Madhesi',
  },
  {
    id: 'DALIT',
    name: 'Dalit',
  },
  {
    id: 'MUSLIM',
    name: 'Muslim',
  },
  {
    id: 'OTHERS',
    name: 'Others',
  },
];

export const nepaliEthnicityB = [
  {
    id: 'BKTD',
    name: 'Brahmin / Kshetri / Thakuri / Dasnami',
  },
  {
    id: 'TRIBAL',
    name: 'Indigenous',
  },
  {
    id: 'MADHESI',
    name: 'Madhesi',
  },
  {
    id: 'DALIT',
    name: 'Dalit',
  },
  {
    id: 'MUSLIM',
    name: 'Muslim',
  },
  {
    id: 'OTHERS',
    name: 'Others',
  },
];
export const nepaliEthnicityC = [
  {
    id: 'BKTD',
    name: 'Brahmin / Kshetri / Thakuri / Dasnami',
  },
  {
    id: 'INDIGENOUS',
    name: 'Indigenous People',
  },
  {
    id: 'MADHESI',
    name: 'Madhesi',
  },
  {
    id: 'DALIT',
    name: 'Dalit',
  },
  {
    id: 'MUSLIM',
    name: 'Muslim',
  },
  {
    id: 'OTHERS',
    name: 'Others',
  },
];
export const genderTypes = ['FEMALE', 'MALE', 'OTHERS'];

export const peopleDetails = {
  total: '',
  female: '',
  male: '',
  brtd: '',
  tA: '',
  tB: '',
  ma: '',
  da: '',
  mus: '',
  others: '',
};

export const MAP_DATA_TYPE = [
  { value: 'GPS_FIELD_BOOK', label: 'GPS Field Book' },
  { value: 'TABLE_SURVEY', label: 'Table Survey' },
  { value: 'NONE', label: 'None' },
];

export const geographicalRegion = [
  { id: 'HIGH_HIMAL', value: 'High Himal' },
  { id: 'HIGH_MOUNTAIN', value: 'High Mountain' },
  { id: 'MID_HILL', value: 'Mid Hill' },
  { id: 'CHURE', value: 'Chure' },
  { id: 'TERAI', value: 'Terai' },
];
export const speciesTypes = [
  { id: 'PLANT', value: 'Plant' },
  { id: 'ANIMAL', value: 'Animal' },
];
export const plantTypes = [
  { id: 'WOOD', value: 'Wood' },
  { id: 'NON_WOOD', value: 'Non Wood' },
];
export const animalTypes = [
  { id: 'CRAWLING', value: 'Crawling' },
  { id: 'BIRD', value: 'Bird' },
  { id: 'MAMMALS', value: 'Mammals' },
  { id: 'INSECTS', value: 'Insects' },
  { id: 'AMPHIBIAN', value: 'Amphibian' },
  { id: 'EXTINCT', value: 'Extinct' },
];

export const getSpeciesSubTypes = (speciesType) => {
  if (speciesType === 'PLANT') {
    return plantTypes;
  }
  if (speciesType === 'ANIMAL') {
    return animalTypes;
  }
  return [];
};
