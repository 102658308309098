export const generateSlug = ( title ) => {
    const slug =  title
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, '-') // Replace spaces with dashes
      .replace(/[^\w-]+/g, '') // Remove non-word characters (except for dashes)
      .replace(/--+/g, '-') // Replace consecutive dashes with a single dash
      .trim(); // Trim leading and trailing dashes (if any)
      return slug;
  }

 const _stringToColor = ( string ) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    const color = '#9CA3AF';
    // let color = '#';
  
    // for (i = 0; i < 3; i += 1) {
    //   const value = (hash >> (i * 8)) & 0xff;
    //   color += `00${value.toString(16)}`.slice(-2);
    // }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
export const stringAvatar = ( name ) => {
    if (!name) return null;
    const avatar =  {
      sx: {
        bgcolor: _stringToColor(name),
        color: 'black',
        height:28,
        width:28,
        position: 'absolute',
        zIndex:9,
        fontSize:'0.75rem'
      },
      children: `${name?.split(' ')[0]?.[0]?? ''} ${name?.split(' ')[1]?.[0]??' '}`,
    };
    return avatar;
  }


  export const objectToQueryString = (obj) => {
    return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
}

const ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  PARTNER: 'PARTNER',
  OTHER: 'OTHER'
};

export const filterNavConfigByRole = (navConfig, userRole) => {
  if (userRole === ROLE.SUPER_ADMIN) {
    return navConfig; // Return all submenus for SUPER_ADMIN
  }

  if (userRole === ROLE.PARTNER) {
    // Return only 'Municipal', 'Partners', and 'CF' submenus for PARTNER
    return navConfig.map(section => {
      if (section.subheader === 'Areas') {
        return {
          ...section,
          items: section.items.filter(item => item.title === 'Municipal' || item.title === 'Partners' || item.title === 'CF')
        };
      }
      return section;
    });
  }

  // Return only 'All CF' and 'CF' submenus for other roles
  return navConfig.map(section => {
    if (section.subheader === 'Areas') {
      return {
        ...section,
        items: section.items.filter(item => item.title === 'All CF' || item.title === 'CF')
      };
    }
    return section;
  });
}


