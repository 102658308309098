import { PersonOutline } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Stack, Typography, styled, useTheme } from '@mui/material';
import APIServices from 'apiUtils/APIServices';
import { post } from 'apiUtils/api';
import Image from 'components/Image';
import React, { useEffect, useState } from 'react';
import { PostItemSkeleton } from 'sections/@dashboard/blogs/post-skeleton';
import { fShortMonthDate } from 'utils/formatTime';
import { getImgSrc } from 'utils/getImgSrc';
import { stringAvatar } from 'utils/string.util';

const NewsAndArticleCard = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: 'white',
  img: {
    width: '100%',
    height: '240px',
    zIndex: '100',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '.MuiBox-root': {
    borderRadius: '8px',
    padding: '24px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
}));

export default function AllPosts({posts}) {
  const theme = useTheme();
 
  return (
    <Box paddingX={{ xs: '30px', sm: '10px', lg: '112px' }} sx={{ pt: '48px', pb: '40px' }}>
      <Typography textAlign={'center'} fontSize={'32px'} fontWeight={'600'} marginY={3} letterSpacing={0}>
        All Posts
      </Typography>
      { posts?.length > 0 && 
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 3 }}>
        {posts.map((post, index) => {
          return (
            <Grid item xs={1} key={index}>
              <NewsAndArticleCard>
                <img src={getImgSrc(post?.coverImage)} alt={post.slug} sx={{ height: 0.5, borderRadius: 1.5 }} />
                {/* <Image alt={post?.title} src={getImgSrc(post?.coverImage)} sx={{ height: 0.5, borderRadius: 1.5 }} /> */}
                <Box>
                  <Stack spacing={2} direction="row" justifyContent="left" alignItems="flex-start" sx={{ mb: '2%' }}>
                    <Stack>
                      <Avatar
                        alt={post?.authorName}
                        
                        // sx={{ position: 'absolute', top: 10, right: 10, zIndex: 9, height: 14, width: 14 }}
                        {...stringAvatar(post?.authorName)}
                      />
                    </Stack>
                    <Stack sx={{ minWidth: 0 }}>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'400'}
                        color={theme.palette.grey[600]}
                        display={'flex'}
                        gap={2}
                        alignItems={'center'}
                        sx={{marginTop:'1%', paddingLeft:'1rem'}}
                      >
                        {post?.authorName} &nbsp; | &nbsp; {fShortMonthDate(post?.createdOn)}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Typography fontSize={'1rem'} fontWeight={'600'} marginY={2} letterSpacing={0}>
                    {post.title}
                  </Typography>

                  {post.excerpt && (
                    <Typography
                      fontSize={'0.8rem'}
                      fontWeight={'400'}
                      marginY={2}
                      letterSpacing={0}
                      dangerouslySetInnerHTML={{ __html: post.excerpt }}
                    />
                  )}

                  <span>
                    <Button variant="contained" rel="noopener" sx={{ padding: '.7rem 2rem' }} href={`/blog/${post.slug}`}>
                      Read More
                    </Button>
                  </span>
                </Box>
              </NewsAndArticleCard>
            </Grid>
          );
        })}
      </Grid>
      }
    </Box>
  );
}

