import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func
};

export default function RHFRadioGroup({ name, options = [], getOptionLabel, setValue, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other}
            // onChange={(e) => { if (typeof setValue === 'function') setValue(name, forceHtmlRadioOutputToBeBoolean(e.target.value)) }}
          >
            {options?.map((option, index) => (
              <FormControlLabel
                key={option.value ?? option}
                value={option.value ?? option}
                control={<Radio />}
                label={getOptionLabel?.length ? getOptionLabel[index] : option.label ?? option}
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}


export function forceHtmlRadioOutputToBeBoolean(value) {
  let returnValue = null
  if (value === 'true') {
    returnValue = true;
  } else if (value === 'false') {
    returnValue = false;
  } else if (value === null) {
    // Sometimes the radio group can be optional, allow this case
    returnValue = null;
  } else {
    throw new Error(`this radio group is supposed to only manage string boolean values ("true", "false"), or can optionally be null.`);
  }
  return returnValue
}