import * as yup from 'yup';
import {
  nonNegativeReqiredNumber,
  countSchema,
  numberSchema,
  requiredNumberSchema,
  positiveFloatingPointSchema,
} from './schemas';
import NepaliDate from 'nepali-date-converter';

const speciesSchema = yup.object().shape({
  name: yup.string(),
});

const dateSchema = yup
  .string()
  .test('is-past', 'Past date is required', async (value, testContext) => new Date(value) < new Date());
const majorProgramSchema = yup.object().shape({
  intervalType: yup.string(),
  type: yup.string(),
  budgetSource: yup.string(),
  amount: nonNegativeReqiredNumber,
});

const afforestationSchema = yup.object().shape({
  intervalType: yup.string(),
  type: yup.string(),
  budgetSource: yup.string(),
  afforestationArea: nonNegativeReqiredNumber,
  plantCount: countSchema,
  mainSpeciesIds: yup.array().of(yup.number().required('Required')).required('Required'),
  successRate: nonNegativeReqiredNumber,
});

const woodSaleSchema = yup.object().shape({
  intervalType: yup.string(),
  internalDistribution: nonNegativeReqiredNumber,
  internalSales: nonNegativeReqiredNumber,
  externalSales: nonNegativeReqiredNumber,
  mainSpeciesIds: yup.array().of(nonNegativeReqiredNumber).label('Species').required(),
});

const firewoodGrassSchema = yup.object().shape({
  intervalType: yup.string(),
  packs: countSchema,
  type: yup.string().default('FIRE_WOOD'),
});

const fireControlSchema = yup.object().shape({
  intervalType: yup.string(),
  fireControlLine: nonNegativeReqiredNumber.required('Required Data'),
});

const humanLoseSchema = yup.object().shape({
  intervalType: yup.string(),
  ethnicity: yup.string(),

  maleCount: countSchema,
  femaleCount: countSchema,
  othersCount: countSchema,
});

const otherLoseSchema = yup.object().shape({
  intervalType: yup.string(),
  type: yup.string(),
  quantity: nonNegativeReqiredNumber.when('type', (type, schema, val) => {
    return ['PETS', 'HOUSEHOLD'].includes(type) ? schema.integer() : schema;
  }),
});

const fireLandslideDamageSchema = yup.object().shape({
  intervalType: yup.string(),
  type: yup.string().required('Required Data'),
  humanLosses: yup.array().of(humanLoseSchema),
  otherLosses: yup.array().of(otherLoseSchema),
  naturalCause: yup.bool(),
});

const landslideDetailSchema = yup.object().shape({
  intervalType: yup.string(),
  landslideCount: countSchema,
  affectedArea: nonNegativeReqiredNumber,
  // landslideType: yup.string(),
});

const humanAnimalEncounterSchema = yup.object().shape({
  intervalType: yup.string(),
  animalSpecies: yup.array().of(numberSchema),
  type: yup.string(),
  maleCasualty: countSchema,
  femaleCasualty: countSchema,
  damages: yup.array().of(otherLoseSchema),
  humanWildlifeConflicts: yup.array().of(
    yup.object().shape({
      type: yup.string().required('Required'),
      frequency: countSchema,
      wildlifeSpeciesId: numberSchema.required('Required'),
    })
  ),
});

const nonWoodCultivationSchema = yup.object({
  measurementType: yup.string(),
  speciesId: requiredNumberSchema,
  quantity: positiveFloatingPointSchema,
});

export const developmentManagementFormSchema = yup.object().shape({
  // fiscalYearStart: yup.date.required(),
  // fiscalYearEnd: yup.date.required(),
  fiscalYear: yup.string().required('Required'),
  majorPrograms: yup.array().of(majorProgramSchema),
  afforestation: yup.array().of(afforestationSchema),
  woodSales: woodSaleSchema,
  fireWoodCollection: firewoodGrassSchema,
  grassCollection: firewoodGrassSchema,
  fireControlPreviousFiscalYear: fireControlSchema,
  fireControlTillPreviousFiscalYear: fireControlSchema,
  initialEnvironmentEffectStatus: yup.bool().required('Required'),
  hasFireOccurred: yup.bool().required(),
  fireBurntOut: yup
    .object()
    .nullable()
    .when('hasFireOccurred', {
      is: true,
      then: yup.object().shape({
        burntOutCount: countSchema,
        affectedArea: nonNegativeReqiredNumber,
      }),
    }),
  fireDamages: yup.object().nullable().when('hasFireOccurred', {
    is: true,
    then: fireLandslideDamageSchema.required(),
  }),
  hasLandslideOccurred: yup.bool().required(),
  landslideDamages: yup.object().nullable().when('hasLandslideOccurred', {
    is: true,
    then: fireLandslideDamageSchema.required(),
  }),
  landslide: yup.object().nullable().when('hasLandslideOccurred', {
    is: true,
    then: landslideDetailSchema.required(),
  }),
  hasHumanAnimalEncounter: yup.bool(),
  animalEncounter: yup.object().nullable().when('hasHumanAnimalEncounter', {
    is: true,
    then: humanAnimalEncounterSchema.required(),
  }),
  nonWoodCultivationDone: yup.bool(),
  nonWoodCultivation: yup.array().when('nonWoodCultivationDone', {
    is: true,
    then: yup.array().of(nonWoodCultivationSchema).required(),
  }),
});
