import DashContainer from "./DashContainer";
import DashPieChart from "./DashPieChart";
import DashBarGraph from "./DashBarGraph";
import DashText from "./DashText";
import DashList from "./DashList";
import DashCompositeNumeric from "./DashCompositeNumeric";
import DashHeader from "./DashHeader";
import TabbedStackGraph from './TabbedStackGraph';
import DashLinkedList from './DashLinkedList';

/**
 * Types
 * ---------
 * Numeric
 * CompositeNumeric
 * List
 * Badge
 * PieChart
 * BarGraph
 */

const DashAnalyticsItem = (props) => {
    const { graphType, data } = props;

    // if (data === null) {
    //     return null;
    // }

    switch (graphType) {
        case "Numeric":
            return <DashText {...props} />;
        case "CompositeNumeric":
            return <DashCompositeNumeric {...props} />;
        case "List":
            return <DashList {...props} />;
        case "Badge":
            return null;
        case "PieChart":
            console.log('ITEM', props)
            return <DashPieChart {...props} />;
        case "BarGraph":
            return <DashBarGraph {...props} />;
        case "TabbedStackGraph":
            return <TabbedStackGraph {...props} />;
        case "LinkedList":
            return <DashLinkedList {...props} />;
        default:
            return null;
    }
};

export {
    DashContainer,
    DashAnalyticsItem,
    DashHeader
}
