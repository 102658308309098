import {
    Box,
    Card,
    Typography,
} from "@mui/material";

const DashContainer = ({ title, children, nospacing, size }) => {

    const containerWidth = () => {
        if (size === "small") {
            return "33%"
        }

        if (size === "medium") {
            return "50%"
        }

        return "100%"
    }

    return (
        // <Box sx={{
        //     px: 1, py: 1,
        //     display: "flex",
        //     alignItems: "center",
        //     flexDirection: "column",
        //     width: containerWidth
        // }}>
        <Box sx={{
            width: containerWidth,
            px: 0.5,
            py: 0.5
        }}>
            <Card
                variant="outlined"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    minHeight: 150,
                    borderRadius: "0px",
                    px: nospacing ? 0 : 1,
                    py: nospacing ? 0 : 1,
                    // px: 1, py: 1
                }}
            >
                {title && <Typography variant="h6" sx={{ mb: 2 }}>
                    {title}
                </Typography>}
                <Box sx={{}}>{children}</Box>
            </Card>
        </Box>
    );
};

export default DashContainer;
