import { Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import ReportsStatWidget from './ReportsStatWidget';

export default function  RegionTaskOverview () {
  return(
    <Card>
      {/* <CardHeader title="Region Task Overview" /> */}
      {/* <CardContent>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <ReportsStatWidget stat={0} title={"Submitted"}/>
          </Grid>
          <Grid item md={4}>
            <ReportsStatWidget stat={0} title={"Reviewed"}/>
          </Grid>
          <Grid item md={4}>
            <ReportsStatWidget stat={0} title={"Published"}/>
          </Grid>
        </Grid>
      </CardContent> */}
      <CardActions />
    </Card>
  )
}