import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { isValidToken, setSession } from 'utils/jwt';
import APIServices from 'apiUtils/APIServices';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: false,
  userDetails: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  ERROR: (state, action) => {
    const { error } = action.payload;

    return {
      ...state,
      error,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          const user = JSON.parse(window.localStorage.getItem('authUser'));
          setSession(accessToken, user);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              userDetails: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            userDetails: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (data) => {
    try {
      const formData = {
        username: data.username,
        password: data.password,
      };
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      const res = await fetch(`${process.env.REACT_APP_HOST_API_KEY}auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .catch((error) => error.json());
      if (res.accessToken) {
        const { accessToken } = res;
        setSession(accessToken, res);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: res,
          },
        });
      } else {
        dispatch({
          type: 'ERROR',
          payload: {
            error: 'Internal Server Error',
          },
        });
      }
    } catch (err) {
      dispatch({
        type: 'ERROR',
        payload: {
          error: 'Internal Server Error',
        },
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
