import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Stack,
  Grid,
  Tooltip,
  MenuItem,
  Menu,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Badge,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import QuickSearch from '../regions/sections/QuickSearch';
import Label from 'components/Label';
import Scrollbar from 'components/Scrollbar';
import { useSnackbar } from 'notistack';
import APIServices from 'apiUtils/APIServices';
import useUser from 'hooks/useUser';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { PATH_DASHBOARD } from 'routes/paths';
import Search from '../../../../components/Search';
import { Box } from '@mui/system';
import { Mail, MapOutlined } from '@mui/icons-material';
import MapDialog from '../../../../components/MapDialog';
import Iconify from 'components/Iconify';
import { RHFEditor } from 'components/hook-form';
import Editor from 'components/editor';

export default function ReportsList() {
  const userData = useUser();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openRejectReasonDialog, setOpenRejectReasonDialog] = useState(false);
  const [openStatusUpdateConfirmationDialog, setOpenStatusUpdateConfirmationDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rpt, setRpt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();
  const { rId: regionId } = useParams();
  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'reportedAt', label: 'Reported At.', alignRight: false },
    { id: 'dataCollectedOn', label: 'Data Collected On.', alignRight: false },
    { id: 'reporter', label: 'Reporter.', alignRight: false },
    { id: 'region', label: 'Region.', alignRight: false },
    { id: 'completeness', label: 'Progress', alignRight: false },
    { id: 'mapped', label: 'Mapped', alignRight: false },
    { id: 'stats', label: 'Status ', alignRight: false },
    { id: 'a' },
    { id: 'b' },
  ];

  const [reports, setReports] = useState([]);
  const [filter, setFilter] = useState({});
  const isReporter = userData?.authorities?.includes('REPORTER');
  const isPartner = userData?.authorities?.includes('PARTNER');
  const isOperator = userData?.authorities?.includes('OPERATOR');
  const userRegions = userData?.regions;

  const fetchReports = async (filter = {}) => {
    if (userData) {
      const urlParams = new URLSearchParams();
      Object.keys(filter).forEach((key) => {
        if (filter[key]) {
          urlParams.append(key, filter[key]);
        }
      });
      if (regionId) urlParams.append('localRegion', regionId);

      let url = '';
      if (urlParams.toString()) {
        url = `reporting?${urlParams.toString()}`;
      } else {
        url = 'reporting';
      }
      const { data, success } = await new APIServices(url).get();
      if (success) {
        if (isReporter) {
          if (regionId) {
            setReports(
              data?.items.filter(
                (r) =>
                  r?.forest?.localRegion?.id === parseInt(regionId, 10) &&
                  (r.reporter.id === parseInt(userData?.userId, 10) ||
                    userRegions?.map((r) => r.id).includes(r?.forest?.localRegion?.id))
              )
            );
          } else {
            setReports(
              data?.items.filter(
                (r) =>
                  r.reporter.id === parseInt(userData?.userId, 10) ||
                  userRegions?.map((r) => r.id).includes(r?.forest?.localRegion?.id)
              )
            );
          }
        } else {
          setReports(data?.items);
        }
      }
    }
  };

  const getColor = (report) => {
    if (report.status === 'approve' && report.mapped) {
      return 'green'; // Highlight with green color if status is approve and mapped
    }
    if (!report.mapped) {
      return 'red'; // Highlight with red color if there is no map
    }
    return 'blue'; // Highlight with blue color for other cases (e.g., status is draft)
  };

  useEffect(() => {
    fetchReports();
  }, [userData]);

  const handleMenuClick = (event, report) => {
    if (open) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setRpt(report);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setRpt(null);
  };

  const handleStatusSubmit = async (status) => {
    setLoading(true);
    const url = `reporting/${rpt.id}/${status.toLowerCase()}`;
    const { data, success } = await new APIServices(url).put('', { comment: reason });
    if (success) {
      enqueueSnackbar('Status has been successfully updated', { variant: 'success' });
      fetchReports();
    } else {
      enqueueSnackbar(data?.data?.message, { variant: 'error' });
    }
    setLoading(false);
    handleMenuClose();
    handleRejectReasonDialogClose();
    handleRejectDialogClose();
    handleStatusUpdateConfirmationClose();
  };

  const handleRejectStatusSubmit = async () => {
    setOpenRejectDialog(true);
  };

  const handleViewReport = () => {
    navigate(`/dashboard/tasks/report/view/${rpt?.forest.id}`);
    handleMenuClose();
  };

  const handleEditReport = () => {
    navigate(
      `${PATH_DASHBOARD.tasks.root}/${
        regionId || rpt?.forest?.localRegion?.id
      }/new-report/data-collection?reportingId=${rpt?.id}&forestId=${rpt?.forest?.id}`
    );
    handleMenuClose();
  };

  const handleRejectDialogClose = () => {
    setOpenRejectDialog(false);
    handleMenuClose();
  };

  const handleRejectReasonDialogClose = () => {
    setOpenRejectReasonDialog(false);
  };
  const handleEditorChange = (value) => {
    setReason(value);
  };

  const handleStatusUpdateConfirmationClose = () => {
    
    setOpenStatusUpdateConfirmationDialog(false);
  }
  const open = Boolean(anchorEl);
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Card sx={{ width: '100%' }}>
        <CardHeader title="Reports" />
        <CardContent>
          <Stack>
            <Grid container direction={'row'} width={'100%'} color={'black'} spacing={5}>
              {!isReporter && (
                <Grid item xs={4}>
                  <Search
                    src={'search/reporter'}
                    label={'Reporter'}
                    paramName={'query'}
                    labelField={'fullName'}
                    getLabel={(reporter) => `${reporter.fullName} (${reporter.username})`}
                    onChange={async (reporter) => {
                      await setFilter({ ...filter, reporter: reporter.id });
                      fetchReports({ ...filter, reporter: reporter.id });
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <Search
                  src={'search/forest'}
                  label={'Community Forest'}
                  paramName={'query'}
                  labelField={'name'}
                  getLabel={(forest) => `${forest.name} (${forest.localRegion.name})`}
                  onChange={async (forest) => {
                    await setFilter({ ...filter, forest: forest.id });
                    fetchReports({ ...filter, forest: forest.id });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Search
                  src={'search/local-region'}
                  label={'Local Region'}
                  paramName={'query'}
                  labelField={'name'}
                  getLabel={(region) => region.name}
                  onChange={async (region) => {
                    await setFilter({ ...filter, localRegion: region.id });
                    fetchReports({ ...filter, localRegion: region.id });
                  }}
                />
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Scrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S.N</TableCell>
                    {TABLE_HEAD.map((head) => (
                      <TableCell key={head.id} align={head.alignRight ? 'right' : 'left'}>
                        {head.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((report, idx) => (
                    <TableRow key={report.id}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{report.forest?.name} </TableCell>
                      <TableCell>{moment(report?.createdOn).format('YYYY-MM-DD HH:mm')}</TableCell>
                      <TableCell>{report.dataCollectedOn}</TableCell>
                      <TableCell>{report.reporter?.fullName}</TableCell>
                      <TableCell>{report.forest?.localRegion.name}</TableCell>
                      <TableCell>{`${report.completeness ?? 0} %`}</TableCell>
                      <TableCell>
                        <IconButton
                          disabled={!report.mapped}
                          onClick={async () => {
                            await setSelectedReport(report.id);
                            setOpenDialog(!openDialog);
                          }}
                        >
                          <MapOutlined color={report.mapped === true ? 'primary' : 'disabled'} />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {report.comment ? (
                          // <Tooltip title="Test">
                          <Badge
                            color="secondary"
                            variant="dot"
                            onClick={() => {
                              setOpenRejectReasonDialog(true);
                              setReason(report.comment);
                            }}
                          >
                            <Label style={{ backgroundColor: getColor(report), color: 'white' }}>
                              {/* <Tooltip title="Add" arrow> */}
                              {report?.status}
                              {/* </Tooltip> */}
                            </Label>
                          </Badge>
                        ) : (
                          <Label style={{ backgroundColor: getColor(report), color: 'white' }}>{report.status}</Label>
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            navigate(`/cf/dashboard/${report.forest.id}?type=FOREST`);
                          }}
                        >
                          <DashboardOutlinedIcon color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleMenuClick(e, report)}
                        >
                          <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            sx={{ color: 'primary' }}
                            onClick={() => {
                              handleViewReport();
                              handleMenuClose();
                            }}
                          >
                            <Iconify icon={'lets-icons:view-duotone'} width={20} height={20} />
                            View
                          </MenuItem>
                          {!isPartner && rpt?.status !== 'APPROVED' && !(rpt?.status === 'UNDER_REVIEW' && isReporter) && (
                            <MenuItem
                              sx={{ color: 'primary' }}
                              onClick={() => {
                                handleEditReport();
                                handleMenuClose();
                              }}
                            >
                              <Iconify icon={'uil:edit'} width={16} height={16} />
                              Edit
                            </MenuItem>
                          )}
                          <Divider orientation="horizontal" flexItem />
                          {rpt?.status === 'DRAFT' && (
                            <>
                              <MenuItem
                                sx={{ color: 'primary.main' }}
                                onClick={() => {
                                  setSelectedStatus('SUBMIT');
                                  setOpenStatusUpdateConfirmationDialog(true);
                                  // handleStatusSubmit('SUBMIT');
                                }}
                              >
                                <Iconify icon={'iconoir:submit-document'} width={16} height={16} />
                                Submit
                              </MenuItem>
                              <MenuItem
                                sx={{ color: 'error.main' }}
                                onClick={() => {
                                  setSelectedStatus('DISCARD');
                                  setOpenStatusUpdateConfirmationDialog(true);

                                  // handleStatusSubmit('DISCARD');
                                }}
                              >
                                <Iconify icon={'codicon:discard'} width={16} height={16} />
                                Discard
                              </MenuItem>
                            </>
                          )}
                          {rpt?.status === 'UNDER_REVIEW' && (
                            <>
                              {!isReporter && (
                                <MenuItem
                                  sx={{ color: 'primary.main' }}
                                  onClick={() => {
                                    setSelectedStatus('APPROVE');
                                    setOpenStatusUpdateConfirmationDialog(true);
                                    // handleStatusSubmit('APPROVE');
                                  }}
                                >
                                  <Iconify icon={'mdi:approve'} width={16} height={16} />
                                  Approve
                                </MenuItem>
                              )}
                              {!isReporter && (
                                <MenuItem
                                  sx={{ color: 'error.main' }}
                                  onClick={() => {
                                    handleRejectStatusSubmit();
                                  }}
                                >
                                  <Iconify icon={'codicon:discard'} width={16} height={16} />
                                  Reject
                                </MenuItem>
                              )}
                            </>
                          )}
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    id="tablePagination"
                    count={reports?.length || 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                  />
                </TableFooter>
              </Table>
              <MapDialog
                dialogOpen={openDialog}
                reportingId={selectedReport}
                toggleDialog={() => setOpenDialog(!openDialog)}
              />
              <Dialog
                open={openRejectDialog}
                onClose={handleRejectDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Add Rejection reason'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {/* <RHFEditor simple name="content" /> */}
                    <Editor
                      value={reason}
                      onChange={handleEditorChange}
                      simple
                      plaholder={'Write a rejection reason here.'}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRejectDialogClose} sx={{ color: 'secondary.light' }}>
                    Cancel
                  </Button>
                  {reason.length > 5 && (
                    <Button
                      autoFocus
                      sx={{ bgcolor: 'primary.main', color: 'white' }}
                      onClick={() => {
                        handleStatusSubmit('REJECT');
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </DialogActions>
              </Dialog>

              <Dialog
                open={openRejectReasonDialog}
                onClose={handleRejectReasonDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Rejection reason'}</DialogTitle>
                <DialogContent>
                  <Typography variant="body2" gutterBottom>
                    <p dangerouslySetInnerHTML={{ __html: reason }} />
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRejectReasonDialogClose} sx={{ color: 'secondary.light' }}>
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openStatusUpdateConfirmationDialog}
                onClose={handleStatusUpdateConfirmationClose}
                aria-labelledby="alert-dialog-title-2"
                aria-describedby="alert-dialog-description-2"
              >
                <DialogTitle id="alert-dialog-title-2">{'Confirm!'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description-2">Do you want to update the status to <strong>{selectedStatus}</strong> for the forest <strong>
                  {rpt?.forest?.name}
                  </strong>
                  ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleStatusUpdateConfirmationClose} sx={{ color: 'secondary.light' }}>Cancel</Button>
                  <Button onClick={() => {
                    handleStatusSubmit(selectedStatus)
                  }}   autoFocus
                  sx={{ bgcolor: 'primary.main', color: 'white' }}>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Scrollbar>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
