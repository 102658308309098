import { Stack, Typography } from '@mui/material';
import TableView from './TableView';
import QuestionnaireView from './QuestionnaireView';
import ListView from './ListView';
import GridKeyValueView from './GridKeyValueView';
import QnAView from './QnAView';

const TextualAnalyticView = ({item}) => {
  return (
    <Stack direction={'column'}>
      {item.title ? <Typography marginBottom={2} color={'primary'} variant={'h5'}>{item.title}</Typography> : undefined}
      {item.subtitle ? <Typography variant={'h5'} style={{color: 'gray'}}>{item.subtitle}</Typography> : undefined }
      <VisualizationComponent item={item} />
    </Stack>
  );
}

export default TextualAnalyticView;

const VisualizationComponent = ({item}) => {
  switch (item.type){
    case 'QA':
      return <QnAView data={item}/>
    case 'Table':
      return <TableView table={item}/>
    case 'Questionnaire':
      return <QuestionnaireView data={item}/>

    case 'List':
      return <ListView data={item}/>
    case 'GridKeyValue':
      return <GridKeyValueView data={item}/>
    default:
      return null
  }
}
