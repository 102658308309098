import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchNotFound from './SearchNotFound';
import { Box, Card, CardContent, CardMedia, CircularProgress, IconButton, InputAdornment, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


const SearchComponent = ({onItemClick}) => {
  const [forestName, setForestName] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleSearch();
  },[forestName])

  const fetchData = async () => {
    setLoading(true)
    const query = forestName;
    if (query !== '') {
      try {
        const response = await fetch(`https://www.cfdataportal.com/api/search/forest?query=${query}`);
        const data = await response.json();
        setSearchResults(data || []);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
      setLoading(false)
    }
  };

  const handleSearch = () => {
    if (forestName.trim() !== '' && forestName.length > 0) {
      fetchData();
    }else{
      setSearchResults([])
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <TextField
          label="Search forest by name"
          // variant="outlined"
          size="medium"
          value={forestName}
          fullWidth
          onChange={(e) => setForestName(e.target.value)}
          style={{ marginRight: '16px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {forestName && (
                  <IconButton
                    aria-label="clear search"
                    onClick={(e) => setForestName('')}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {
          loading && <CircularProgress size={30} />
        }
      </div>

      <div style={{ marginTop: '20px', maxHeight: '70vh', overflow: 'scroll' }}>
        {searchResults.length > 0 ? (
          <>
          <p><b>{searchResults.length}</b> community forest found:</p>
          <hr/>
          <ul>
            {searchResults && searchResults.map((result) => (
              <Card onClick={() => onItemClick(result)} key={result.id} sx={{ display: 'flex', borderRadius: 0, maxWidth: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6">
                      {result.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" component="div">
                      {result.localRegion.name}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div" style={{ marginTop: 5 }}>
                      {`${result.cfugCode}`}
                    </Typography>
                    <Button onClick={() => window.open(`/cf/dashboard/${result.id}?type=FOREST`) } style={{marginTop: 5}} variant="contained">View Details</Button>
                  </CardContent>
                </Box>
              </Card>
            ))}
          </ul>
          </>
        ) : (
          <SearchNotFound searchQuery={forestName} />
        )}
      </div>
    </div>
  );
};

export default SearchComponent;
