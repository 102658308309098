import { Card, Dialog, DialogTitle, Divider, Grid, IconButton, Slide, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Link } from 'react-router-dom';

function ForestDialog({open, forest, onClose}) {
  return (
    <Slide direction={'left'} in={open} mountOnEnter unmountOnExit>
      <Card sx={{ position: "absolute", width: '30%', minHeight: 400, right: 5, bottom: 50, borderRadius: 0 }} variant="outlined">
        <IconButton style={{position: 'absolute', right: '0'}} aria-label="close" onClick={onClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
        <Stack padding={2} marginTop={2} direction={'column'}>
          <Typography color={'primary'} textAlign={'center'} variant={'h5'}>{forest.name}</Typography>
          <Divider/>
          <Grid columnSpacing={2} rowSpacing={1} container direction={'row'} marginTop={2} alignItems={'center'} alignContent={'space-between'}>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>ID</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.id}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>CFUG Code</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.cfug_code}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>Area</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{`${forest.area  } HA`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>Province</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.province}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>District</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.district}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>Local Region</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.palika}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>Division Forest Office</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.division_forest_office}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>Sub Division Forest Office</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.sub_division_forest_office}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'right'} sx={5} variant={'subtitle1'}>Geographical Region</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={'left'} sx={6} variant={'body1'}>{forest.geo_graphical_region}</Typography>
            </Grid>
          </Grid>
          <Link to={`/dashboard/tasks/report/${forest.reporting_id}`}>View Details</Link>
        </Stack>
      </Card>
    </Slide>
  )
}

export default ForestDialog;
