import React from 'react';
import ProgressBar from './ProgressBar';
// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <>
      <ProgressBar />
    </>
  );
}
