
import React, { useEffect, useState } from 'react'
import MemberList from './MemberList'
import { Grid } from '@mui/material'
import APIServices from 'apiUtils/APIServices';
import TimberLineChart from 'pages/explore/Components/TimberLineChart';

function TimeberMember({isLoading, aac, growingTimeber,members, showMemberList = true}) {


  return (
    <Grid container spacing={2} marginTop={'10px'}>
      <Grid item xs={6} md={6}>
        <TimberLineChart isLoading={isLoading} series={[growingTimeber, aac]} />
      </Grid>
      <Grid item xs={6} md={6}>
        {showMemberList && <MemberList title={'New Member'} list={members ?? []} />}
      </Grid>
    </Grid>
  );
}

export default TimeberMember
