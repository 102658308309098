import { styled, useTheme } from '@mui/material/styles';
import './styles.css';
import Page from 'components/Page';
import {
  Stack,
} from '@mui/material';
import Map from 'components/openlayer/Map';
import { ImageLayer, Layers, TileLayer, VectorLayer } from '../../components/openlayer/Layers';
import * as olSource from 'ol/source';
import { ImageWMS } from 'ol/source';
import { Controls, FullScreenControl, ZoomControl } from '../../components/openlayer/Controls';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import ForestDialog from './ForestDialog';
import React, { useEffect, useRef, useState } from 'react';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import APIServices from '../../apiUtils/APIServices';
import { transform } from 'ol/proj';
import ClusterLayer from '../../components/openlayer/Layers/ClusterLayer';
import MapMenu from './MapMenu';

const RootStyle = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(8),
  // [theme.breakpoints.up('md')]: {
  //   paddingTop: theme.spacing(3),
  // },
}));

const redColorShades = [
  '#cb5382aa',
  '#bc4f5eaa',
  '#cb0e40aa',
  '#b21c0eaa',
  '#470a0aaa',
]

const provinceColors = {
  1: '#F39C12AA',
  2: '#2ECC71AA',
  3: '#E74C3CAA',
  4: '#3498DBAA',
  5: '#9B59B6AA',
  6: '#E67E22AA',
  7: '#95A5A6AA'
}

function MapExplore() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [center, setCenter] = useState([9395568.149394948, 3311298.5880030664]);
  const [layers, setLayers] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState();
  const [map, setMap] = useState();
  const [vectorSources, setVectorSources] = useState([])
  const [hoveredForest, setHoveredForest] = useState()
  const [baseTile, setBaseTile] = useState(new olSource.XYZ({
    url: 'https://mt1.google.com/vt/lyrs=m@113&hl=en&&x={x}&y={y}&z={z}&key=AIzaSyBZSNHrKENqTgIzadIdHQ2-tKV-iqDMTWc',
    // url: 'https://api.maptiler.com/maps/topo-v2/{z}/{x}/{y}.png?key=9E8pIRtkflheFsSZosri',
  }))
  const [forestStyleCache, setForestStyleCache] = useState({})


  const forestClusterStyle = (feature, resolution) => {
    const size = feature.get('features').length ?? 1;
    let colorIndex = 0;
    if (size <= 50)
      colorIndex = 0
    if (size > 50 && size <= 100) {
      colorIndex = 1
    } else if (size > 100 && size <= 150){
      colorIndex = 2
    } else if (size > 150 && size <= 200){
      colorIndex = 3
    } else if (size > 200){
      colorIndex = 4
    }
    const radius = size <= 2 ? 10 : 10 * Math.log(size)
    return new Style({
      image: new Circle({
        radius, // Adjust the factor for proportional radius
        fill: new Fill({
          color: redColorShades[colorIndex],
        }),
        stroke: new Stroke({
          width: 0.5,
          color: 'black'
        })
      }),
      text: new Text({
        font: '12px Calibri,sans-serif',
        fill: new Fill({
          color: 'white',
        }),
        text: `${size}`
      })
    });
  };

  const provinceStyleFunction = (feature, resolution) => new Style({
    stroke: new Stroke({
      color: '#00000033',
      width: 2,
    }),
    fill: new Fill({
      color: provinceColors[feature.get('id')],
      alpha: 0.5
    }),
    text: new Text({
      font: '16px Calibri,sans-serif',
      text: `${feature.get('name')}\n(${feature.get('np_name')})`,
      fill: new Fill({
        color: 'black',
      })
    }),
  });

  const districtStyleFunction = (feature, resolution) => new Style({
    stroke: new Stroke({
      color: '#00000066',
      width: 1.5,
    }),
    fill: new Fill({
      color: provinceColors[feature.get('province_id')],
      alpha: 0.5
    }),
    text: new Text({
      font: '20px Calibri,sans-serif',
      text: `${feature.get('name')}\n(${feature.get('np_name')})`,
      fill: new Fill({
        color: 'white',
      }),
      stroke: new Stroke({
        color: 'black',
        width: 0.5,
      })
    }),
  });

  const municipalStyleFunction = (feature, resolution) => new Style({
    stroke: new Stroke({
      color: '#00000066',
      width: 2,
    }),
    fill: new Fill({
      color: resolution < 38 ? '#FFFFFF00' : provinceColors[feature.get('province_id')],
      alpha: 0.5
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      text: feature.get('name'),
      fill: new Fill({
        color: 'black',
      })
    }),
  });

  const forestStyleFunction = (feature, resolution) => {
    if (hoveredForest) {
      console.log(hoveredForest)
    }
    return new Style({
      stroke: new Stroke({
        color: forestBorderColor(feature.get('status')),
        width: 3,
        lineDash: [6, 12],
      }),
      fill: new Fill({
       color: forestFillColor(feature.get('status'))
      }),
      text: new Text({
        font: '14px Calibri,sans-serif',
        fill: new Fill({
          color: 'white',
        }),
      }),
    });
  };

  const forestBorderColor = (status) => {
    if (status === 'APPROVED' || status === 'PUBLISHED') {
      return '#00AB55'
    }
    if (status === 'DRAFT' || status === 'UNDER_REVIEW') {
      return '#6495ED'
    }
    return '#E0115F'
  }

  const forestFillColor = (status) => {
    if (status === 'APPROVED' || status === 'UNDER_REVIEW') {
      return '#00AB55AA'
    }
   if (status === 'DRAFT' || status === 'UNDER_REVIEW') {
      return '#6495EDAA'
    }
   return '#E0115FAA'
  }

  const selectedForestStyle = new Style({
    stroke: new Stroke({
      color: 'white',
      width: 4,
      lineDash: [6, 12]
    }),
    fill: new Fill({
      color: '#00AB55CC',
    }),
    text: new Text({
      font: '14px Calibri,sans-serif',
      fill: new Fill({
        color: 'white',
      })
    }),
  });

  const defaultLayers = [
    {
      title: 'Openlayers',
      type: 'TILE',
      subType: 'OSM',
      visible: true,
    },
    {
      title: 'Provinces',
      type: 'VECTOR',
      subType: 'GEOJSON',
      source: 'province',
      layer: 'cfportal:province',
      serverType: 'geoserver',
      minZoom: 6,
      maxZoom: 9,
      visible: true,
      styleFunction: provinceStyleFunction
    },
    {
      title: 'Districts',
      type: 'VECTOR',
      subType: 'GEOJSON',
      source: 'district',
      layer: 'cfportal:district',
      serverType: 'geoserver',
      minZoom: 9,
      maxZoom: 10,
      visible: true,
      styleFunction: districtStyleFunction
    },
    {
      title: 'Local Regions',
      type: 'VECTOR',
      subType: 'GEOJSON',
      source: 'municipal',
      serverType: 'geoserver',
      minZoom: 10,
      visible: true,
      styleFunction: municipalStyleFunction
    },
    {
      title: 'Special Areas',
      type: 'IMAGE',
      url: `${process.env.REACT_APP_GEOSERVER_BASE  }/cfportal/wms`,
      layer: 'cfportal:special_area',
      serverType: 'geoserver',
      minZoom: 12,
      visible: false,
    },
    {
      title: 'Forests',
      type: 'VECTOR',
      subType: 'GEOJSON',
      source: 'forest',
      visible: true,
      minZoom: 12,
      styleFunction: forestStyleFunction,
    },
    {
      title: 'Forests',
      type: 'CLUSTER',
      subType: 'GEOJSON',
      source: 'forest',
      visible: true,
      maxZoom: 12,
      styleFunction: forestClusterStyle,
    },
  ];


  useEffect( () => {
    setLayers(defaultLayers)
    const sources = vectorSources
    if (!sources.forest) {
      vectorSources.forest = new VectorSource({
        format: new GeoJSON(),
        url: `${process.env.REACT_APP_GEOSERVER_BASE  }/cfportal/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cfportal%3Aforest&outputFormat=application%2Fjson`,
      })
    }
    if (!sources.province) {
      vectorSources.province = new VectorSource({
        format: new GeoJSON(),
        url: `${process.env.REACT_APP_GEOSERVER_BASE  }/cfportal/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cfportal:province&outputFormat=application%2Fjson`,
      })
    }
    if (!sources.district) {
      vectorSources.district = new VectorSource({
        format: new GeoJSON(),
        url: `${process.env.REACT_APP_GEOSERVER_BASE  }/cfportal/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cfportal:district&outputFormat=application%2Fjson`,
      })
    }
    if (!sources.municipal) {
      vectorSources.municipal = new VectorSource({
        format: new GeoJSON(),
        url: `${process.env.REACT_APP_GEOSERVER_BASE  }/cfportal/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cfportal:local_region&outputFormat=application%2Fjson`,
      })
    }
    setVectorSources(sources)
  },[])

  const MapLayer = ({ layer }) => {
    if (!layer.visible) return null;
    switch (layer.type) {
      case 'TILE':
        return <TileLayer name={layer.title}
          source={baseTile}
        />;

      case 'IMAGE':
        return <ImageLayer name={layer.title} minZoom={layer.minZoom}
          maxZoom={layer.maxZoom} source={new ImageWMS({
          url: layer.url,
          params: {
            'LAYERS': layer.layer,
            serverType: layer.serverType,
          },
        })} />;

      case 'VECTOR':
        return <VectorLayer name={layer.title} minZoom={layer.minZoom} maxZoom={layer.maxZoom} style={ (feature, resolution) => {
          if (layer.styleFunction) {
            return layer.styleFunction(feature, resolution)
          }
          let {style} = layer;
          if (selectedFeature && feature.get('id') === selectedFeature.get('id')) {
            style = selectedForestStyle
          }
          style.getText().setText(feature.get('name'));
          return style;
        }} source={layer.source ? vectorSources[layer.source] : new VectorSource({
          format: new GeoJSON(),
          url: layer.url,
        })} />;

      case 'CLUSTER':
        return <ClusterLayer name={layer.title} minZoom={layer.minZoom} maxZoom={layer.maxZoom} distance={40} style={ (feature, resolution) => {
          const size = feature.get('features').length;
          if (layer.styleFunction) {
            return layer.styleFunction(feature, resolution)
          }
          return  layer.style;
        }} source={layer.source ? vectorSources[layer.source] : new VectorSource({
          format: new GeoJSON(),
          url: layer.url,
        })} />;

      default:
        return null;

    }
  };

  const onSingleClick = (evt) => {
    setDialogOpen(false);
    setSelectedFeature(null);
    const features = evt.map.getFeaturesAtPixel(evt.pixel)
    if (features.length === 0) {
      return
    }
    const forestFeature = features.find(feat => feat.get('local_region_id'))
    if (!forestFeature) {
      const feature = features.find(feat => feat.getGeometry())
      if (feature && !feature.get('ignoreClick')){
        evt.map.getView().fit(feature.getGeometry().getExtent(), evt.map.getSize());
      }
    } else {
      // setSelectedFeature(forestFeature);
      // evt.map.getView().fit(forestFeature.getGeometry().getExtent(), evt.map.getSize());
      // forestResultSelected(forestFeature.get('forest_id'))
      window.location.href = `/cf/dashboard/${forestFeature.get('forest_id')}?type=FOREST`
      evt.preventDefault()
    }
  };
  const mapChanged = (m) => {
    if (!map && m) {
      setMap(m);
    }
  };
  const forestResultSelected = async (forest) => {
    // const url = `search/forest/coordinates/${forest.id}`;
    window.open(`/cf/dashboard/${forest}?type=FOREST`)
    /* const { data, success } = await new APIServices(url).get();
    if (success && data && data.y && data.x) {
      if (map) {
        const coordinate = transform([data.x, data.y], 'EPSG:4326', 'EPSG:3857');
        const pixel = map.getPixelFromCoordinate(coordinate);
        map.forEachFeatureAtPixel(pixel, (feature, layer) => {
          const id = feature.get('forest_id');
          if (forest.id === id) {
            setSelectedFeature(feature);
            const extent = feature.getGeometry().getExtent();
            map.getView().fit(extent);
            setDialogOpen(true);
          }
        });
      }

    } */
  };

  const getForest = (evt) => {
    const features = evt.map.getFeaturesAtPixel(evt.pixel)
    if (features.length === 0) {
      return undefined
    }
    const feature = features.find(feat => feat.get('local_region_id'));
    if (feature) {
      // console.log(feature)
     // setHoveredForest(feature)
    }
    return feature
  }

  const onForestClicked = (forest) => {
    console.log(forest)
  }

  return <Page sx={{ width: '100%', height: '100%' }} title={'Map Explore'}>
    <RootStyle>
      <Stack sx={{ width: '100%', height: '100vh' }} direction={'row'}>
        <Map onMapChange={mapChanged} onMapSingleClick={onSingleClick} zoom={7}
          center={center || [9395568.149394948, 3311298.5880030664]} getHoveredFeature={getForest}>
          <Layers>
            {layers.map(l => <MapLayer layer={l} />)}
          </Layers>
          <Controls>
            <FullScreenControl />
            <ZoomControl />
          </Controls>
        </Map>
        <MapMenu onItemClick={onForestClicked} style={{position: 'absolute', left: 10, top:10}} />
        <ForestDialog open={dialogOpen} onClose={() => setDialogOpen(false)}
          forest={selectedFeature ? selectedFeature.values_ : {}} />
      </Stack>
    </RootStyle>
  </Page>;
}

export default MapExplore;
