import { PersonOutline } from '@mui/icons-material';
import { Avatar, Box, Button, Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import Link from '@mui/material/Link';
import { fShortMonthDate } from 'utils/formatTime';
import { getImgSrc } from 'utils/getImgSrc';
import { stringAvatar } from 'utils/string.util';

const NewsAndArticleCard = styled(Stack)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: 'white',
  img: {
    width: '286px',
    height: '176px',
    zIndex: '100',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '.MuiBox-root': {
    borderRadius: '8px',
    padding: '24px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    width: '290px',
    '.title-ellipsis': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
  },
}));

export default function PopularBlogs({ posts }) {
  const theme = useTheme();

  return (
    <Box>
      <Typography fontSize={'20px'} fontWeight={600}>
        Popular Posts
      </Typography>
      { posts?.length > 0 &&
      <Stack spacing={3} marginTop={3}>
        {posts.map((post, index) => {
          return (
            <NewsAndArticleCard direction={'row'} key={index}>
              <div>
                {/* <img src="/static/img/popular-blog1.png" alt="iuagsduyg" /> */}
                <img src={getImgSrc(post?.coverImage)} alt={post?.slug} sx={{ height: 0.5, borderRadius: 1.5 }} />
              </div>
              <Box>
                <Stack spacing={2} direction="row" justifyContent="left" alignItems="flex-start" sx={{ mb: '2%' }}>
                  <Stack>
                    <Avatar
                      alt={post?.authorName}
                      sx={{ height: 24, width: 24 }}
                      {...stringAvatar(post?.authorName)}
                    />
                  </Stack>
                  <Stack sx={{ minWidth: 0 }}>
                    <Typography
                      fontSize={'12px'}
                      fontWeight={'400'}
                      color={theme.palette.grey[600]}
                      display={'flex'}
                      gap={2}
                      alignItems={'center'}
                      sx={{ marginTop: '1%', paddingLeft: '1rem' }}
                    >
                      {post?.authorName} |  {fShortMonthDate(post?.createdOn)}
                    </Typography>
                  </Stack>
                </Stack>

                <Typography
                  fontSize={'1rem'}
                  className="title-ellipsis"
                  fontWeight={'600'}
                  marginY={1}
                  letterSpacing={0}
                >
                  {post.title}
                </Typography>
                {/* <Typography fontSize={'14px'} fontWeight={700}>
                  Read More
                </Typography> */}
                {post.slug && (
                  <Link href={`/blog/${post?.slug}`} underline="hover">
                    read more{' '}
                  </Link>
                )}
              </Box>
            </NewsAndArticleCard>
          );
        })}
      </Stack> 
      }
    </Box>
  );
}
