import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// chart data
import timberLineChartData from './TimberLineChartData';


// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const TimberLineChart = ({ isLoading, series }) => {
  const theme = useTheme();

  let chartSeries = [];
  if(!isLoading) {
    timberLineChartData.options.xaxis.categories = Array.isArray(series.data)? ['Timber Stock', 'Annual Allowable Cut (AAC)'] : ['Annual Allowable Cut (AAC)']
    chartSeries = [
      {
        name: 'Height(ft3)',
        data: series
      }
    ]
  }

  return (
    <>
      {isLoading ? (
        <SkeletonTotalGrowthBarChart />
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    {/* <Grid item>
                      <Typography variant="subtitle2">Total Growth</Typography>
                    </Grid> */}
                    <Grid item>
                      <Typography variant="h5" style={{fontWeight: '500'}}>Timber</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* <Chart {...chartData} /> */}

              <Chart options={timberLineChartData.options} series={chartSeries} type={`bar`} width={`400`} height={`300`} />
      
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

TimberLineChart.propTypes = {
  isLoading: PropTypes.bool
};

export default TimberLineChart;