import {
  Card,
  CardContent,
  Checkbox,
  Container, Divider,
  FormControlLabel,
  Grid,
  Stack,
  TablePagination, TextField,
  Typography,
  Button, CircularProgress,
} from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import APIServices from '../../../apiUtils/APIServices';
import TableView from '../../analytics/components/TableView';
import SearchField from 'components/Search';
import Backdrop from '@mui/material/Backdrop';

const defaultData = {
  data: {
    header: {
      rows: []
    },
    data: []
  }
}

export default function Search() {
  const [data, setData] = useState(defaultData)
  const [name, setName] = useState('')
  const [province, setProvince] = useState(null)
  const [district, setDistrict] = useState(null)
  const [municipal, setMunicipal] = useState(null)
  const [params, setParams] = useState([])
  const [selectedParams, setSelectedParams] = useState([])
  const [size, setSize] = useState(10)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [customary, setCustomary] = useState()
  const [womenLed, setWomenLed] = useState()
  const [onlyApproved, setOnlyApproved] = useState(false)
  const [notApproved, setNotApproved] = useState(false)
  const [sort, setSort] = useState({
    field: 'CF_NAME',
    direction: 'ASC'
  })
  // const [selectedParams, setSelectedParams] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const handleMasterCheckboxChange = (event) => {
    const { checked } = event.target;
    setAllChecked(checked);

    if (checked) {
      setSelectedParams(params);
    } else {
      setSelectedParams([]);
    }
  };

  const handleIndividualCheckboxChange = (param, checked) => {
    if (checked && !selectedParams.includes(param)) {
      setSelectedParams((prev) => [...prev, param]);
    } else if (!checked && selectedParams.includes(param)) {
      setSelectedParams((prev) => prev.filter((p) => p !== param));
    }
  };

  const fetchData = async (params) => {
    setLoading(true)
    const {data, success} = await new APIServices('advanced-search/forest').post(params)
    setLoading(false)
    if (success){
      setData({ ...data, sortColumns: ['CF_NAME'] });
      setTotal(data?.data?.pagination?.total ?? 0)
    }
  }

  const fetchParams = async () => {
    console.log("Fetch reporting data")
    const {data, success} = await new APIServices('advanced-search/forest/params').get()
    if (success){
      setParams(data || [])
      setSelectedParams([])
    }
  }

  const onSortChanged = (column, order) => {
    const sortData = {
      field: column,
      direction: order.toUpperCase()
    }
    setSort(sortData)
    const params = selectedParams.map(param => param.key)
    const request = {
      params,
      name,
      province,
      district,
      municipal,
      page,
      size,
      womenLed: womenLed ? true : null,
      onlyApproved: onlyApproved ? true : null,
      notApproved: notApproved ? true : null,
      customary: customary ? true : null,
      sort: sortData
    }
    fetchData(request)
  }

  const onPageChanged = (page) => {
    setPage(page)
    const params = selectedParams.map(param => param.key)
    const request = {
      params,
      name,
      province,
      district,
      municipal,
      page,
      size,
      sort,
      womenLed: womenLed ? true : null,
      onlyApproved: onlyApproved ? true : null,
      notApproved: notApproved ? true : null,
      customary: customary ? true : null
    }
    fetchData(request)
  }

  const onPageSizeChanged = (pageSize) => {
    setSize(pageSize)
    const params = selectedParams.map(param => param.key)
    const request = {
      params,
      name,
      province,
      district,
      municipal,
      page,
      size: pageSize,
      sort,
      womenLed: womenLed ? true : null,
      onlyApproved: onlyApproved ? true : null,
      notApproved: notApproved ? true : null,
      customary: customary ? true : null
    }
    fetchData(request)
  }

  const onFilterClicked = async () => {
    const params = selectedParams.map(param => param.key)
    const request = {
      params,
      name,
      province,
      district,
      municipal,
      page,
      size,
      womenLed: womenLed ? true : null,
      onlyApproved: onlyApproved ? true : null,
      notApproved: notApproved ? true : null,
      customary: customary ? true : null,
      sort
    }
    fetchData(request)
  }

  useEffect(() => {
    fetchParams()
    fetchData({page: 0, size: 10, sort: {
        field: 'CF_NAME',
        direction: 'DESC'
      }})
  }, [])

  return (
    <Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction={'column'}>
        <HeaderBreadcrumbs
          heading="Advanced Search"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.app },
            { name: 'Search', href: PATH_DASHBOARD.search.root },
          ]}
        />

        <Card>
          <CardContent>
            <Grid container columns={{ xs: 4, md: 12 }}>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.primary" gutterBottom>
                  Display Fields
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allChecked}
                      onChange={handleMasterCheckboxChange}
                      inputProps={{ 'aria-label': 'Master Checkbox' }}
                    />
                  }
                  label="Select All"
                />
              </Grid>
            </Grid>

            <Grid container direction={'row'}>
              {params.map((param) => (
                <Grid item md={3}>
                  <FormControlLabel
                    label={param.value}
                    control={
                      <Checkbox
                        checked={selectedParams.includes(param)}
                        onChange={async (a, b) => {
                          if (b && !selectedParams.includes(param)) {
                            await setSelectedParams([...selectedParams, param]);
                          }
                          if (!b && selectedParams.includes(param)) {
                            await setSelectedParams(selectedParams.filter((p) => p !== param));
                          }
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
            <Divider />
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', marginTop: 2 }} color="text.primary" gutterBottom>
              Filter
            </Typography>
            <Grid container direction={'row'} spacing={1}>
              <Grid item xs={3}>
                <TextField
                  style={{ width: '100%' }}
                  variant={'outlined'}
                  placeholder={'Forest Name'}
                  onChange={({ target }) => setName(target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <SearchField
                  src={'search/province'}
                  label={'Province'}
                  paramName={'query'}
                  labelField={'name'}
                  getLabel={(province) => province.name}
                  onChange={async (province) => {
                    await setProvince(province?.id ?? undefined);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <SearchField
                  src={'search/district'}
                  label={'District'}
                  paramName={'query'}
                  labelField={'name'}
                  getLabel={(forest) => forest.name}
                  onChange={async (district) => {
                    await setDistrict(district?.id ?? undefined);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <SearchField
                  src={'search/local-region'}
                  label={'Municipal'}
                  paramName={'query'}
                  labelField={'name'}
                  getLabel={(municipal) => municipal.name}
                  onChange={async (municipal) => {
                    await setMunicipal(municipal?.id ?? undefined);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }} direction={'row'} spacing={1}>
              <FormControlLabel
                label="Customary Forest"
                control={
                  <Checkbox
                    checked={customary}
                    onChange={async (event, checked) => {
                      await setCustomary(checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                label="Women Led"
                control={
                  <Checkbox
                    checked={womenLed}
                    onChange={async (event, checked) => {
                      await setWomenLed(checked);
                    }}
                  />
                }
              />
              <FormControlLabel
                label="Only Approved"
                control={
                  <Checkbox
                    checked={onlyApproved}
                    onChange={async (event, checked) => {
                      await setOnlyApproved(checked);
                      if (checked) {
                        setNotApproved(false);
                      }
                    }}
                  />
                }
              />
              <FormControlLabel
                label="Not Approved"
                control={
                  <Checkbox
                    checked={notApproved}
                    onChange={async (event, checked) => {
                      await setNotApproved(checked);
                      if (checked) {
                        setOnlyApproved(false);
                      }
                    }}
                  />
                }
              />
            </Grid>
            <Stack marginTop={2} direction={'row-reverse'}>
              <Button onClick={onFilterClicked} variant={'outlined'}>
                Filter Data
              </Button>
            </Stack>
          </CardContent>
        </Card>
        <TableView table={data} onSortChange={onSortChanged} />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 1500]}
          component="div"
          count={total}
          rowsPerPage={size}
          page={page}
          onPageChange={(_, page) => onPageChanged(page)}
          onRowsPerPageChange={({ target }, b, perPage) => onPageSizeChanged(target.value)}
        />
      </Stack>
    </Container>
  );
}
