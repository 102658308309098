import React from 'react';
import { t } from 'i18next';
import { Stack, Typography, InputAdornment, 
  TableContainer, TableBody, TableHead, Table, TableCell, Button, TableRow } from '@mui/material';

import { statuses } from '.';

import { RHFRadioGroup, RHFSelect, RHFTextField } from 'components/hook-form';
import { LabelStyle } from '../StyledComponents';
import Iconify from 'components/Iconify';
import SpecieButton from './SpecieButton';

const HumanWildAnimalConflict = ({ values, setValue, unregister, ANIMALS = [], fetchSpecies }) => (
  <>
    <Typography variant={'h6'} padding={1}>
      12. Human Wildlife Conflicts
    </Typography>
    <Stack spacing={2} paddingX={3}>
      <RHFRadioGroup name="hasHumanAnimalEncounter" options={[true, false]} getOptionLabel={statuses} />
    </Stack>
    {values.hasHumanAnimalEncounter === 'true' ? (
      <Stack spacing={2} paddingX={3}>
        <LabelStyle paddingX={2}>Major Human-Wildlife Conflict</LabelStyle>
        <TableContainer sx={{ my: 2 }}>
          <Table>
            <TableHead>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Conflict Type</TableCell>
              <TableCell>Animal Species</TableCell>
              <TableCell>Frequency of Conflicts</TableCell>
            </TableHead>
            <TableBody>
              {values?.animalEncounter.humanWildlifeConflicts?.map((wc, i) =>
                getHumanWildlifeConflictRow(i, ANIMALS, values, setValue, unregister, fetchSpecies)
              )}
              <TableRow key={`business-field-row-9999`}>
                <TableCell sx={{ minWidth: 50 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setValue(`animalEncounter.humanWildlifeConflicts[${values?.animalEncounter.humanWildlifeConflicts?.length}]`, {})}
                    sx={{ minWidth: 140 }}
                  >
                    +
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Typography variant="subtitle1">Damage Detail</Typography> */}

        <LabelStyle>Death Detail</LabelStyle>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={2}
        >
          <Stack width={'20%'}>
            <LabelStyle>Humanitarian(Male)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.maleDeath"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:face-man-shimmer" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Humanitarian(Female)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.femaleDeath"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:face-woman-shimmer" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Humanitarian(Others)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.othersDeath"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:face-woman-shimmer" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
  
        </Stack>

        <LabelStyle>Damage Detail</LabelStyle>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={2} 
        >
          <Stack width={'20%'}>
            <LabelStyle>Humanitarian(Male)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.maleCasualty"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:face-man-shimmer" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Humanitarian(Female)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.femaleCasualty"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:face-woman-shimmer" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Humanitarian(Others)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.othersCasualty"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:face-woman-shimmer" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Homes</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.damages[0].quantity"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:home" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Pets</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.damages[1].quantity"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:cow" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Agricultural Damage(Quintal)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.damages[2].quantity"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography color={'text.secondary'}>Q</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack width={'20%'}>
            <LabelStyle>Agricultural Damage (Hectare)</LabelStyle>
            <RHFTextField
              label={t('Number')}
              fullWidth
              name="animalEncounter.damages[3].quantity"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography color={'text.secondary'}>HA</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    ) : null}
  </>
);

export default HumanWildAnimalConflict;

const getHumanWildlifeConflictRow = (i, ANIMALS, values, setValue, unregister, fetchSpecies) => {
  const conflicts = values != null ? values.animalEncounter.humanWildlifeConflicts : null;
  const selectedList = values
    ? values.animalEncounter.humanWildlifeConflicts.filter((nW) => nW.wildlifeSpeciesId != null).map((nW) => parseInt(nW.wildlifeSpeciesId, 10))
    : [];

  return (
    <TableRow key={`business-field-row-${i}`}>
      <TableCell sx={{ minWidth: 150 }}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            if (values?.animalEncounter?.humanWildlifeConflicts?.filter((n) => n).length === 1) {
              setValue('animalEncounter.humanWildlifeConflicts', []);
            } else {
              unregister(`animalEncounter.humanWildlifeConflicts[${i}]`);
            }
          }}
          sx={{ minWidth: 140 }}
        >
          -
        </Button>
      </TableCell>
      <TableCell sx={{ minWidth: 250 }}>
        <RHFTextField
            label={t('Conflict Type')}
            fullWidth
            name={`animalEncounter.humanWildlifeConflicts[${i}].type`}
          />
      </TableCell>
      <TableCell sx={{ minWidth: 250 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }}>
          <RHFSelect
            key={`animal-index-${i}`}
            name={`animalEncounter.humanWildlifeConflicts[${i}].wildlifeSpeciesId`}
            label="Animal Species"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <Iconify icon="mdi:cow" />
                </InputAdornment>
              ),
            }}
          >
            <option value=""/>
            {ANIMALS?.map(({ id: value, name: label }, i) => (
              <option key={value} value={value} disabled={selectedList.indexOf(value) !== -1 ? 'disabled' : ''}>
                {label}
              </option>
            ))}
          </RHFSelect>
          <SpecieButton fetchSpecies={fetchSpecies}/>
        </Stack>
      </TableCell>
      <TableCell>
        <RHFTextField type="number"  min={0} name={`animalEncounter.humanWildlifeConflicts[${i}].frequency`} 
          label={t('forms.gdcf.developmentManagement.humanWildlifeConflict.frequency')} />
      </TableCell>
    </TableRow>
  );
};