import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import PropTypes from 'prop-types';
import cssStyles from 'utils/cssStyles';
import Iconify from 'components/Iconify';
import Label from 'components/Label';

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  padding : 30,
  justifyContent: 'center',
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.lighter }),
  width: '100%',
  height: '100%'
  }));

RegionCover.propTypes = {
  name : PropTypes.string.isRequired,
  consortiumPartner: PropTypes.string.isRequired,
  icon : PropTypes.string.isRequired
}

export default function RegionCover({ name, consortiumPartner, icon }) {
  return (
      <InfoStyle>
        <Stack textAlign={'center'} alignItems={'center'}>
        <Iconify icon={icon} sx={{ width: 128,height: 128,}}/>
        <Box  textAlign="center">
          <Typography variant="h4"> {name}</Typography>
          <Label variant={'filled'} color={'info'} >{consortiumPartner}</Label>
        </Box>
      </Stack>
</InfoStyle>
  );
}