import React, { useEffect, useState } from 'react';

import APIServices from 'apiUtils/APIServices';

import Page from 'components/Page';
// import SpecieTable from './SpecieTable';
import { PATH_DASHBOARD } from 'routes/paths';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import BlogButton from './BlogButton';
import {  PostListView } from 'sections/@dashboard/blogs/view';

const Blogs = () => {
  return (
    <Page title="Blogs">
      <HeaderBreadcrumbs
        heading="Blog List"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Blogs' }]}
        action={<BlogButton  />}
      />

      <PostListView  />
    </Page>
  );
};

export default Blogs;
