import { useState, useEffect } from 'react';

const useUser = () => {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const userDetail = JSON.parse(window.localStorage.getItem('authUser'));
    setUserData(userDetail);
  }, []);

  return userData;
};
export default useUser;
