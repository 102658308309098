import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControl, FormHelperText, InputLabel, Select, TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelect({ name, children, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{ native: true }}
          error={!!error}
          helperText={error?.message}
          InputLabelProps={{ shrink: true }}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultipleSelect = ({ name, children, value = [], label, onChange, ...other }) => {
  const { control } = useFormContext();
  const [values, setValues] = useState([...value]);

  useEffect(() => {
    if (value?.length) setValues([...value]);
  }, [value]);

  const handleChange = (e, changeHandler) => {
    const {
      target: { value },
    } = e;
    setValues([...value]);
    if (typeof onChange === 'function') onChange([...value]);
    else changeHandler(e);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            {...field}
            multiple
            value={values}
            {...other}
            labelId={`${name}-label`}
            input={<OutlinedInput label={label} />}
            MenuProps={MenuProps}
            onChange={(e) => handleChange(e, field.onChange)}
            
          >
            {children}
          </Select>
          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

MultipleSelect.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};
