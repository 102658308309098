import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLTileLayer from "ol/layer/Tile";

const TileLayer = ({ source, zIndex = 0 , name}) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        const tileLayer = new OLTileLayer({
            source,
            zIndex,
        });
        tileLayer.set('name', name)
        map.addLayer(tileLayer);
        tileLayer.setZIndex(zIndex);


        return () => {
            if (map) {
                map.removeLayer(tileLayer);
            }
        };
    }, [map]);

    return null;
};

export default TileLayer;
