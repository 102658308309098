// auth
export const POST_LOGIN = 'auth/login'
export const POST_PWD_RESET_REQUEST = 'auth/password-reset'
export const POST_PWD_RESET = 'auth/password-reset/'
export const POST_SET_PROFILE = (userId, confirmToken ) => `auth/confirmAccount/${userId}/${confirmToken}`

// Partner
export const GET_USER_LIST_BY_PARTNER = 'partner/user'
export const POST_NEW_OPERATOR = 'operator'
export const POST_NEW_REPORTER = (region) => `regions/${region}/reporter`;

export const GET_ASSIGN_USER_ROLE_BY_ADMIN = 'partner/user/:u_id'
export const PUT_ASSIGN_USER_ROLE_BY_ADMIN = 'partner/user/:u_id'
export const DELETE_USER_BY_ADMIN = 'partner/user/:u_id'
export const POST_REVERIFICATION_BY_ADMIN = 'partner/user/:u_id/reverification'
export const POST_ADMIN_CHANGE_PWD = 'partner/user/:u_id/change-password'


// Data Operator
export const OPERATOR = 'operator'
export const POST_NEW_BY_OPERATOR = 'operator'
export const GET_OPERATOR = 'operator/:u_id'
export const PUT_ASSIGN_USER_ROLE_BY_OPERATOR = 'operator/:u_id'
export const DELETE_USER_BY_OPERATOR = 'operator/:u_id'

export const REPORTER = 'reporters'


// Data Reporter
export const GET_REGIONS_REPORTER = 'regions/reporter'
export const GET_REPORTER_LIST = 'reporters'
export const GET_CF_REPORTS_LIST = 'general-reporting'


// Regions
export const GET_REGIONS_LIST = 'regions'

// CF Data General Info
export const POST_GENERAL_INFO = 'cf/:cf_id/report/gi'
export const POST_GENERAL_COMMITTEE_INFO = 'cf/:cf_id/report/:report_id/committee_details'

// CF Data Report
export const GET_CF_REPORT_LIST = 'cf/:cf_id/report/'
export const GET_CF_REPORT_DATA = 'cf/:cf_id/report/:report_id'

// Upload
export const UPLOAD_FILE = 'attachments'
export const GENERAL_INFO_REPORT = 'general-reporting'
