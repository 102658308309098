import {
  Button, Card,
  CardContent, Checkbox, CircularProgress,
  Container, Divider, FormControlLabel, Grid,
  Stack,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination, TableRow, TextField, Typography,
} from '@mui/material';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import * as React from 'react';
import { useEffect, useState } from 'react';
import APIServices from '../../apiUtils/APIServices';
import TableView from '../analytics/components/TableView';
import SearchField from '../../components/Search';
import Backdrop from '@mui/material/Backdrop';
import { Link } from 'react-router-dom';

const Municipal = () => {
  const [municipalAreas, setMunicipalAreas] = useState([])
  const [size, setSize] = useState(10)
  const [page, setPage] = useState(0)
  const [query,setQuery] = useState('')
  const [loading, setLoading] = useState(false)


  const fetchData = async () => {
    setLoading(true)
    const params = {query, page, size}
    const {data, success} = await new APIServices('search/local-region').post(params)
    setLoading(false)
    if (success){
      setMunicipalAreas(data || [])
    }
  }



  useEffect(() => {
    fetchData()
  }, [size, page, query])

  console.log(municipalAreas)

  return (
    <Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction={'column'} spacing={2}>
        <HeaderBreadcrumbs
          heading="Municipal Areas"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.app },
            { name: 'Search', href: PATH_DASHBOARD.search.root },
          ]}
        />

        <Grid item xs={3}>
          <TextField
            style={{width: '100%'}}
            variant={'outlined'}
            placeholder={'Search'}
            onChange={({ target }) => {
              setQuery(target.value)
              if (target.value && target.value.length >= 3){
                fetchData()
              }
            }
            }
          />
        </Grid>

        <TableContainer component={'div'}>
          <Table
            sx={{minWidth: 750}}>
            <TableHead>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Nepali Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell width={150}>Action</TableCell>
            </TableHead>
            <TableBody>
              {
                municipalAreas.map((m,index) => (
                  <TableRow key={index}>
                    <TableCell>{page * size + (index + 1) }</TableCell>
                    <TableCell>{m.name}</TableCell>
                    <TableCell>{m.npName}</TableCell>
                    <TableCell>{m.type}</TableCell>
                    <TableCell width={150}>
                    <Link to={`/dashboard/areas/analytics/municipal/${m.id}?name=${m.name}`}> 
                      Analytics
                      {/* <Iconify icon="clarity:analytics-line" width="30" height="30" /> */}
                      </Link>
                      {/* <Link to={`/cf/dashboard/${m.id}?type=MUNICIPAL`}>View Details</Link> */}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500]}
          component="div"
          count={municipalAreas.length}
          rowsPerPage={size}
          page={page}
          onPageChange={(_, page) => {
            setPage(page);
            fetchData()
          }}
          onRowsPerPageChange={({target}) => {
            setSize(target.value);
            fetchData()
          }}
        />
      </Stack>
    </Container>
  )
}

export default Municipal
