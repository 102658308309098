import * as yup from 'yup';

export const nanTransformer = (value) => (Number.isNaN(value) ? undefined : value);
export const numberSchema = yup.number().transform(nanTransformer);
export const requiredNumberSchema = numberSchema.required('Required');
export const nonNegativeReqiredNumber = requiredNumberSchema.min(0, 'Non negative number required');
export const positiveNumber = numberSchema.positive('Positive value required');
export const positiveNumberRequired = positiveNumber.required('Required');
export const positiveFloatingPointSchema = numberSchema.positive('Positive value required').required('Required');
export const integer = numberSchema.integer('Integer value required');
export const requiredInteger = requiredNumberSchema.integer('Integer value required');
export const countSchema = nonNegativeReqiredNumber.integer('Integer value required');
