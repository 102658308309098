import { Alert, AlertTitle, Box, Container, Divider } from '@mui/material';
import * as React from 'react';
import Page from 'components/Page';
import useAuth from 'hooks/useAuth';
import AssignedRegions from './AssignedRegions';
import ReportsList from '../dataRegions/region/ReportsList';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import DataReporters from '../users/DataReporters/DataReporters';
import OperatorsTaskOverview from './OperatorsTaskOverview';
import DataOperators from '../users/DataOperators/DataOperators';

export default function Tasks() {
  const { user } = useAuth();

  switch (user?.authorities[0]) {
    case 'OPERATOR':
      return <OperatorsTask />;
    case 'PARTNER':
      return <PartnersTask />;
    case 'REPORTER':
      return <ReportersTask />;
    default:
      return (
        <Container>
          <Alert severity="error">
            <AlertTitle>Permission Denied</AlertTitle>
            You do not have permission to access this page
          </Alert>
        </Container>
      );
  }
}

function OperatorsTask() {
  return (
    <Page title="Tasks">
      <HeaderBreadcrumbs
        heading="Your Task"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.app },
          { name: 'Tasks', href: PATH_DASHBOARD.tasks.root },
        ]}
      />
      {/* <OperatorsTaskOverview /> */}
      <br />
      <DataReporters />
    </Page>
  );
}

function PartnersTask() {
  return (
    <Page title="Tasks">
      <HeaderBreadcrumbs
        heading="Your Task"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.app },
          { name: 'Tasks', href: PATH_DASHBOARD.tasks.root },
        ]}
      />
      <DataOperators />
      <Divider sx={{ my: 2 }} />
      <ReportsList />
    </Page>
  );
}

function ReportersTask() {
  const { user } = useAuth();
  return (
    <Box>
      <AssignedRegions regions={user?.regions} />
      <Divider sx={{ my: 2 }} />
      <ReportsList />
    </Box>
  );
}
