import PropTypes from 'prop-types';
import * as React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';

// project imports
import TypographySkeleton from 'ui-component/cards/Skeleton/TypographySkeleton';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { Icon } from '@iconify/react';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const CardItemList = ({ isLoading, title, list }) => {
  const theme = useTheme();

  return (
    <>
      {isLoading ? (
        <TypographySkeleton noOfRows={6} />
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item style={{paddingTop:'5px'}}>
                      <Typography variant="button" display="block">
                        {' '}
                        <Tooltip title={title} placement="top-start">
                          <IconButton aria-label="delete">
                            <Icon icon={`ri:information-line`} color="#6161ee" width={27} height={27} />
                          </IconButton>
                        </Tooltip>{' '}
        {title}
                        
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{paddingTop:'5px'}}>
              {list &&
                list.map((item) => (
                  <List sx={{ width: '100%', bgcolor: 'background.paper' }} key={item}>
                    <ListItem divider style={{paddingTop:'0px'}}>
                      <ListItemText primary={ `${item.name} :`  } />
                      <ListItemText secondary={ `${item.reports} CF`} />
                      <Divider />
                    </ListItem>
                  </List>
                ))}
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

CardItemList.propTypes = {
  isLoading: PropTypes.bool,
};

export default CardItemList;
