import { Button, Grid, Paper, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import React from 'react';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

function BusinessPartnership() {
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" columnSpacing={10}>
        <Grid item xs={12} md={5} columnSpacing={2}>
          <Item>
            <img
              style={{
                borderRadius: '1rem',
                height: '70%',
                width: '100%',
              }}
              src="/static/img/getinvolved/business-partner.png"
              alt="heroImage"
              width={'560px'}
              height={'560px'}
            />
          </Item>
        </Grid>
        <Grid item xs={12} md={7} columnSpacing={2}>
          <Item>
            <Stack spacing={2}>
              <Item>
                <h2>Business Partnerships</h2>
              </Item>
              <Item>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Lacus condimentum nisi aliquet sed iaculis pharetra. Tortor
                  sed sed lorem facilisis risus morbi lectus congue id. Commodo eleifend laoreet aenean dolor vulputate
                  massa. Etiam et lorem justo dui in at tincidunt integer aliquam. Elementum egestas amet non interdum
                  pretium ullamcorper vestibulum. Et risus elementum magnis diam turpis proin dolor bibendum.{' '}
                </p>
              </Item>
              <Item>
                <ul>
                  <li>Lorem ipsum dolor sit amet consectetur.</li>
                  <li>Lorem ipsum dolor sit amet consectetur.</li>
                  <li>Lorem ipsum dolor sit amet consectetur.</li>
                  <li>Lorem ipsum dolor sit amet consectetur.</li>
                  <li>Lorem ipsum dolor sit amet consectetur.</li>
                </ul>
              </Item>
              <Item>
              {/* <Button variant="contained">Join Now</Button> */}
              <Button
                          variant="contained"
                          rel="noopener"
                          sx={{ padding: '.7rem 2rem' }}
                          onClick={() => alert("Partnership JOIIN NOW")}
                        >
                          Join Now
                        </Button>
              </Item>
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessPartnership;
