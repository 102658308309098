import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import APIServices from '../apiUtils/APIServices';
import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

Search.propTypes = {
  label: PropTypes.string.isRequired,
  getLabel: PropTypes.func,
  src: PropTypes.string.isRequired,
  paramName: PropTypes.string.isRequired,
  labelField: PropTypes.string,
  idField: PropTypes.string,
  params: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default function Search({ label, getLabel, onChange, src, paramName, params = {}, labelField, idField }){
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState()

  const getResults = async (input = '') => {
    setLoading(true)
    let url = `${src}?${paramName}=${input}`;
    const paramString = Object.keys(params).reduce((result, param) => `${param}=${params[param]}`, '')
    if (paramString){
      url += `&${paramString}`
    }
    const {data, success} = await new APIServices(url).get()
    setLoading(false)
    if (success) {
      setData(data || [])
    }
  }

  /* useEffect(() => {
    if (!src) {
      return;
    }
    // getResults()
  }, [src, params]) */

  return (
    <Autocomplete
      getOptionLabel={(option) => {
        if (getLabel){
          return getLabel(option)
        }
        if (typeof option === 'string') return option
        return labelField ? option[labelField] : option.value
      }}
      options = {data}
      autoComplete
      value={value}
      onChange={(event, newValue, reason) => {
        if (['selectOption', 'removeOption', 'clear'].includes(reason)) {
          onChange(idField ? newValue[idField] : newValue)
        }
      }}
      onInputChange={(event, newInput, reason) => {
        if (['input'].includes(reason))
          getResults(newInput)
      }}
      renderInput = {(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color={'primary'} size={20}/> : null }
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}
