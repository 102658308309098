import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, Container, Stack, Toolbar } from '@mui/material';
// hooks
import { t } from 'i18next';
import useResponsive from 'hooks/useResponsive';
// utils
import cssStyles from 'utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
import Logo from 'components/Logo';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import LanguagePopover from '../dashboard/header/LanguagePopover';
import useAuth from 'hooks/useAuth';
import AccountPopover from 'layouts/dashboard/header/AccountPopover';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const auth = window.localStorage.getItem('accessToken') !== null;

  const theme = useTheme();
  const { user } = useAuth();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  return (
    <AppBar position="relative" sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        sx={{
          ...cssStyles(theme).bgBlur(),
          height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
        }}
      >
        <Container
          maxWidth='xl'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            
          }}
        >
          <Logo />
          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <MenuDesktop isHome={isHome} navConfig={navConfig} />}

          <Stack direction={'row'} spacing={1}>
            {/* <Button
              variant="contained"
              target="_blank"
              rel="noopener"
              onClick={() => navigate(auth ? '/auth/login' : '/auth/register')}
            >
              {auth ? 'Dashboard' : t('auth.sign-up')}
            </Button> */} 
            

            {!user && isDesktop && (
              <Button
                variant="contained"
                target="_blank"
                rel="noopener"
                onClick={() => window.location.href = '/auth/login'}
              >

                {t('auth.login')}
              </Button>
            )}

          {user && (
            <AccountPopover />
            )}
            {/* <LanguagePopover /> */}

          </Stack>
          {!isDesktop && <MenuMobile isHome={isHome} navConfig={navConfig} />}
        </Container>
      </ToolbarStyle>
      {<ToolbarShadowStyle />}
    </AppBar>
  );
}
