import { createSlice } from '@reduxjs/toolkit';
import { getReports } from '../actions/reports';

const initialState = {
  reports: [],
  report: {},
};

const slice = createSlice({
  name : 'reports',
  initialState,
  extraReducers : {
    [getReports.fulfilled] : (state, action) => {
      state.reports = action.payload;
    }
  }
})

export default slice.reducer;