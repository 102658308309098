import * as React from 'react';
import { Card, CardContent, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import Page from 'components/Page';
import Iconify from 'components/Iconify';

ReportTypeBtn.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default function NewReports() {
  return (
    <Page title="Regions ">
      <HeaderBreadcrumbs
        heading="New Reports."
        links={[
          { name: 'Reports', href: PATH_DASHBOARD.root },
          { name: 'New Reports', href: PATH_DASHBOARD.dataRegions.root },
        ]}
      />
      <Stack spacing={2}>
        {REPORT_TYPE.map((rt, i) => (
          <ReportTypeBtn key={i} {...rt} />
        ))}
      </Stack>
    </Page>
  );
}

ReportTypeBtn.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  path: PropTypes.string
};

function ReportTypeBtn({ title, description, icon, path }) {
  return (
    <Card>
      <CardContent>
        <Iconify icon={icon} />
        <Link to={path} color="inherit" component={RouterLink}>
          <Typography variant={'h6'}>{title}</Typography>
          <Typography variant={'subtitle2'}>{description}</Typography>
        </Link>
      </CardContent>
    </Card>
  );
}

const REPORT_TYPE = [
  {
    title: 'General Data Collection Report',
    description: 'General Data Report',
    icon: '',
    path: 'data-collection',
  },
  {
    title: 'Enterprise Data Report',
    description: 'Enterprise Data Report',
    icon: '',
    path: '',
  },
  {
    title: 'Questionnaires',
    description: 'Questionnaires',
    icon: '',
    path: '',
  },
];
