import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLVectorLayer from "ol/layer/Vector";
import { Cluster } from 'ol/source';
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import Point from 'ol/geom/Point';
import {getCenter} from 'ol/extent'

const ClusterLayer = ({ source, style, zIndex = 0 , distance = 40, minZoom, maxZoom, name}) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    const clusterSource = new Cluster({
      distance,
      source,
      geometryFunction: (feature) => {
        const extent = feature.getGeometry().getExtent();
        const point = new Point(getCenter(extent));
        point.set('ignoreClick', true)
        return point;
      },
    })

    const vectorLayer = new OLVectorLayer({
      source: clusterSource,
      style
    });

    vectorLayer.set('name', name)
    if (minZoom) {
      vectorLayer.setMinZoom(minZoom)
    }

    if (maxZoom) {
      vectorLayer.setMaxZoom(maxZoom)
    }

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map]);

  return null;
};

export default ClusterLayer;
