import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
// routes
import { t } from 'i18next';
import { PATH_PAGE } from 'routes/paths';
// components
import Logo from 'components/Logo';
import Social from 'ui-component/Social';

// ----------------------------------------------------------------------

const LINKS = [
  // {
  //   headline: t('CF Data Portal'),
  //   children: [
  //     { name: t('About us'), href: PATH_PAGE.about },
  //     { name: t('Contact us'), href: PATH_PAGE.contact },
  //     { name: t('FAQs'), href: PATH_PAGE.faqs },
  //   ],
  // },
  {
    headline: t('Help'),
    children: [
      { name: t('About CF Data Portal'), href: '/about-us' },
      { name: t('FAQs'), href: '/faqs' },
      { name: t('Privacy Policy'), href: '/privacy-policy' },
      { name: t('Support'), href: '#' },
    ],
  },
  {
    headline: t('Contact'),
    children: [
      { name: '+977 01 123456', href: '#', icon: <img src='/icons/Phone_Call.png' alt='phone' /> },
      { name: 'cfdataportal@gmail.com', href: '#', icon: <img src='/icons/Mail.png' alt='email' /> },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary[50],
}));

const StyedLink = styled(Link)(({ theme }) => ({
  display: 'block',
  fontSize: 16,
  marginY: '5px',
  transitionDuration: '.5s',
  '&:hover': {
    color: theme.palette.primary[400],
    textDecoration: 'none',
    cursor: 'pointer',
  }
}))



// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Container maxWidth='xl' sx={{ pt: 10 }}>
        <Box marginLeft={{sm:'0', lg:'8rem'}} >
          <Grid
            container
            spacing={2}
          >
            <Grid item sm={12} lg={6} sx={{ mb: 3 }}>
              <Logo />
              <Typography marginTop={'12px'} component={'p'} variant="body2" sx={{ fontSize: 16 }}>
                {t('')}
              </Typography>

              <Typography variant='h6'>Connect with us online</Typography>
              <Stack direction={'row'} gap={2} marginTop={1} >
                
                <Social />

              </Stack>
            </Grid>
            {
              LINKS.map((list) => (
                <Grid item xs={12} sm={6} lg={3}>
                  <Typography component="h5" variant="overline" fontWeight={600} fontSize={16} marginBottom={'10px'} >
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Stack direction={'row'} alignItems={'center'}>
                      {
                        link.icon ?
                          <Box component={'span'} marginRight={1} borderRadius={'100%'} padding={'6px'} bgcolor={'#6B7280'}>
                            {link.icon}
                          </Box> : null
                      }
                      <StyedLink
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block', fontSize: 16, marginY: '5px' }}
                      >
                        {link.name}
                      </StyedLink>
                    </Stack>
                  ))}
                </Grid>

              ))
            }
          </Grid>
        </Box>

        <Divider sx={{ mt: 2 }} />
        <Typography
          component="p"
          variant="body2"
          sx={{ pt: 4, pb: 4, fontSize: 16, textAlign: 'center', }}> Copyright © {new Date().getFullYear()} CF Data Portal. All Rights Reserved.</Typography>
      </Container>
    </RootStyle>
  );
}
