import { useEffect, useState } from "react";
import {
    Typography,
    Stack
} from "@mui/material";
import DashContainer from "./DashContainer";

const DashCompositeNumeric = ({ data, title, ...props }) => {

    return (
        <DashContainer title={''} {...props}>
            <Stack direction={'column'} md={{height: '100%', background: 'blue', justifySelf: 'center', alignSelf: 'center'}}>
                {data.map((obj, index) => {
                    return (
                      <Stack direction={'column'} style={{height: 60, justifyContent: 'center'}}>
                        <Stack direction="row" key={index} style={{width: '100%'}}>
                          <Typography variant={'h6'}>{`${obj.title}: `}</Typography>
                          <Typography color={'primary'} variant={'h5'}>{`${obj.value}`}</Typography>
                        </Stack>
                      </Stack>
                    )
                })}
            </Stack>
        </DashContainer>
    );

}

export default DashCompositeNumeric;
