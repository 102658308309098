import Iconify from 'components/Iconify';

// ----------------------------------------------------------------------
// TODO: translate whole app

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: 'Explore',
    icon: <Iconify icon={'eva:map-outline'} {...ICON_SIZE} />,
    path: '/map',
  },
  // {
  //   title: 'Events & Programs',
  //   icon: <Iconify icon={'eva:map-outline'} {...ICON_SIZE} />,
  //   path: '#',
  // },
  {
    title: 'Get Involved',
    icon: <Iconify icon={'fluent-emoji-high-contrast:raised-hand'} {...ICON_SIZE} />,
    path: '/get-involved',
  },
  // {
  //   title: 'partners.title',
  //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
  //   path: '/partners',
  // },
  {
    title: 'Blog',
    icon: <Iconify icon={'mdi:blog-outline'} {...ICON_SIZE} />,
    path: '/blog',
  },
  {
    title: 'Contact Us',
    icon: <Iconify icon={'pixelarticons:contact'} {...ICON_SIZE} />,
    path: '/contact-us',
  }
  ,
  {
    title: 'Login',
    icon: <Iconify icon={'material-symbols-light:login-outline'} {...ICON_SIZE} />,
    path: '/auth/login',
  }
];

export default menuConfig;
