import { get, post, put, del, patch } from './api';

import contentDisposition from 'content-disposition';

class APIServices {
  url;

  name;

  data = [];

  success = false;

  constructor(url, name) {
    this.url = url;
    this.name = name;
  }

  getURL(id) {
    return id ? `/${this.url}/${id}` : `/${this.url}`;
  }

  async get() {
    await get({ url: this.url })
      .then((response) => {
        if (response.status < 300) {
          this.data = response.data;
          this.success = true;
        } else {
          this.data = response.data;
        }
      })
      .catch((e) => {
        this.data = e;
      });
    return { data: this.data, success: this.success };
  }

  // TODO: Needs refactoring. This should be pure function
  async post(formData) {
    await post({ url: this.url, data: formData })
      .then((response) => {
        if (response.status < 300) {
          this.data = response.data;
          this.success = true;
        } else {
          this.data = response.data;
        }
      })
      .catch((e) => {
        this.data = e;
      });
    return { data: this.data, success: this.success };
  }

  async put(id, formData) {
    await put({ url: this.getURL(id), data: formData })
      .then((response) => {
        if (response.status < 300) {
          this.data = response.data;
          this.success = true;
        } else {
          this.data = response.data;
        }
      })
      .catch((e) => {
        this.data = e;
      });
    return { data: this.data, success: this.success };
  }

  async patch(id, formData) {
    await patch({ url: this.getURL(id), data: formData })
      .then((response) => {
        if (response.status < 300) {
          this.success = true;
          this.data = response.data;
        } else {
          this.data = response.data;
        }
      })
      .catch((e) => {
        this.data = e;
      });
    return { data: this.data, success: this.success };
  }

  async delete(id) {
    await del(this.getURL(id))
      .then((response) => {
        if (response.status < 300) {
          this.success = true;
          this.data = response.data;
        } else {
          this.data = response.data;
        }
      })
      .catch((e) => {
        this.data = e;
      });
    return { data: this.data, success: this.success };
  }

  async download(id) {
    const token = window.localStorage.getItem('accessToken');
    await fetch(`${process.env.REACT_APP_HOST_API_KEY}${this.url}/${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).then ((response) => {
      if(response.status === 200) {
        response.blob().then(blob => {
          const objectUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          document.body.appendChild(link);
          link.href = objectUrl;
          link.setAttribute('type', 'hidden');
          const filename = contentDisposition.parse(response.headers.get('Content-Disposition')).parameters.filename;
          link.setAttribute('download', filename);
          link.click();
          window.URL.revokeObjectURL(objectUrl);
        })
        this.success = true;
      } else {
        this.success = false;
      }
    })
    .catch((e) => {
      this.data = e;
      this.success = false;
    });
    return { data: this.data, success: this.success};
  }
}

export default APIServices;
