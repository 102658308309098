import DashContainer from './DashContainer';
import {
    Typography,
} from "@mui/material";

const DashText = ({ title, data, unit, ...props }) => {
    return (
        <DashContainer {...props} title={title}>
            <Typography variant="h4" style={{color: 'green'}}>{(data ?? "-") + (unit ? ` (${unit})` : '')}</Typography>
        </DashContainer>
    );
};

export default DashText;
