import { createSlice } from '@reduxjs/toolkit';
import { getRegions } from '../actions/regions';

const initialState = {
  regions: [],
  region: {},
  cfList: [],
  cf: {},
};

const slice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    startLoading(state) {
      state.dataRegionsLoading = true;
    },
    hasError(state, action) {
      state.dataRegionsError = action.payload;
    },
    selectRegion: (state, action) => {
      state.region = state.regions.find((region) => region.id === parseInt(action.payload, 10));
    },
  },
  extraReducers: {
    [getRegions.fulfilled]: (state, action) => {
      state.regions = action.payload.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    },
    [getRegions.rejected]: (state, action) => {
      state.dataRegionsError = action.payload;
    },
  },
});

export default slice.reducer;

