import { Box, Button, Divider, Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react'


const OtherAuthButtons = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[300],
    fontSize: '14px', fontWeight: '500',
    borderRadius:'5px',
    '&:hover':{
        backgroundColor:theme.palette.grey[50],
        borderColor: theme.palette.grey[300],
    }
}));

export default function OtherWaysToLogin() {

    const theme = useTheme()
    return (
        <>
            <Stack marginY={3} direction={'row'} alignItems={'center'}>
                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                <Typography variant='body1' fontSize={'14px'} marginX={2}>
                    Or Login With
                </Typography>
                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <OtherAuthButtons variant="outlined" size="large">
                    <Box >
                        <img src={'/icons/facebook-colorful.svg'} alt="facebook" width={16} height={16} style={{ marginRight: 10 }} />
                    </Box>
                    Login with Facebook
                </OtherAuthButtons>

                <OtherAuthButtons variant="outlined" size="large" >
                    <Box >
                        <img src={'/icons/google-colorful.svg'} alt="google" width={16} height={16} style={{ marginRight: 10 }} />
                    </Box>
                    Login with Google
                </OtherAuthButtons>
            </Stack>
        </>
    )
}
