import { Grid } from '@mui/material';
import React from 'react';
import TitleList from './TitleList';

function FloraFaunaNtfpsActivities({isLoading, majorFauna, majorFlora, majorNTFPs, forestMgmtActivities}) {
  return (
    <>
      <Grid
        container
        style={{ marginTop: '5px' }}
        spacing={{ xs: 1, sm: 2, md: 5 }}
        rowSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <TitleList title={majorFauna?.title} list={majorFauna?.data} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TitleList title={majorFlora?.title} list={majorFlora?.data} isLoading={isLoading} />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TitleList title={forestMgmtActivities?.title} list={forestMgmtActivities?.data} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TitleList title={majorNTFPs?.title} list={majorNTFPs?.data} isLoading={isLoading} />
        </Grid>
      </Grid> 
    </>
  );
}

export default FloraFaunaNtfpsActivities;
