import {
  Card, Chip,
  Stack,
  Typography,
} from '@mui/material';

const DashHeader = ({ name, type, cfugCode, badges = [], reportType }) => {
    return (
        <Card
            variant="outlined"
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                py: 2,
                my: .5
            }}
        >
            <Typography variant="h6">{name}</Typography>
            <Stack direction={'column'}>
              <Stack direction="row" spacing={5}>
                {reportType !== 'MUNICIPAL' && cfugCode && <Typography variant="subtitle1">{'CFUG Code: '} ${cfugCode || ''}</Typography>}
                {type && <Typography variant="subtitle1">{`${reportType !== 'MUNICIPAL' ? 'Type: ' : ''}${type}`}</Typography>}
              </Stack>
              <Stack direction={'row'} spacing={5} justifyContent={'center'}>
                { badges && badges.data && badges.data.map((badge, index) => <Chip size={'medium'} color="primary" key={index} label={badge} variant={'outlined'} />) }
              </Stack>
            </Stack>

        </Card>
    )
}

export default DashHeader;
