import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApi } from './fetchApi';
import { GENERAL_INFO_REPORT, GET_REPORTER_LIST, UPLOAD_FILE } from '../../backend/url_helper';

export const getReports = createAsyncThunk('reports/getReports', async (payload, thunkAPI) => {
  try {
    const reports = await fetchApi(GET_REPORTER_LIST, 'GET');
    return thunkAPI.fulfillWithValue(reports?.items);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const uploadFile = createAsyncThunk('reports/uploadFile', async (payload, thunkAPI) => {
  try {
    const uploadedFile = await fetchApi(UPLOAD_FILE, 'POST', {
      generalInfo: payload,
    });
    return thunkAPI.fulfillWithValue(uploadedFile);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const postGeneralInfoReport = createAsyncThunk('reports/postGeneralInfoReport', async (payload, thunkAPI) => {
  // const Data = {
  //   "reportingId": 0,
  //   "id": 0,
  //   "contactInfo": {
  //     "id": 0,
  //     "name": "string",
  //     "position": "string",
  //     "contactNumber": "string"
  //   },
  //   "generalInfo": {
  //     "name": "string",
  //     "fileNo": "string",
  //     "cfugCode": "string",
  //     "geoGraphicalRegion": "HIMALAYAN",
  //     "regionId": 0,
  //     "wards": [
  //       0
  //     ],
  //     "divisionForestOffice": "string",
  //     "subDivisionForestOffice": "string",
  //     "handOverDate": "2022-03-01",
  //     "mapData": {
  //       "attachmentId": 0,
  //       "type": "GPS_FIELD_BOOK"
  //     },
  //     "minAltitude": 0,
  //     "maxAltitude": 0,
  //     "area": 0,
  //     "membershipRenewalDate": "2022-03-01",
  //     "forestId": 0,
  //     "infoOfficerContact": {
  //       "id": 0,
  //       "name": "string",
  //       "position": "string",
  //       "contactNumber": "string"
  //     },
  //     "groupOfficerContact": {
  //       "id": 0,
  //       "name": "string",
  //       "position": "string",
  //       "contactNumber": "string"
  //     },
  //     "fecofunMember": true
  //   },
  //   "committeeDetails": {
  //     "id": 0,
  //     "date": "2022-03-01",
  //     "total": 0,
  //     "maleCount": 0,
  //     "femaleCount": 0,
  //     "othersCount": 0,
  //     "ethnicGenderCount": [
  //       {
  //         "id": 0,
  //         "ethnicity": "ALL",
  //         "maleCount": 0,
  //         "femaleCount": 0,
  //         "othersCount": 0
  //       }
  //     ],
  //     "mainPostDetails": [
  //       {
  //         "date": "2022-03-01",
  //         "post": "PRESIDENT",
  //         "name": "string",
  //         "ethnicity": "ALL",
  //         "gender": "MALE"
  //       }
  //     ],
  //     "committeeProsperity": {
  //       "levelACount": 0,
  //       "levelBCount": 0,
  //       "levelCCount": 0,
  //       "levelDCount": 0
  //     },
  //     "subCommitteeGenderCount": [
  //       {
  //         "name": "string",
  //         "date": "2022-03-01",
  //         "maleCount": 0,
  //         "femaleCount": 0,
  //         "othersCount": 0
  //       }
  //     ]
  //   },
  //   "mainPostDetails": [
  //     {
  //       "date": "2022-03-01",
  //       "post": "PRESIDENT",
  //       "name": "string",
  //       "ethnicity": "ALL",
  //       "gender": "MALE"
  //     }
  //   ],
  //   "actionPlan": {
  //     "id": 0,
  //     "forestId": 0,
  //     "date": "2022-03-01",
  //     "renewedTimes": 0,
  //     "renewalDate": "2022-03-01",
  //     "period": 0,
  //     "lastRenewalDate": "2022-03-01",
  //     "handOverDate": "2022-03-01",
  //     "handOverStatus": "NATURAL"
  //   },
  //   "cfugDetails": {
  //     "id": 0,
  //     "date": "2022-03-01",
  //     "cfugEthnicDetails": [
  //       {
  //         "id": 0,
  //         "ethnicity": "ALL",
  //         "houseHold": 0,
  //         "population": 0,
  //         "female": 0,
  //         "male": 0,
  //         "singleWomen": 0,
  //         "femaleHeadedHouseHold": 0,
  //         "disabled": 0
  //       }
  //     ],
  //     "cfugProsperity": [
  //       {
  //         "id": 0,
  //         "ethnicity": "ALL",
  //         "levelACount": 0,
  //         "levelBCount": 0,
  //         "levelCCount": 0,
  //         "levelDCount": 0
  //       }
  //     ],
  //     "forestId": 0
  //   },
  //   "subCommitteeGenderCount": [
  //     {
  //       "name": "string",
  //       "date": "2022-03-01",
  //       "maleCount": 0,
  //       "femaleCount": 0,
  //       "othersCount": 0
  //     }
  //   ]
  // }

  try {
    const generalInfoReport = await fetchApi(GENERAL_INFO_REPORT, 'POST', {
      generalInfo: payload,
    });

    return thunkAPI.fulfillWithValue(generalInfoReport);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
