import APIServices from 'apiUtils/APIServices';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  growingStocksColumnChartdata,
  populationPieChartData,
  ethnicityPieChartData,
  mappingRightsColumnChartdata,
} from 'pages/explore/data/chart';
import GrowingStocks from 'sections/@dashboard/general/app/components/GrowingStocks';
import EmploymentEnterprise from 'sections/@dashboard/general/app/components/EmploymentEnterprise';
import FloraFaunaNtfpsActivities from 'sections/@dashboard/general/app/components/FloraFaunaNtfpsActivities';
import WetHumanStock from 'sections/@dashboard/general/app/components/WetHumanStock';

import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { useSearchParams } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Stack } from '@mui/material';
import TimberLineChart from 'pages/explore/Components/TimberLineChart';
import TimeberMember from 'sections/@dashboard/general/app/components/TimeberMember';
import MapCf from 'sections/@dashboard/general/app/components/MapCf';
import Gis from 'pages/gis';
import CardItemList from 'sections/@dashboard/general/app/components/CardItemList';
import StatCard from 'sections/@dashboard/general/app/components/StatCard';
import StockBarChart from 'pages/explore/Components/StockBarChart';
import Paper from '@mui/material/Paper';
// import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function AreaAnalytics() {
  const { type, id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIstLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');

  const path = `browse/${type}s/${id ?? 'all'}/data`;
  const title = `Analytics -  ${id && id !== 'all' ? 'Individual' : 'All'} ${
    type.charAt(0).toUpperCase() + type.slice(1)
  } `;
  const fetchData = async () => {
    const { data, success, loading } = await new APIServices(path).get();
    if (success) {
      setData(data?.data);
      setIstLoading(false);
    }
  };

  const {
    aac,
    area,
    employments,
    enterpriseEmployment,
    enterprises,
    forestMgmtActivities,
    growingStockFirewood,
    growingStockSpecies,
    growingStockTimber,
    household,
    humanWildlifeConflicts,
    majorFauna,
    majorFlora,
    majorNTFPs,
    majorTreeSpecies,
    populationByEthnicity,
    populationByGender,
    wetlands,
    mapVisualization,
    customaryForests,
    mappings,
  } = data;

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title={type}>
      <HeaderBreadcrumbs heading={title} links={[{ name }]} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2} marginTop={'10px'}>
            <Grid item xs={6} md={8}>
              <Card sx={{ bgcolor: '#cfe8fc', height: '53vh' }}>
                <CardContent style={{ padding: 0 }}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '53vh',
                    }}
                  >
                    <Gis mapVisualization={mapVisualization} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={4}>
              <Stack direction="column" spacing={2}>
                <Item>
                  {customaryForests?.data && <StatCard
                    isLoading={isLoading}
                    title={`Total ${customaryForests?.title}`}
                    icon={{
                      iconName: 'healthicons:forest-outline',
                      title: 'Volunteers',
                      color: '#008635',
                    }}
                    value={customaryForests?.data}
                    bgColor={'#E6F4EB'}
                    textColor={`#000`}
                    style={{ marginBottom: '12px' }}
                  />}
                </Item>
                {/* <Item>
                  <StockBarChart
                    title={`Mapping Rights`}
                    isLoading={isLoading}
                    data={mappingRightsColumnChartdata}
                    series={mappings?.data[0]}
                  />
                </Item> */}
              </Stack>
            </Grid>
          </Grid>

          <GrowingStocks
            isLoading={isLoading}
            growingStockSpecies={growingStockSpecies}
            populationByGender={populationByGender}
            populationByEthnicity={populationByEthnicity}
            growingStocksColumnChartdata={growingStocksColumnChartdata}
            populationPieChartData={populationPieChartData}
            ethnicityPieChartData={ethnicityPieChartData}
          />
          <EmploymentEnterprise
            isLoading={isLoading}
            employmentsData={employments?.data}
            enterpriseEmploymentData={enterpriseEmployment?.data}
            enterprisesData={enterprises?.data}
          />

          <FloraFaunaNtfpsActivities
            isLoading={isLoading}
            majorFauna={majorFauna}
            majorFlora={majorFlora}
            majorNTFPs={majorNTFPs}
            forestMgmtActivities={forestMgmtActivities}
          />

<Grid container spacing={2} marginTop={'10px'}>
            <Grid item xs={6} md={4}>
            <TimberLineChart isLoading={isLoading} series={[growingStockTimber?.data, aac]} />
            </Grid>
            <Grid item xs={6} md={4}>
            <StockBarChart
                    title={`Governance Mapping`}
                    isLoading={isLoading}
                    data={mappingRightsColumnChartdata}
                    series={mappings?.data[1]}
                    name="Governance"
                  />
            </Grid>
            <Grid item xs={6} md={4}>
            <StockBarChart
                    title={`Rights Mapping`}
                    isLoading={isLoading}
                    data={mappingRightsColumnChartdata}
                    series={mappings?.data[0]}
                    name="Rights"
                  />
            </Grid>
          </Grid>

         

          <WetHumanStock
            isLoading={isLoading}
            wetLandData={wetlands?.data}
            humanData={humanWildlifeConflicts?.data}
            stockData={growingStockFirewood?.data}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default AreaAnalytics;
