import { Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Iconify from 'components/Iconify';
import { fNumber } from 'utils/formatNumber';

OverviewStat.propTypes = {
  title : PropTypes.string,
  icon : PropTypes.string,
  totalStat : PropTypes.number,
  measurement : PropTypes.string,
  stat : PropTypes.number,
}

export default function OverviewStat({ title, icon, stat, totalStat, measurement }) {
  return (
    <Stack width={1} textAlign="center" alignItems={'center'}>
      <Iconify icon={icon} width={48} height={48} />
      <Stack direction={'row'} alignItems={'baseline'} sx={{ color: 'primary.dark' }}>
        <Typography variant="h4">{fNumber(stat)}</Typography>
        {totalStat && (
          <Typography variant="subtitle1" ml={1}>
            {!measurement ? `/ ${totalStat}` : totalStat}
          </Typography>
        )}
      </Stack>
      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
    </Stack>
  );
}