import React, { useState } from 'react';
import {
    Box,
    ImageListItem,
    ImageListItemBar,
    Stack,
    Typography,
} from '@mui/material';

import DelteIcon from '../../../../../../../assets/dustbin-icon.svg'

import DevelopmentAndManagementModal from './DevelopmentAndManagementModal';
import DevelopmentAndManagementDeleteModal from './DevelopmentAndManagementDeleteModal';
import DevelopmentAndManagementPreviewModal from './DevelopmentAndManagementPreviewModal';


export default function DevelopmentAndManagementImageUpload({ values, setValue, unregister }) {
    console.log(values)

    const [deleteModal, setDeleteModal] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)
    const [selectedPhoto, setSelectedPhoto] = useState(null)

    const toggleDeleteModal = () => setDeleteModal(!deleteModal)
    const togglePreviewModal = () => setPreviewModal(!previewModal)

    const onClickDeleteIcon = (photo) => {
        setDeleteModal(true)
        setSelectedPhoto(photo)
    }

    const onClickImagePreview = (photo) => {
        setSelectedPhoto(photo)
        setPreviewModal(true)
    }


    // const { enqueueSnackbar } = useSnackbar();
    const token = window.localStorage.getItem('accessToken');
    const apiBase = process.env.REACT_APP_HOST_API_KEY;

    const getSrc = (photo) => {
        return `${apiBase}attachments/${photo.attachment}?token=${token}`;
    };

    const deletePhoto = (photo) => {
        setValue('images', values.images.filter((image) => image.attachment !== photo.attachment))
        setDeleteModal(false)
        if (previewModal) {
            setPreviewModal(false)
        }
    }
    return (
        <>
            <Typography variant={'h6'} padding={1}>
                Upload Image
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} width={"100%"} spacing={"16px"} paddingX={2}>
            {
                    values.images.map((photo, index) => (
                        <Box position={'relative'} >
                            <img src={DelteIcon} alt='Delete Icon' width={30}
                                style={{ padding: '6px', position: 'absolute', top: '10px', right: '10px', borderRadius: '100%', border: '1px solid #D1D5DB', zIndex: 1, backgroundColor: 'white', cursor: 'pointer' }}
                                onClick={() => onClickDeleteIcon(photo)}
                                aria-hidden="true"
                            />
                            <ImageListItem key={index} component={'div'} onClick={() => onClickImagePreview(photo)} aria-hidden='true'>
                                <img
                                    src={getSrc(photo)}
                                    srcSet={getSrc(photo)}
                                    alt={photo.caption}
                                    loading='lazy'
                                    style={{ width: '208px', height: ' 208px', borderRadius: '8px', border: '1px solid whitesmoke' }}
                                />
                                <ImageListItemBar
                                    title={photo.caption}
                                />
                            </ImageListItem>
                        </Box>
                    ),
                    )
                }
                <DevelopmentAndManagementModal values={values} setValue={setValue} />
                <DevelopmentAndManagementDeleteModal deletePhoto={deletePhoto} selectedPhoto={selectedPhoto} deleteModal={deleteModal} toggleDeleteModal={toggleDeleteModal} />
                <DevelopmentAndManagementPreviewModal onClickDeleteIcon={onClickDeleteIcon} previewModal={previewModal} togglePreviewModal={togglePreviewModal} selectedPhoto={selectedPhoto} getSrc={getSrc} DelteIcon={DelteIcon} />
            </Stack>
        </>
    )
}



