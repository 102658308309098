import { Stack, Typography } from '@mui/material';

const ListView = ({data}) => {
  return (
    <Stack paddingLeft={2} direction={'column'}>
      { data.data.map((item, index) => <Typography variant={'h7'}>{`${index + 1}. ${item}`}</Typography>) }
    </Stack>
  )
}

export default ListView;
