import { createSlice } from '@reduxjs/toolkit';
import { getReporters } from 'redux/actions/reporter';

const initialState = {
  reporters: [],
  gi: {
    forestId: 0,
    contactInfo: {
      id: 0,
      name: 'string',
      position: 'string',
      contactNumber: 'string',
    },
    generalInfo: {
      id: 0,
      name: 'string',
      fileNo: 'string',
      cfugCode: 'string',
      geoGraphicalRegion: 'HIMALAYAN',
      regionId: 0,
      wards: [0],
      divisionForestOffice: 'string',
      subDivisionForestOffice: 'string',
      handOverDate: '2022-03-02',
      mapData: {
        attachmentId: 0,
        type: 'GPS_FIELD_BOOK',
      },
      minAltitude: 0,
      maxAltitude: 0,
      area: 0,
      membershipRenewalDate: '2022-03-02',
      forestId: 0,
      infoOfficerContact: {
        id: 0,
        name: 'string',
        position: 'string',
        contactNumber: 'string',
      },
      groupOfficerContact: {
        id: 0,
        name: 'string',
        position: 'string',
        contactNumber: 'string',
      },
      fecofunMember: true,
    },
    committeeDetails: {
      id: 0,
      date: '2022-03-02',
      total: 0,
      maleCount: 0,
      femaleCount: 0,
      othersCount: 0,
      ethnicityGenderCount: [
        {
          id: 0,
          ethnicity: 'ALL',
          maleCount: 0,
          femaleCount: 0,
        },
      ],
      mainPostDetails: [
        {
          date: '2022-03-02',
          post: 'PRESIDENT',
          name: 'string',
          ethnicity: 'ALL',
          gender: 'MALE',
        },
      ],
      committeeProsperity: {
        levelACount: 0,
        levelBCount: 0,
        levelCCount: 0,
        levelDCount: 0,
      },
      subCommitteeGenderCount: [
        {
          name: 'string',
          date: '2022-03-02',
          maleCount: 0,
          femaleCount: 0,
          othersCount: 0,
        },
      ],
    },
    mainPostDetails: [
      {
        date: '2022-03-02',
        post: 'PRESIDENT',
        name: 'string',
        ethnicity: 'ALL',
        gender: 'MALE',
      },
    ],
    actionPlan: {
      id: 0,
      forestId: 0,
      date: '2022-03-02',
      renewedTimes: 0,
      renewalDate: '2022-03-02',
      period: 0,
      lastRenewalDate: '2022-03-02',
      handOverDate: '2022-03-02',
      handOverStatus: 'NATURAL',
    },
    cfugDetails: {
      id: 0,
      date: '2022-03-02',
      cfugEthnicDetails: [
        {
          id: 0,
          ethnicity: 'ALL',
          houseHold: 0,
          population: 0,
          female: 0,
          male: 0,
          singleWomen: 0,
          femaleHeadedHouseHold: 0,
          disabled: 0,
        },
      ],
      cfugProsperity: [
        {
          id: 0,
          ethnicity: 'ALL',
          levelACount: 0,
          levelBCount: 0,
          levelCCount: 0,
          levelDCount: 0,
        },
      ],
      forestId: 0,
    },
    subCommitteeGenderCount: [
      {
        name: 'string',
        date: '2022-03-02',
        maleCount: 0,
        femaleCount: 0,
        othersCount: 0,
      },
    ],
  },
};

const slice = createSlice({
  name: 'reporters',
  initialState,
  extraReducers: {
    [getReporters.fulfilled]: (state, action) => {
      state.items = action.payload;
    },
    [getReporters.rejected]: (state, action) => {
      state.dataRegionsError = action.payload;
    },
  },
});

export default slice.reducer;
