import React from 'react';
import { t } from 'i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
  InputAdornment,
} from '@mui/material';

import { statuses } from '.';
import { RHFRadioGroup, RHFSelect, RHFTextField } from 'components/hook-form';
import { nepaliEthnicityB } from 'dataTypes';
import { LabelStyle } from '../StyledComponents';
import Iconify from 'components/Iconify';

const LandslideDetail = ({ values }) => (
  <>
    <Typography variant={'h6'} padding={1}>
      10. Landslide Detail
    </Typography>
    <Stack spacing={2} paddingX={3}>
      <RHFRadioGroup name="hasLandslideOccurred" options={[true, false]} getOptionLabel={statuses} />
      {values.hasLandslideOccurred === 'true' ? (
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={2}
          paddingY={2}
        >
          <RHFTextField
            name="landslide.landslideCount"
            label="Landslide Count"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'mdi:elevation-decline'} />
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            name="landslide.affectedArea"
            label="Affected Area"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'mdi:elevation-decline'} />
                </InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">HA</InputAdornment>,
            }}
          />
          {/* <RHFTextField name="landslideDamages.type" label="" fullWidth /> */}
        
          <RHFSelect
                    name="landslideDamages.type"
                    label="Landslide Type"
                    placeholder="Landslide Type"
                  >
                    <option value="" />
                    {landSlideTypes?.map((item) => (
                      <option key={item.key} value= {item.key}>                       
                        { item.label}
                      </option>
                    ))}
                  </RHFSelect>

        </Stack>
      ) : null}
    </Stack>
    <br />
    {values.hasLandslideOccurred === 'true' ? (
      <>
        <Typography variant={'h6'} padding={1}>
          11. Damage details caused by Landslide
        </Typography>
        <Stack spacing={2} paddingX={3}>
          <LabelStyle paddingX={2}>Humanitarian damage</LabelStyle>
          <TableContainer
            sx={{
              my: 1,
            }}
          >
            <Table>
              <TableHead>
                <TableCell>Ethnicity</TableCell>
                <TableCell>Male</TableCell>
                <TableCell>Female</TableCell>
                <TableCell>Others</TableCell>
              </TableHead>
              <TableBody>
                {nepaliEthnicityB.map((wc, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        minWidth: 250,
                      }}
                    >
                      {wc.name}
                    </TableCell>
                    <TableCell colSpan={1}>
                      <RHFTextField label={t('Number')} name={`landslideDamages.humanLosses.${i}.maleCount`} />
                    </TableCell>
                    <TableCell>
                      <RHFTextField label={t('Number')} name={`landslideDamages.humanLosses.${i}.femaleCount`} />
                    </TableCell>
                    <TableCell>
                      <RHFTextField label={t('Number')} name={`landslideDamages.humanLosses.${i}.othersCount`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={2}
          >
            <Stack width={'25%'}>
              <LabelStyle>Homes</LabelStyle>
              <RHFTextField
                label={t('Number')}
                fullWidth
                name={`landslideDamages.otherLosses[0].quantity`}
                type="number"
                min={0}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Iconify icon="mdi:home" />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width={'25%'}>
              <LabelStyle>Pets</LabelStyle>
              <RHFTextField
                label={t('Number')}
                fullWidth
                name={`landslideDamages.otherLosses[1].quantity`}
                type="number"
                min={0}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Iconify icon="mdi:cow" />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width={'25%'}>
              <LabelStyle>Agricultural Damage (Quintal)</LabelStyle>
              <RHFTextField
                label={t('Number')}
                fullWidth
                name={`landslideDamages.otherLosses[2].quantity`}
                type="number"
                min={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color={'text.secondary'}>Q</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width={'25%'}>
              <LabelStyle>Agricultural Damage ( Hectare )</LabelStyle>
              <RHFTextField
                label={t('Number')}
                fullWidth
                name={`landslideDamages.otherLosses[3].quantity`}
                type="number"
                min={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color={'text.secondary'}>HA</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </>
    ) : null}
  </>
);

export default LandslideDetail;

const landSlideTypes = [
  { key: 'सुक्खा पहिरो', label: 'सुक्खा पहिरो' },
  { key: 'लेदो पहिरो', label: 'लेदो पहिरो' },
  { key: 'नदी कटान', label: 'नदी कटान' },
]