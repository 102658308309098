import DashContainer from "./DashContainer";
import {
    BarChart,
    Bar,
    LabelList,
    Tooltip,
} from "recharts";

const DashBarGraph = ({ title, data, ...props }) => {

    const customData = Object.entries(data).map((val, i) => {
        const foo = {};
        foo.name = val[0];
        foo.value = Number(val[1].toFixed(2));
        return foo;
    });

    return (
        <DashContainer title={title} {...props}>
            <BarChart width={200} height={200} data={customData} margin={{ top: 50 }}>
                <Bar dataKey="value" fill="#8884d8">
                    <LabelList dataKey="name" position="top" style={{ fontSize: 12 }} />
                </Bar>
                <Tooltip />
            </BarChart>
        </DashContainer>
    );
};

export default DashBarGraph;