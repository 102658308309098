import { createSlice } from '@reduxjs/toolkit';
import { getUserList, verifyAndSetUserProfile } from '../actions/users';

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload; // Assuming payload is an array of users
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Assuming error message is available in action.error.message
      })
      .addCase(verifyAndSetUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyAndSetUserProfile.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(verifyAndSetUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Assuming error message is available in action.error.message
      });
  },
});

export const selectUsers = (state) => state.users.users;
export const selectLoading = (state) => state.users.loading;
export const selectError = (state) => state.users.error;

export default usersSlice.reducer;
