import React from 'react'
import { Box, Modal, Typography, styled } from '@mui/material'

const StyledModalContainer = styled(Box)(({ theme }) => ({
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    borderRadius: ' 12px',
    backgroundColor: '#FFF',
    boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
    padding: '12px'
}));

export default function DevelopmentAndManagementPreviewModal({onClickDeleteIcon, previewModal, togglePreviewModal, selectedPhoto, getSrc, DelteIcon }) {
  return (
    <>
    {previewModal &&
        <Modal
            open={previewModal}
            onClose={togglePreviewModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <StyledModalContainer>
                <Box position={'relative'}>
                    <img src={DelteIcon} alt='Delete Icon' width={30}
                        style={{ padding: '6px', position: 'absolute', top: '10px', right: '10px', borderRadius: '100%', border: '1px solid #D1D5DB', zIndex: 1, backgroundColor: 'white', cursor: 'pointer' }}
                        onClick={() => onClickDeleteIcon(selectedPhoto)}
                        aria-hidden="true"
                    />
                    <img
                        src={getSrc(selectedPhoto)}
                        srcSet={getSrc(selectedPhoto)}
                        alt={selectedPhoto.caption}
                        loading='lazy'
                        style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius:'8px'
                        }}
                    />
                    <Typography marginTop={'10px'} fontSize={'14px'} fontWeight={'600'} >
                        {selectedPhoto.caption}
                    </Typography>
                </Box>
            </StyledModalContainer>
        </Modal>
    }
</>
  )
}
