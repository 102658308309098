import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TableSortLabel,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import writeXlsxFile from 'write-excel-file';
import DocxUtil from '../../../utils/docxUtil';
import { useState } from 'react';

const  TableView = ({table, onSortChange = (column, order) => {}}) => {
  const [sort, setSort] = useState({})
  const exportToExcel = async (table) => {
    const tableHeaders = table?.data?.header?.rows ?? []
    const headerColumns = tableHeaders.map(row => row.columns)
    const newHeaderColumns = []
    headerColumns.forEach((cols, rowIndex) => {
      cols.forEach((col, colIndex) => {
        const colSpan = col.colSpan || 1
        const rowSpan = col.rowSpan || 1
        Array(rowSpan).fill(0).forEach((_, index) => {
          if (newHeaderColumns.length < rowIndex + 1 + index) {
            newHeaderColumns.push([])
          }
        })
        Array(rowSpan).fill(0).forEach((_, r) => {
          Array(colSpan).fill(0).forEach((_, c) => {
            if (r === 0 && c === 0){
              newHeaderColumns[rowIndex].push(col)
            } else {
              newHeaderColumns[rowIndex + r].push(null)
            }
          })
        })
      })
    })

    const headers = newHeaderColumns.map(cols => cols.map(col => {
      return !col ? null : {
        value: col.content,
        fontWeight: 'bold',
        span: col.colSpan ?? 1,
        rowSpan: col.rowSpan ?? 1,
        align: col.align ?? 'center',
        wrap: true,
      }
    }))

    const body = table.data.data.map(row => row.columns.map(col => {
      return !col ? null : {
        value: col.content,
        colSpan: col.rowSpan ?? 1,
        rowSpan: col.colSpan ?? 1,
        align: col.align ?? 'center',

      }
    }))
    await writeXlsxFile([...headers, ...body], {
      fileName: `${table.title}.xlsx`
    })
  }
  const onSort = (column) => {
    let direction = 'asc'
    if (column === sort.column) {
      direction = sort.direction === 'asc' ? 'desc' : 'asc'
    }
    setSort({column, direction})
    onSortChange(column, direction)
    console.log(sort)
  }
  return (
    <Stack direction={'column'}>
      <Stack paddingX={1.5} direction={'row-reverse'}>
        <IconButton variant={'outlined'} onClick={() => exportToExcel(table)}>
          <Download color={'primary'}/>
        </IconButton>
      </Stack>
      <TableContainer component={'div'}>
        <Table
          sx={{minWidth: 750}}>
          <TableHead>
            {table.data.header.rows.map((row, index) => (
              <TableRow style={{marginBottom: 1}} key={index}>
                { row.columns.map((col, index) => (
                  <TableCell
                    key={col.name ?? index}
                    align={col.alignment ?? 'center'}
                    rowSpan={col.rowSpan || 1}
                    colSpan={col.colSpan || 1}
                    sortDirection={sort.column === col.name ? (sort.direction ?? 'asc') : undefined }
                  >
                    {
                      table.sortColumns ? (
                        <TableSortLabel
                          active = {sort.column === col.name}
                          direction={sort.direction ?? 'asc'}
                          onClick={() => onSort(col.name)}
                        >
                          {col.content}
                          <Box component="span"/>
                        </TableSortLabel>
                      ) : col.content
                    }

                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {
              table.data.data.map((row, index) => (
                <TableRow key={index}>
                  {row.columns.map((col, index) => (
                    <TableCell
                      key={index}
                      align={col.alignment ?? 'center'}
                      rowSpan={col.rowSpan || 1}
                      colSpan={col.colSpan || 1}
                    >
                      {col.content}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default TableView;
