import React from 'react';
import { t } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'redux/store';
import { Card, CardContent, CardHeader, Stack, MenuItem } from '@mui/material';
import { FormProvider, MultipleSelect, RHFTextField } from 'components/hook-form';
import RHFAutocomplete from 'components/hook-form/RHFAutocomplete';

export default function AddReporter({ onSubmit, methods }) {
  const { regions } = useSelector((state) => state.regions);
  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    setValue,
    reset,
  } = methods;
  const values = watch() || {};
  return (
    <Card>
      <CardHeader title={t(`users.${values?.id ? 'update' : 'add'}-reporter`)} />
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <MultipleSelect name="regionIds" label="Regions" value={values?.regionIds}>
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </MultipleSelect>
            {/* <RHFAutocomplete
              name="regionIds"
              placeholder="Regions"
              multiple
              labelKey="name"
              options={regions || []}
              value={values.regionIds || []}
              onChange={(value) => setValue('regionIds', value)}
            /> */}

            <RHFTextField name="name" label={t('Name')} disabled={values?.id} />
            <RHFTextField name="email" label={t('Email')} disabled={values?.id} />
            <Stack alignItems="flex-end" sx={{ mt: 3 }} direction={{ ms: 'row' }} justifyContent="flex-end">
              <LoadingButton sx={{ width: 160, xs: 'column', sm: 'row' }} variant="text" onClick={(_) => reset({})}>
                {t('Reset')}
              </LoadingButton>
              <LoadingButton sx={{ width: 160 }} type="submit" variant="contained" loading={isSubmitting}>
                {t(`users.${values?.id ? 'update' : 'add'}-reporter`)}
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      </CardContent>
    </Card>
  );
}
