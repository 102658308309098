import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLVectorLayer from "ol/layer/Vector";

const VectorLayer = ({ source, style, zIndex = 0 , minZoom, maxZoom, name}) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        const vectorLayer = new OLVectorLayer({
            source,
            style
        });

        vectorLayer.set('name', name)
        if (minZoom) {
            vectorLayer.setMinZoom(minZoom)
        }

        if (maxZoom) {
            vectorLayer.setMaxZoom(maxZoom)
        }

        map.addLayer(vectorLayer);
        vectorLayer.setZIndex(zIndex);

        return () => {
            if (map) {
                map.removeLayer(vectorLayer);
            }
        };
    }, [map]);

    return null;
};

export default VectorLayer;
