import {
    Button, Card,
    CardContent, Checkbox, CircularProgress,
    Container, Divider, FormControlLabel, Grid,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination, TableRow, TextField, Typography,
  } from '@mui/material';
  import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
  import { PATH_DASHBOARD } from '../../routes/paths';
  import * as React from 'react';
  import { useEffect, useState } from 'react';
  import APIServices from '../../apiUtils/APIServices';
  import TableView from '../analytics/components/TableView';
  import SearchField from '../../components/Search';
  import Backdrop from '@mui/material/Backdrop';
  import { Link } from 'react-router-dom';
import Label from 'components/Label';
  
  const Partners = () => {
    const [municipalAreas, setMunicipalAreas] = useState([])
    const [size, setSize] = useState(10)
    const [page, setPage] = useState(0)
    const [query,setQuery] = useState('')
    const [loading, setLoading] = useState(false)
  
  
    const fetchData = async () => {
      setLoading(true)
      const params = {query, page, size}
      const {data, success} = await new APIServices('browse/partners?page=0&size=10').get(params)
      setLoading(false)
      if (success){
        setMunicipalAreas(data?.data || [])
      }
    }
  
    useEffect(() => {
      fetchData()
    }, [size, page, query])
  
    return (
      <Container>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack direction={'column'} spacing={2}>
          <HeaderBreadcrumbs
            heading="Partners"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.app },
              { name: 'Search', href: PATH_DASHBOARD.search.root },
            ]}
          />
  
          <Grid item xs={3}>
            <TextField
              style={{width: '100%'}}
              variant={'outlined'}
              placeholder={'Search'}
              onChange={({ target }) => {
                setQuery(target.value)
                if (target.value && target.value.length >= 3){
                  fetchData()
                }
              }
              }
            />
          </Grid>
  
          <TableContainer component={'div'}>
            <Table
              sx={{minWidth: 750}}>
              <TableHead>
                <TableCell>Id</TableCell>
                <TableCell>Partner</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell width={150}>Action</TableCell>
              </TableHead>
              <TableBody>
                {
                  municipalAreas.map((partner,index) => (
                    <TableRow key={index}>
                      <TableCell>{page * size + (index + 1) }</TableCell>
                      <TableCell>{partner.fullName}</TableCell>
                      <TableCell>{partner.username}</TableCell>
                      <TableCell>{partner.type}</TableCell>
                      <TableCell>{partner.address || 'N/A'}</TableCell>
                      <TableCell>
                      <Label
            variant="filled"
            color={
              (partner.status === 'ACTIVE' && 'primary') || (partner.status === 'Inactive' && 'error') || 'default'
            }
          >
            {partner.status}
          </Label></TableCell>
                      <TableCell width={150}>
                      <Link to={`/dashboard/areas/analytics/partner/${partner.id}?name=${partner.fullName}`}> 
                      Analytics
                      {/* <Iconify icon="clarity:analytics-line" width="30" height="30" /> */}
                      </Link>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500]}
            component="div"
            count={municipalAreas.length}
            rowsPerPage={size}
            page={page}
            onPageChange={(_, page) => {
              setPage(page);
              fetchData()
            }}
            onRowsPerPageChange={({target}) => {
              setSize(target.value);
              fetchData()
            }}
          />
        </Stack>
      </Container>
    )
  }
  
  export default Partners
  