import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { t } from 'i18next';
import AddOperator from './DataOperators/AddOperator';
import AddReporter from './DataReporters/AddReporter';
import OperatorList from './DataOperators/OperatorList';
import ReporterList from './DataReporters/ReporterList';
import useUser from 'hooks/useUser';

export default function AddUser() {
  const [userType, setUserType] = useState('Operator');
  const userData = useUser();

  const handleUserType = (event) => {
    setUserType(event.target.value);
  };
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
      <Card>
        <CardContent>
          <Stack direction={'row'} spacing={1}>
            <Typography variant="h5" component="div">
              {t('users.add-user')}
            </Typography>
            <TextField
              select
              value={userType}
              SelectProps={{ native: true }}
              onChange={handleUserType}
              sx={{
                '& fieldset': { border: '0 !important' },
                '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
              }}
            >
              {getUserTypes(userData).map((option) => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
            </TextField>
          </Stack>
          <Stack>
            {userType === 'Operator' && (
              <>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {t('users.add-operator-sub-title')}
                </Typography>
                <AddOperator />
              </>
            )}
            {userType === 'Reporter' && (
              <>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {t('users.add-reporter-sub-title')}
                </Typography>
                <AddReporter />
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
      {userType === 'Operator' && <OperatorList />}
      {userType === 'Reporter' && <ReporterList />}
    </Stack>
  );
}
const getUserTypes = (userData) => {
    if (userData?.authorities?.includes('OPERATOR')) {
      return USER_TYPES;
    } 
    return PARTNER_USER_TYPES;
} 
const USER_TYPES = [{ name: 'Operator' }, { name: 'Reporter' }];
const PARTNER_USER_TYPES = [{ name: 'Operator' }];
