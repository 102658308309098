import { createAsyncThunk } from '@reduxjs/toolkit';
import APIServices from 'apiUtils/APIServices';
import { REPORTER } from 'backend/url_helper';

export const getReporters = createAsyncThunk('reporter/getReporters', async (data, thunkAPI) => {
  try {
    const api = new APIServices(`${REPORTER}`);
    const { success, data: responseData } = await api.get();
    if (success) {
      return thunkAPI.fulfillWithValue(responseData?.items);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return thunkAPI.rejectWithValue('Something Went wrong.');
});

export const addReporter = createAsyncThunk('reporter/addReporters', async (data, thunkAPI) => {
  try {
    const api = new APIServices(`${REPORTER}`);
    const { success, data: responseData } = await api.post(data);
    if (success) {
      return thunkAPI.fulfillWithValue(responseData);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return thunkAPI.rejectWithValue('Something Went wrong.');
});

export const updateReporter = createAsyncThunk('reporter/addReporters', async (data, thunkAPI) => {
  try {
    const api = new APIServices(`reporters/${data?.id}/regions`);
    delete data.id;
    const { success, data: responseData } = await api.patch(0, data);
    if (success) {
      return thunkAPI.fulfillWithValue(responseData);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return thunkAPI.rejectWithValue('Something Went wrong.');
});
