import { format, formatDistanceToNow, getTime } from 'date-fns';
import NepaliDate from 'nepali-date-converter';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}


export function fShortMonthDate(date) {
  return format(new Date(date), 'dd MMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fFiscalYears(startDate, endDate) {
  const result = [];
  let j = startDate;
  for (let i = startDate + 1; i <= endDate; i += 1) {
    result.push(`0${j} / 0${i}`);
    j = i;
  }
  return result;
}

export const onNepaliDatechange = (val) => {
  const nepaliDate = new NepaliDate(val);
  const enDate = nepaliDate.getAD();
  const yearString = enDate.year;
  const monthString = `0${enDate.month + 1}`.slice(-2);
  const dateString = `0${enDate.date}`.slice(-2);
  const enDateString = `${yearString}-${monthString}-${dateString}`;
  return enDateString
}

export const convertDateRangeToFYI = (startDate, endDate) =>{
  if( startDate === undefined || endDate === undefined) return undefined ;
  const dateStart = `${new NepaliDate(new Date(startDate)).getYear()}`
  const dateEnd = `${new NepaliDate(new Date(endDate)).getYear()}`;

  const start = dateStart.slice(1);
  const end  = dateEnd.slice(1);
 return `${start} / ${end}`;
}
