import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';

import APIServices from 'apiUtils/APIServices';

import Page from 'components/Page';

import { PATH_DASHBOARD } from 'routes/paths';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import {
  FormProvider,
  RHFTextField,
  RHFRadioGroup,
  MultipleSelect,
  RHFSelect,
  RHFDateField,
} from 'components/hook-form';
import { nepaliEthnicityB } from 'dataTypes';
import { useSnackbar } from 'notistack';

import MajorPrograms, { budgetSources, programList } from './MajorPrograms';
import BurnOutDetail from './BurnOutDetail';
import WoodSaleDetail from './WoodSaleDetail';
import LandslideDetail from './LandslideDetail';
import HumanWildAnimalConflict from './HumanWildAnimalConflict';
import { developmentManagementFormSchema } from './formSchema';

import SpecieButton from './SpecieButton';
import Iconify from 'components/Iconify';
import { t } from 'i18next';
import DevelopmentAndManagementImageUpload from './DevelopmentAndManagementImageUpload/DevelopmentAndManagementImageUpload';
import { fFiscalYears, onNepaliDatechange, convertDateRangeToFYI } from 'utils/formatTime';
import NepaliDate from 'nepali-date-converter';

const DevelopmentAndManagement = ({ handleNextStep, data = {}, devAndManagementReportingId }) => {
  const devMgmtProg = data?.developmentAndManagementPrograms;
  if (devMgmtProg) {
    if (devMgmtProg.majorPrograms) {
      data.developmentAndManagementPrograms.majorPrograms = orderByProgramTypeThenBudgetSource(
        devMgmtProg.majorPrograms
      );
    }
    if (devMgmtProg.fireDamages?.humanLosses) {
      devMgmtProg.fireDamages.humanLosses = orderByEthnicity(devMgmtProg.fireDamages?.humanLosses);
    }
    if (devMgmtProg.landslideDamages?.humanLosses) {
      devMgmtProg.landslideDamages.humanLosses = orderByEthnicity(devMgmtProg.landslideDamages?.humanLosses);
    }
  }
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [reportingId, setReportingId] = useState();

const [fyStart, setFyStart] = useState();
const [fyEnd, setFyEnd] = useState();
  const nepaliDateNow = new NepaliDate(new Date())
  const currentYear = `${nepaliDateNow.getYear()}`.slice(2)
  const endFY = nepaliDateNow.getMonth() >= 3 ? currentYear : currentYear - 1
  const fiscalYears = fFiscalYears(endFY - 10, endFY);

  const fiscalYearChangeHandler = (event) => {
    const fiscalYearRange = event.target.value.split('/');
    const startDate = onNepaliDatechange(`2${fiscalYearRange[0].trim()}-04-01`);
    const endDate = onNepaliDatechange(`2${fiscalYearRange[1].trim()}-03-31`);
    
    setFyStart(startDate);
    setFyEnd(endDate);
    setValue('fiscalYear', event.target.value); 
  };


  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const report = params.get('reportingId');
    setReportingId(report);
    if (!report) {
      navigate('/dashboard/app');
    } else fetchSpecies();
  }, []);

  const api = new APIServices('dev-mgmt-reporting');
  const methods = useForm({
    resolver: yupResolver(developmentManagementFormSchema),
    defaultValues: {
      ...defaultValues,
      ...data.developmentAndManagementPrograms,
      devAndManagementReportingId: data.id,
      nonWoodCultivationDone: data.developmentAndManagementPrograms?.nonWoodCultivation?.length > 0 ? 'true' : 'false',
      nonWoodCultivation:
        data.developmentAndManagementPrograms?.nonWoodCultivation?.length > 0
          ? data.developmentAndManagementPrograms?.nonWoodCultivation
          : defaultValues.nonWoodCultivation,

      hasFireOccurred: data.developmentAndManagementPrograms?.fireBurntOut !== null ? 'true' : 'false',
      fireDamages: data.developmentAndManagementPrograms?.fireDamages
        ? {
            ...data.developmentAndManagementPrograms?.fireDamages,
            otherLosses: orderLosses(data.developmentAndManagementPrograms?.fireDamages?.otherLosses),
          }
        : defaultValues.fireDamages,

      // fireBurntOut: data.fireBurntOut? data.fireBurntOut: defaultValues.fireBurntOut
      hasHumanAnimalEncounter: data.developmentAndManagementPrograms?.animalEncounter !== null ? 'true' : 'false',
      animalEncounter: data.developmentAndManagementPrograms?.animalEncounter
        ? {
            ...data.developmentAndManagementPrograms?.animalEncounter,
            damages: orderLosses(data.developmentAndManagementPrograms?.animalEncounter?.damages),
          }
        : defaultValues.animalEncounter,

      hasLandslideOccurred: data.developmentAndManagementPrograms?.landslide !== null ? 'true' : 'false',
      landslideDamages: data.developmentAndManagementPrograms?.landslideDamages
        ? {
            ...data.developmentAndManagementPrograms?.landslideDamages,
            otherLosses: orderLosses(data.developmentAndManagementPrograms?.landslideDamages?.otherLosses),
          }
        : defaultValues.landslideDamages,
       
        fiscalYear: convertDateRangeToFYI(data.developmentAndManagementPrograms?.fiscalYearStart, data.developmentAndManagementPrograms?.fiscalYearEnd)
        
    },
    mode: 'all',
  });

  const {
    watch,
    setValue,
    setError,
    handleSubmit,
    unregister,
    formState: { isDirty },
  } = methods;
  const values = watch();

  const onSubmit = async (data) => {
    if (!isDirty) {
      // alert("Is not dirty");
    //   handleNextStep();
    //   return;
    }

    data.fiscalYearStart = fyStart || data.fiscalYearStart;
    data.fiscalYearEnd = fyEnd || data.fiscalYearEnd;
    delete data.fiscalYear;
    
    data.afforestation = data.afforestation?.filter((t) => t);
    data.nonWoodCultivation = data.nonWoodCultivation?.filter((t) => t);
    if (!data?.hasFireOccurred) {
      data.fireBurntOut = null;
      data.fireDamages = null;
    }
    delete data?.hasFireOccurred;
    if (!data?.hasLandslideOccurred) {
      data.landslideDamages = null;
      data.landslide = null;
    }
    delete data.hasLandslideOccurred;
    if (!data?.hasHumanAnimalEncounter) {
      data.animalEncounter = null;
    } else {
      data.animalEncounter.humanWildlifeConflicts = data.animalEncounter?.humanWildlifeConflicts.filter((t) => t);
    }
    delete data.hasHumanAnimalEncounter;

    if (!data?.nonWoodCultivationDone) {
      data.nonWoodCultivation = [];
    }
    delete data.nonWoodCultivationDone;

    const formData = { reportingId, id: data.devAndManagementReportingId, developmentAndManagementPrograms: data };

    delete data.devAndManagementReportingId;
    // Check if the data has id or not, if there is a id update data to api
    const { success, data: responseData } = await (formData.id ? api.put(formData.id, formData) : api.post(formData));
    if (success) {
      handleNextStep();
    } else {
      // TODO: Finalize errors and populate to form as per the error returned form API
      enqueueSnackbar(responseData?.data?.message, { variant: 'error' });

      responseData?.data?.details?.forEach(({ errorMessage, field }) => {
        setError(field.replace('developmentAndManagementPrograms.', ''), { type: 'manual', message: errorMessage });
      });
    }
    // setSubmitting(false);
  };

  const [species, setSpecies] = useState({ PLANT: [], ANIMAL: [] });
  const fetchSpecies = async (_) => {
    const { data, success } = await new APIServices('species').get();
    if (success) {
      const speciess = { PLANT: [], ANIMAL: [] };
      data?.forEach((d) => {
        speciess[d.type]?.push(d);
      });
      setSpecies(speciess);
    }
  };

  const { PLANT = [], ANIMAL = [] } = species;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (_) => setIsModalOpen(!isModalOpen);
  return (
    <Page title="Development and Management Details">
      <HeaderBreadcrumbs
        heading="Development and Management Forms"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Data Regions', href: PATH_DASHBOARD.dataRegions.root },
          { name: 'CF', href: PATH_DASHBOARD.dataRegions.root },
          { name: 'Development and Management Forms' },
        ]}
      />

      <Paper elelvation={4}>
        <Card
          sx={{
            mb: 3,
            position: 'relative',
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <Paper elevation={4}>
                
                <Typography variant={'h6'} padding={1}>
                  Previous Year time frame (Fiscal Year)
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingX={3}>
                  <RHFSelect
                    name="fiscalYear"
                    label="Fiscal Year"
                    placeholder="Fiscal year"
                    onChange={fiscalYearChangeHandler}
                  >
                    <option value="" />
                    {fiscalYears?.map((option) => (
                      <option key={option} value= {option}>                       
                        {`${option} FY`}
                      </option>
                    ))}
                  </RHFSelect>

                 {/* <RHFDateField name="fiscalYearStart" label={t('forms.gdcf.developmentManagement.fiscalYearStart')} />
                  <RHFDateField name="fiscalYearEnd" label={t('forms.gdcf.developmentManagement.fiscalYearEnd')} />  */}
                </Stack>
                <MajorPrograms values={values} />
                <br />
                <Typography variant={'h6'} padding={1}>
                  2. Plantation / Afforestation / Reforestation of Previous Fiscal Year
                </Typography>
                <TableContainer sx={{ my: 2 }}>
                  <Table>
                    <TableHead>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Species</TableCell>
                      <TableCell>Plant Count</TableCell>
                      <TableCell>Survival Rate</TableCell>
                      <TableCell>Plantation / Afforestation / Reforestation Area ( Hectare )</TableCell>
                    </TableHead>
                    <TableBody>
                      {values?.afforestation?.map((wc, i) =>
                        getAfforestationRow(i, PLANT, values, setValue, unregister, fetchSpecies)
                      )}
                      <TableRow key={`business-field-row-9999`}>
                        <TableCell sx={{ minWidth: 50 }}>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setValue(`afforestation[${values?.afforestation?.length}]`, {})}
                            sx={{ minWidth: 140 }}
                          >
                            +
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <WoodSaleDetail values={values} PLANTS={PLANT} fetchSpecies={fetchSpecies} />
                <br />

                <Typography variant={'h6'} padding={1}>
                  4. Total firewoods collected in previous fiscal year <i>(In chatta)</i>
                </Typography>
                <Stack direction={{ xs: 'column' }} spacing={2} paddingX={3} width={600}>
                  <RHFTextField
                    name="fireWoodCollection.packs"
                    label="Total firewoods collected"
                    type="number"
                    min={0}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon="mdi:campfire" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>

                <br />

                <Typography variant={'h6'} padding={1}>
                  5. Total Seula (सेउला) collected in previous fiscal year <i> (In Bhari )</i>
                </Typography>
                <Stack direction={{ xs: 'column' }} spacing={2} paddingX={3} width={600}>
                  <RHFTextField
                    name="grassCollection.packs"
                    label="Total सेउला collected"
                    type="number"
                    min={0}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Iconify icon="mdi:leaf-maple" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>

                <br />

                <Typography variant={'h6'} padding={1}>
                  6. Fire Control Line (Meter)
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingX={3}>
                  <RHFTextField
                    name="fireControlPreviousFiscalYear.fireControlLine"
                    label="Built Last Year"
                    fullWidth
                    type="number"
                    min={0}
                  />
                  <RHFTextField
                    name="fireControlTillPreviousFiscalYear.fireControlLine"
                    label="Total till this year"
                    fullWidth
                    type="number"
                    min={0}
                  />
                </Stack>

                <br />

                <Typography variant={'h6'} padding={1}>
                  7. Initial Environmental Examination/Environmental Impact Assessment
                </Typography>
                <Stack spacing={2} paddingX={3}>
                  <RHFRadioGroup
                    name="initialEnvironmentEffectStatus"
                    required
                    options={[true, false]}
                    getOptionLabel={statuses}
                  />
                </Stack>

                <br />

                <BurnOutDetail values={values} />
                <LandslideDetail values={values} />
                <HumanWildAnimalConflict
                  values={values}
                  setValue={setValue}
                  unregister={unregister}
                  ANIMALS={ANIMAL}
                  fetchSpecies={fetchSpecies}
                />

                <br />

                <Typography variant={'h6'} padding={1}>
                  13. Non Timber Forest Products [NTFP] Plantation / Farming
                </Typography>
                <Stack spacing={2} paddingX={3}>
                  <RHFRadioGroup
                    name="nonWoodCultivationDone"
                    options={[true, false]}
                    getOptionLabel={statuses}
                    type="boolean"
                    value={values.nonWoodCultivationDone === 'true'}
                  />
                  {values.nonWoodCultivationDone === 'true' ? (
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TableContainer sx={{ my: 2 }}>
                        <Table>
                          <TableHead>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>Species</TableCell>
                            <TableCell>Area (Hectare)</TableCell>
                          </TableHead>
                          <TableBody>
                            {values?.nonWoodCultivation?.map((wc, i) =>
                              getNTFPRow(i, PLANT, values, setValue, unregister, fetchSpecies)
                            )}
                            <TableRow key={`business-field-row-9999`}>
                              <TableCell sx={{ minWidth: 50 }}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={() =>
                                    setValue(`nonWoodCultivation[${values?.nonWoodCultivation?.length}]`, {
                                      measurementType: 'AREA',
                                    })
                                  }
                                  sx={{ minWidth: 140 }}
                                >
                                  +
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  ) : null}
                </Stack>
                <br />
                <Typography variant={'h6'} padding={1}>
                  14. Frequent action or practices to control and minimized incidents

                </Typography>
                <Stack spacing={2} paddingX={3}>
                  <RHFTextField name={`suddenMeasures`} label="Details" fullWidth />
                </Stack>
                
                <DevelopmentAndManagementImageUpload values={values} setValue={setValue} unregister={unregister} />
               
                <br />
              </Paper>
            </Stack>
            <br />
            <Button fullWidth size="large" type="submit" variant="contained">
              Save and Continue
            </Button>
          </FormProvider>
        </Card>
      </Paper>
    </Page>
  );
};
export default DevelopmentAndManagement;

const getAfforestationRow = (i, PLANT, values, setValue, unregister, fetchSpecies) => (
  <TableRow key={`business-field-row-${i}`}>
    <TableCell sx={{ minWidth: 150 }}>
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          if (values?.afforestation?.filter((n) => n).length === 1) {
            setValue('afforestation', []);
          } else {
            unregister(`afforestation[${i}]`);
          }
        }}
        sx={{ minWidth: 140 }}
      >
        -
      </Button>
    </TableCell>
    <TableCell sx={{ minWidth: 410, maxWidth: 500 }}>
      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <MultipleSelect
          name={`afforestation[${i}].mainSpeciesIds`}
          label="Plant Species"
          value={values.afforestation[i]?.mainSpeciesIds}
          defaultvalues={[]}
        >
          {PLANT.map(({ id: value, name: label }, idx) => (
            <MenuItem key={`program-dropdown-${idx}`} value={value}>
              {label}
            </MenuItem>
          ))}
        </MultipleSelect>
        <SpecieButton fetchSpecies={fetchSpecies} />
      </Stack>
    </TableCell>
    <TableCell sx={{ minWidth: 110 }}>
      <RHFTextField
        name={`afforestation[${i}].plantCount`}
        label="Plant count"
        type="number"
        min={0}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Iconify icon="mdi:sprout-outline" />
            </InputAdornment>
          ),
        }}
      />
    </TableCell>
    <TableCell sx={{ minWidth: 110 }}>
      <RHFTextField
        name={`afforestation[${i}].successRate`}
        label="Survived plants (%)"
        type="number"
        min={0}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography color={'text.secondary'}>% </Typography>
            </InputAdornment>
          ),
        }}
      />{' '}
    </TableCell>
    <TableCell sx={{ minWidth: 110 }}>
      <RHFTextField
        name={`afforestation[${i}].afforestationArea`}
        label="Afforested Area (in Hectare)"
        type="number"
        min={0}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography color={'text.secondary'}>Ha </Typography>
            </InputAdornment>
          ),
        }}
      />
    </TableCell>
  </TableRow>
);

export const statuses = ['Yes', 'No'];

const getNTFPRow = (i, PLANT, values, setValue, unregister, fetchSpecies) => {
  const selectedList = values
    ? values.nonWoodCultivation.filter((nW) => nW.speciesId != null).map((nW) => parseInt(nW.speciesId, 10))
    : [];
  return (
    <TableRow key={`business-field-row-${i}`}>
      <TableCell sx={{ minWidth: 150 }}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            if (values.nonWoodCultivation.filter((n) => n).length === 1) {
              setValue('nonWoodCultivation', []);
            } else {
              unregister(`nonWoodCultivation[${i}]`);
            }
          }}
          sx={{ minWidth: 140 }}
        >
          -
        </Button>
      </TableCell>
      <TableCell sx={{ minWidth: 150 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }}>
          <RHFSelect
            name={`nonWoodCultivation[${i}].speciesId`}
            label="Non Timber Plant Species"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify icon="mdi:sprout-outline" />
                </InputAdornment>
              ),
            }}
          >
            <option value="" />
            {PLANT?.filter((a) => a.subType === 'NON_WOOD').map(({ id: value, name: label }, i) => (
              <option key={value} value={value} disabled={selectedList.indexOf(value) !== -1 ? 'disabled' : ''}>
                {label}
              </option>
            ))}
          </RHFSelect>
          <SpecieButton fetchSpecies={fetchSpecies} />
        </Stack>
      </TableCell>
      <TableCell sx={{ minWidth: 150 }}>
        <RHFTextField
          name={`nonWoodCultivation[${i}].quantity`}
          label="Area (Hectare)"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography color={'text.secondary'}>HA</Typography>
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
    </TableRow>
  );
};

// TODO: Replace fiscal years as per user input
const today = moment().subtract(5, 'days').format('YYYY-MM-DD');

const defaultMajorPrograms = programList.flatMap(({ id: type }) =>
  budgetSources.map(({ type: budgetSource }) => ({
    type,
    budgetSource,
    amount: 0,
  }))
);
const defaultHumanLoses = nepaliEthnicityB.flatMap(({ id }) => [
  { ethnicity: id, othersCount: 0, maleCount: 0, femaleCount: 0 },
]);

const defaultValues = {
  majorPrograms: defaultMajorPrograms,
  woodSales: {},
  afforestation: [],
  fireWoodCollection: { type: 'FIRE_WOOD' },
  grassCollection: { type: 'GRASS' },
  fireControlPreviousFiscalYear: {},
  fireControlTillPreviousFiscalYear: {},
  animalEncounter: {
    othersCasualty: 0,
    damages: [{ type: 'HOUSEHOLD' }, { type: 'PETS' }, { type: 'AGRICULTURAL_GOODS' }, { type: 'AGRICULTURAL_LAND' }],
    humanWildlifeConflicts: [],
  },
  fireDamages: {
    humanLosses: defaultHumanLoses,
    otherLosses: [
      { type: 'HOUSEHOLD' },
      { type: 'PETS' },
      { type: 'AGRICULTURAL_GOODS' },
      { type: 'AGRICULTURAL_LAND' },
    ],
  },
  landslideDamages: {
    type: '',
    humanLosses: defaultHumanLoses,
    otherLosses: [
      { type: 'HOUSEHOLD' },
      { type: 'PETS' },
      { type: 'AGRICULTURAL_GOODS' },
      { type: 'AGRICULTURAL_LAND' },
    ],
  },
  nonWoodCultivation: [
    {
      measurementType: 'AREA',
    },
  ],
  suddenMeasures: '',
  images: [],
};
// [ PETS, HOUSEHOLD, AGRICULTURAL_LAND, AGRICULTURAL_GOODS ]

const orderByProgramTypeThenBudgetSource = (data) => {
  const map = getProgramTypeAndBudgeSourceMap(data);
  data = getOrdered(map, programList, budgetSources);

  return data;
};
const orderLosses = (data) => {
  const map = data.reduce((map, obj) => {
    map[obj.type] = obj;
    return map;
  }, {});
  return [map.HOUSEHOLD, map.PETS, map.AGRICULTURAL_GOODS, map.AGRICULTURAL_LAND];
};

const getProgramTypeAndBudgeSourceMap = (majorProgramsList) =>
  majorProgramsList.reduce((map, obj) => {
    map[`${obj.type}*${obj.budgetSource}`] = obj;
    return map;
  }, {});
const getOrdered = (ethnicMap, programList, budgetSources) => {
  const orderedList = [];
  programList.forEach((program) => {
    budgetSources.forEach((budgetSource) => {
      if (ethnicMap[`${program.id}*${budgetSource.type}`] != null) {
        orderedList.push(ethnicMap[`${program.id}*${budgetSource.type}`]);
      } else {
        orderedList.push({
          type: program.id,
          budgetSource: budgetSource.type,
          amount: 0,
        });
      }
    });
  });
  return orderedList;
};

const orderByEthnicity = (data) => {
  let ethnicMap;
  if (data) {
    ethnicMap = getEthnicMap(data);
    return getOrderedByEthnicity(ethnicMap, nepaliEthnicityB);
  }
  return data;
};

const getEthnicMap = (ethnicDataList) =>
  ethnicDataList.reduce((map, obj) => {
    map[obj.ethnicity] = obj;
    return map;
  }, {});

const getOrderedByEthnicity = (ethnicMap, ethnicityOrderList) => {
  const orderedList = [];
  ethnicityOrderList.forEach((ethnicity) => {
    if (ethnicMap[`${ethnicity.id}`] != null) {
      orderedList.push(ethnicMap[`${ethnicity.id}`]);
    }
  });
  return orderedList;
};
