// ===========================|| DASHBOARD - TOTAL GROWTH BAR CHART ||=========================== //

// const TimberLineChartData = {
//     type: 'column',
//     height: 200,
//     options: {
//       chart: {
//         sparkline: {
//           enabled: true
//         }
//       },
//       dataLabels: {
//         enabled: false
//       },
//       colors: ['#f3dfb8', '#33A961'],
//       fill: {
//         type: 'solid',
//         opacity: 1
//       },
//       stroke: {
//         curve: 'smooth',
//         width: 2
//       },
//       xaxis: {
//         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
//         title: {
//           text: 'Month'
//         }
//       },
//       yaxis: {
//         min: 0,
//         max: 100
//       },
//       tooltip: {
//         theme: 'dark',
//         fixed: {
//           enabled: false
//         },
//         x: {
//           show: false
//         },
//         y: {
//           title: 'Total Order'
//         },
//         marker: {
//           show: false
//         }
//       },
//       grid: {
//         borderColor: '#e7e7e7',
//         row: {
//           colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
//           opacity: 0.5
//         },
//       },
//       legend: {
//         position: 'top',
//         horizontalAlign: 'right',
//         floating: true,
//         offsetY: -25,
//         offsetX: -5
//       }
//     },
//     series:  [{
//       data: [21, 22, 10, 28, 16, 21, 13, 30]
//     }]
//   };
  // export default TimberLineChartData;

  const timberLineChartData = {
    height: 300,
    type: 'bar',
    options: {
      chart: {
        id: 'bar-chart',
        toolbar: {
          show: true
        },
        zoom: {
          enabled: false
        }
      },
      responsive: [
        {
          // breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%'
        }
      },
      xaxis: {
        type: 'category',
        categories: ['Timber', 'Sallo']
      },
      yaxis: {
        title: {
          text: 'ft3'
        }
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: `'Roboto', sans-serif`,
        position: 'bottom',
        offsetX: 20,
        labels: {
          useSeriesColors: false
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8
        }
      },
      fill: {
        type: 'solid'
      },
      dataLabels: {
        enabled: true
      }
    }
  };

  export default timberLineChartData