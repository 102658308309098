import { Divider } from '@mui/material';
import * as React from 'react';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import useAuth from 'hooks/useAuth';
import ReportsList from '../dataRegions/region/ReportsList';
import AssignedRegions from '../tasks/AssignedRegions';

export default function Reports() {
  const { user } = useAuth();

  return (
    <Page title="Reports">
      <HeaderBreadcrumbs
        heading="Reports."
        links={[
          { name: 'Reports', href: PATH_DASHBOARD.root },
          { name: 'New Reports', href: PATH_DASHBOARD.dataRegions.root },
        ]}
      />
      {user.authorities[0] === 'REPORTER' && <AssignedRegions regions={user?.regions} />}
      <Divider sx={{ my: 2 }} />
      <ReportsList />
    </Page>
  );
}
