import Modal from '@mui/material/Modal';
import React, { useCallback, useState } from 'react';
import { Box, Button, IconButton, ImageList, ImageListItem, ImageListItemBar, Stack, Typography, styled } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { AddCircleOutline, BackupOutlined, Close, Delete } from '@mui/icons-material';
import APIServices from 'apiUtils/APIServices';
import { FormProvider, RHFImageUpload, RHFTextField, RHFUploadSingleFile } from 'components/hook-form';
import { FILE_SIZE } from 'store/constant';
import { fData } from 'utils/formatNumber';

const StyledModalContainer = styled(Box)(({ theme }) => ({
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: '430px',
    borderRadius: ' 12px',
    backgroundColor: '#FFF',
    boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
    '& .css-1z0rh7t-MuiStack-root': {
        textAlign: 'center',
        borderRadius: '8px',
        border: '2px dashed #9CA3AF',
        backgroundColor: '#F3F4F6',
        height: '240px',
    }
}));

const DevelopmentAndManagementModal = ({ onComplete, data: editData, setValue: parentSetValue, values: parentValues }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [image, setImage] = useState();
    const [src, setSrc] = useState()

    const { enqueueSnackbar } = useSnackbar();

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const reportingId = params.get('reportingId')
    // const api = new APIServices(`participatory-reporting`);

    const methods = useForm({
        resolver: yupResolver(
            yup.object().shape({
                image: yup.mixed().required().label('Photo'),
                caption: yup.string().required().label('Caption'),
            })
        ),
        defaultValues: editData,
        mode: 'all',
    });
    const { handleSubmit, setValue, getValues, setError, reset, watch } = methods;

    const toggleModal = () => setModalOpen(!isModalOpen);
    const showModal = () => {
        deleteImage()
        reset()
        setModalOpen(true)
    }
    const onSubmit = async () => {
        const formData = getValues();
        parentSetValue('images', [...parentValues?.images, {
            id: null,
            attachment: image,
            caption: formData.caption
        }])
        toggleModal();
    }

    const deleteImage = () => {
        setValue('image', undefined);
        setSrc(undefined)
        setImage(undefined)
        setSrc(null)
    }

    const onImageDrop = useCallback(
        async (acceptedFiles) => {
            const api = new APIServices('attachments');
            const data = new FormData();
            data.append('file', acceptedFiles[0]);
            data.append('qualifier', 'cfMapData');
            const { success, data: responseData } = await api.post(data);
            if (success) {
                const { id } = responseData;
                setValue('image', [acceptedFiles[0]]);
                setSrc(URL.createObjectURL(acceptedFiles[0]))
                setImage(id)
            } else {
                enqueueSnackbar('Could not upload file!', { variant: 'error' });
            }
        }
    )

    return (
        <>
            {isModalOpen &&
                <Modal
                    open={isModalOpen}
                    onClose={toggleModal}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                >
                    <>
                        <StyledModalContainer>
                            <FormProvider style methods={methods}>
                                <Box position={'relative'}>
                                    <Box component={'span'} bgcolor={'primary.50'} position={'absolute'} padding={0.6}
                                        sx={{ borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: -25, right: 10, cursor: 'pointer' }}
                                        onClick={toggleModal}
                                    >
                                        <Close sx={{ width: '18px', height: '18px' }} />
                                    </Box>
                                </Box>
                                <Stack spacing={2} margin={'40px'}>
                                    {

                                        image ?
                                            <ImageList sx={{ width: '100%' }} cols={1}>
                                                <ImageListItem key={image}>
                                                    <img
                                                        style={styles.preview}
                                                        src={src}
                                                        srcSet={src}
                                                        alt="img"
                                                        loading="lazy"
                                                    />
                                                </ImageListItem>
                                            </ImageList> : 
                                            <RHFImageUpload name="image" label={t('Photo')} accept="image/*" maxSize={FILE_SIZE} onDrop={onImageDrop} value={[]}    helperText={
                                                <Typography
                                                  variant="caption"
                                                  sx={{
                                                    mt: 2,
                                                    mx: 'auto',
                                                    display: 'block',
                                                    textAlign: 'center',
                                                    color: 'orange',
                                                  }}
                                                >
                                                  Allowed *.jpeg, *.jpg, *.png, *.gif
                                                  <br /> max size of {fData(FILE_SIZE)}
                                                </Typography>
                                              }>
                                                <Stack alignItems={'center'} justifyContent={'center'}>
                                                    <BackupOutlined sx={{ color: 'green', width: '30px', height: '30px' }} />
                                                    <span style={{ padding: '0 40px' }}>
                                                        <Typography fontSize={'14px'}><span style={{ color: 'green' }}>Browse</span> & Chose the files you want to upload from your computer</Typography>
                                                    </span>
                                                </Stack>
                                            </RHFImageUpload>
                                    }
                                    <RHFTextField name="caption" label={t('Caption')} />
                                    <Box component={'span'} textAlign={'center'} >
                                        <Button type="button" variant="contained" onClick={handleSubmit(onSubmit)}>
                                            {editData?.id ? 'Update' : 'Save and Continue'}
                                        </Button>
                                    </Box>
                                </Stack>
                            </FormProvider>
                        </StyledModalContainer>
                    </>
                </Modal>
            }
            <ImageListItem sx={{ width: "150px" }} onClick={() => showModal()}>
                <div style={styles.imageItem}
                >
                    <Box component={'span'} style={{ textAlign: 'center' }}>
                        <AddCircleOutline sx={{ width: '35px', height: '35px', color: 'green' }} />
                        <br />
                        <Typography component={'small'} fontWeight={'600'} fontSize={'14px'} sx={{ color: '#374151' }}>Add Image</Typography>
                    </Box>
                </div>
            </ImageListItem>
        </>
    );
};

DevelopmentAndManagementModal.prototypes = {
    onComplete: Function,
    data: Object
}

export default DevelopmentAndManagementModal;

const styles = {
    imageItem: {
        width: '208px',
        height: '208px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px dashed #9CA3AF',
        cursor: 'pointer',
        background: '#F3F4F6',
        borderRadius: '8px'
    },
    preview: {
        width: "100%",
        height: 200
    }
}