import { Grid } from '@mui/material';
import React from 'react';
import StockBarChart from 'pages/explore/Components/StockBarChart';
import PieChart from './PieChart';

function GrowingStocks({
  isLoading,
  growingStockSpecies,
  populationByGender,
  populationByEthnicity,
  growingStocksColumnChartdata,
  populationPieChartData,
  ethnicityPieChartData,
}) {

  return (
    <Grid container spacing={2} marginTop={'10px'}>
      <Grid item xs={6} md={4}>
        <StockBarChart
          title={`Growing Stock`}
          isLoading={isLoading}
          series={growingStockSpecies}
          data={growingStocksColumnChartdata}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <PieChart
          title={`Population`}
          series={populationByGender}
          data={populationPieChartData}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <PieChart
          title={`Ethinicity`}
          series={populationByEthnicity}
          data={ethnicityPieChartData}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
}

export default GrowingStocks;
