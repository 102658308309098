// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// components
import Page from 'components/Page';
// sections
import BusinessPartnership from 'sections/getinvolved/business-partnership';
import Volunteer from 'sections/getinvolved/volunteer';
import Heading from 'sections/getinvolved/heading';

export default function GetInvolved() {
  const theme = useTheme();

  return (
    <>
      <Page sx={{ px: 0 }} title="Get Involved in Our Program">
        <Heading />

        <Box
          paddingX={{ xs: '30px', sm: '10px', lg: '112px' }}
          sx={{ pt: '48px', pb: '20px' }}
          bgcolor={theme.palette.primary[0]}
        >
          <BusinessPartnership />

          <Volunteer />
        </Box>
      </Page>
    </>
  );
}

