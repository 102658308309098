import { Box, Stack, Typography } from '@mui/material';
import { t } from 'i18next';

export default function LoginInfo() {
  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography textAlign={'center'} variant="h4" gutterBottom>
          {t('auth.login-title')}
        </Typography>
        <Typography textAlign={'center'} sx={{ color: 'text.secondary' }}>{t('auth.login-sub-title')}</Typography>
      </Box>
    </Stack>
  );
}