import { Dialog } from '@mui/material';
import Gis from '../pages/gis';
import { useEffect, useState } from 'react';
import APIServices from '../apiUtils/APIServices';

const MapDialog = ({ dialogOpen, toggleDialog, reportingId, attachmentId}) => {
  const [mapVisualization, setMapVisalization] = useState()

  const fetchForestData = async (id) => {
    const { data, success } = await new APIServices(`reporting/${id}/map`).get();
    if (success) {
      setMapVisalization(data.data)
    }
  }

  const fetchAttachmentData = async (id) => {
    const { data, success } = await new APIServices('partner/report-count').get();
    if (success) {
      await setMapVisalization(data.data)
    }
  }

  useEffect(() => {
    if (!reportingId) {
      return
    }
    fetchForestData(reportingId)
  }, [reportingId])

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setMapVisalization(undefined)
        toggleDialog()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={'lg'}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '100%', height: '70vh', position: 'relative' }}>
          {mapVisualization && <Gis mapVisualization={mapVisualization} />}
        </div>
      </div>
    </Dialog>
  );
};

export default MapDialog;
