import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchApi } from './fetchApi';
import { GET_REGIONS_LIST } from '../../backend/url_helper';
import { partners } from 'dataTypes';
import { get } from 'apiUtils/api';

export const getRegions = createAsyncThunk('regions/getRegions', async (payload, thunkAPI) => {
  try {
    const params = new URLSearchParams({
      consortiumPartner: payload?.partner,
    });
    // const furl = new URL(GET_REGIONS_LIST);
    // if (params) {
    //   Object.keys(query).forEach((key) => furl.searchParams.append(key, query[key]));
    // }
    const { data } = await get({ url: GET_REGIONS_LIST });
    //  fetchApi(GET_REGIONS_LIST, 'GET', null, params);
    const regions = data?.items;
    return thunkAPI.fulfillWithValue(regions);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
