import { Card, Divider, Stack } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import OverviewStat from './OverviewStat';

RegionOverview.propTypes = {
  region: PropTypes.object.isRequired,
};


export default function RegionOverview({ region }) {
  const regionOverview = [
    {
      title: 'Community Forest Reported.',
      icon: 'healthicons:community-meeting',
      stat: '0',
      totalStat: region?.noOfCF,
      measurement: false,
    },
    {
      title: 'Data Reporters Assigned',
      icon: 'eva:people-fill',
      stat: '0',
    },
    {
      title: 'Community Forest Area.',
      icon: 'gis:measure-area-alt',
      stat: region?.totalCFArea,
      totalStat: 'Ha',
      measurement: true,
    },
    {
      title: 'Community Forest User Groups.',
      icon: 'eva:people-fill',
      stat: region.cfugpopulation,
    },
    {
      title: 'Community Forest User Groups Population.',
      icon: 'eva:person-fill',
      stat: region.totalCFUGPopulation,
    },
  ];
  return (
    <Card sx={{ py: 3 }}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        {regionOverview?.map((el, i) => (
          <OverviewStat key={i} {...el} />
        ))}
      </Stack>
      <Divider sx={{ my: 2 }} />
    </Card>
  );
}
