import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { useRouter } from 'routes/hooks';

import useResponsive from 'hooks/useResponsive';

import { fDate, fShortMonthDate } from 'utils/formatTime';

import Label from 'components/Label';
import Image from 'components/Image';
import Iconify from 'components/Iconify';
import TextMaxLine from 'components/TextMaxLine';
import CustomPopover, { usePopover } from 'components/custom-popover';
import BlogButton from 'pages/protected/management/BlogButton';
import { Button } from '@mui/material';

import { stringAvatar } from 'utils/string.util';
import { getImgSrc } from 'utils/getImgSrc';
import APIServices from 'apiUtils/APIServices';
import React, {  useState } from 'react'
// ----------------------------------------------------------------------

export default function PostItemHorizontal({ post }) {
  const popover = usePopover();

  const deletePost = (id) => {
    alert(id);
  }


  const smUp = useResponsive('up', 'sm');

  const { title, authorName, pinned, status:publish, coverImage:coverUrl, createdOn:createdAt, excerpt:description, id } = post;

  return (
    <>
      <Stack component={Card} direction="row">
        <Stack
          sx={{
            p: (theme) => theme.spacing(3, 3, 2, 3),
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Label variant="soft" color={(publish === 'published' && 'info') || 'default'}>
              {publish}
            </Label>

            <Box component="span" sx={{ typography: 'caption', color: 'text.disabled' }}>
              {fShortMonthDate(createdAt)}
            </Box>
          </Stack>

          <Stack spacing={1} flexGrow={1}>
          
            <TextMaxLine variant="subtitle2" line={2}>
              {title}
            </TextMaxLine>

            <TextMaxLine variant="body2" sx={{ color: 'text.secondary' }} dangerouslySetInnerHTML={{ __html: description}} />
              
            
          </Stack>

          <Stack direction="row" alignItems="center">
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>

          </Stack>
        </Stack>

        {smUp && (
          <Box
            sx={{
              width: 180,
              height: 240,
              position: 'relative',
              flexShrink: 0,
              p: 1,
            }}
          >
            <Avatar
              alt={authorName}
              sx={{ position: 'absolute', zIndex: 9, }}
              {...stringAvatar(authorName)} 
            />
            <Image alt={title} src={getImgSrc(coverUrl)} sx={{ height: 1, borderRadius: 1.5,}} />
          </Box>
        )}
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="bottom-center" sx={{ width: 140 }}>

        <MenuItem
        >
          <BlogButton
            data={{id, title}}
            LabelComponent={(onClick) => (
              <Button variant="text" color="info" onClick={() => {onClick(1,id)}}>
                <Iconify icon="solar:pen-bold" />
                Edit
              </Button>
            )}
            
          />
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            deletePost(id)
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  );
}

PostItemHorizontal.propTypes = {
  post: PropTypes.shape({
    author: PropTypes.string,
    coverUrl: PropTypes.string,
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    description: PropTypes.string,
    publish: PropTypes.string,
    title: PropTypes.string,
    totalComments: PropTypes.number,
    totalShares: PropTypes.number,
    totalViews: PropTypes.number,
  }),
};
