import { DashContainer } from './index';
import { useState } from 'react';
import {
  Box, Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const TabbedStackGraph = ({title, data, ...props}) => {
  const [selectedTab, setSetSelectedTab] = useState(0)
  const handleChange = (event, newValue) => {
    setSetSelectedTab(newValue);
  };
  return (
    <DashContainer title={title} {...props}>
        <Box sx={{ minWidth: '100%',borderBottom: 1, borderColor: 'divider' }}>
          <Tabs style={{minWidth: '100%'}} value={selectedTab} onChange={handleChange}>
            {data.map((graph, index) => <Tab key={index} label={graph.title}/> )}
          </Tabs>
        </Box>
        {data.map((graph, index) => {
          return (
            <TabPanel value={selectedTab} tabIndex={index} index={index}>
              <Box style={{minWidth: 300, height: '100%'}}>
                <Grid container columnSpacing={2}>
                  { Object.keys(graph.data).map((key, index) => (
                    <>
                      <Grid item xs={2}>
                        <Typography textAlign={'end'} variant={'body2'}>{key}</Typography>
                      </Grid>
                      <Grid display={'flex'} item xs={10} alignItems={'center'}>
                        <LinearProgress color={'warning'}  style={{flex: 1, height: 20}} variant={'determinate'} title={`${key} - (${Math.round(graph.data[key] * 100)})`} value={graph.data[key] * 100}/>
                      </Grid>
                    </>
                    ))
                  }
                </Grid>
              </Box>
            </TabPanel>
          )
        })}
    </DashContainer>
  )
}

export default TabbedStackGraph;
