import React from 'react';
import { t } from 'i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { RHFTextField } from 'components/hook-form';

const MajorPrograms = ({majorPrograms}) => (
  <>
    <Typography variant={'h6'} padding={1}>
      1. Major programs related to Forest development and Management
    </Typography>
    <TableContainer
      sx={{
        my: 1,
      }}
    >
      <Table>
        <TableHead>
          <TableCell>Programs</TableCell>
          <TableCell
            colSpan={6}
            style={{
              textAlign: 'center',
            }}
          >
            Budget Source
          </TableCell>
        </TableHead>
        <TableBody>
          {programList.map((wc, i) => (
            <TableRow key={i}>
              <TableCell
                sx={{
                  minWidth: 250,
                }}
              >
                {wc.name}
              </TableCell>
              {budgetSources.map(({ label }, idx) => (
                <TableCell colSpan={1} key={`fields-${label}-${idx}`}>
                  <RHFTextField label={t(label)} name={`majorPrograms.${i * 6 + idx}.amount`} type="number" min={0} tooltip  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
export default MajorPrograms;

export const programList = [
  {
    id: 'NURSERY_PLANTS_PRODUCTION',
    name: 'Nursery Establishment',
  },
  {
    id: 'PROTECTION_PROGRAM',
    name: 'Forest management activities (Thinning, Pruning, Weeding, Habitat Management, Fencing, etc.)',
  },
  {
    id: 'AFFORESTATION',
    name: 'Plantation / Afforestation / Reforestation',
  },
  {
    id: 'FIRE_LINE',
    name: 'Fire Line Construction / Promotion',
  },
  {
    id: 'FOREST_ENCROACHMENT_CONTROL',
    name: 'Forest Encroachment Control',
  },
  {
    id: 'WATER_SOURCE_PRESERVATION',
    name: 'Water Source Preservation',
  },
  {
    id: 'PRESERVED_WATER_SOURCE_CONSTRUCTION',
    name: 'Preserved Water source construction and improvement',
  },
  {
    id: 'OTHER',
    name: 'Other',
  },
];

export const budgetSources = [
  { type: 'DIVISION_FOREST_OFFICE', label: 'Division Forest Office' },
  { type: 'CENTRAL_GOVERNMENT', label: 'Central Government' },
  { type: 'PROVINCIAL_GOVERNMENT', label: ' Provincial Government' },
  { type: 'LOCAL_GOVERNMENT', label: 'Local Government' },
  { type: 'PREVIOUS_YEAR', label: 'Previous Year' },
  { type: 'OTHER', label: 'Other' },
];
