import { styled } from '@mui/material/styles';
import Page from 'components/Page';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import APIServices from '../../apiUtils/APIServices';
import { MotionContainer } from '../../components/animate';
import TableView from './components/TableView';
import TextualAnalyticView from './components/TextualAnalyticView';
import { useNavigate, useParams } from 'react-router-dom';
import { DashHeader } from '../Reports/Components';
import { PATH_DASHBOARD } from '../../routes/paths';
import { TabContext, TabPanel } from '@mui/lab';
import DocxUtil from '../../utils/docxUtil';

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11),
  },
}));

const Analytics = ({title}) => {
  const [report, setReport] = useState({})
  const [tab, setTab] = useState(0)
  const navigate = useNavigate()
  const params = useParams();

  const fetchAnalyticsData = async () => {
    console.log("Fetch reporting data")
    const { data, success } = await new APIServices(`visualization/textual/cf/${params.forestId || 154226}`).get();
    if (success) {
      setReport(data);
      console.log('report', report)
    }
  }

  useEffect(() => {
    fetchAnalyticsData()
  },[title])

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const generateWord = () => {
    new DocxUtil(report).generateDocx()
  }
  return (
        <>
          <DashHeader {...report} />
          <Box display={'flex'} flexDirection={'row-reverse'}>
            <Button onClick={() => navigate(`/cf/dashboard/${report.id}`)}>Graphical Info</Button>
            <Button onClick={generateWord}>Export</Button>
          </Box>
          <Box>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant={'scrollable'}
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs reporting"
            >
              {
                report && report.data ? report.data.map((tabItem, index) => (
                  <Tab key={index} label={tabItem.title} value={index}/>
                )) : []
              }
            </Tabs>
            <TabContext value={tab}>
              {
                report && report.data ? report.data.map((tabItem, index) => (
                  <TabPanel key={index}  label={tabItem.title} value={index}>
                    <Stack padding={5} direction="column" spacing={5}>
                      {
                        tabItem.data.map((item, index) => <TextualAnalyticView key={index} item={item} />)
                      }
                    </Stack>
                  </TabPanel>
                )) : []
              }
            </TabContext>
          </Box>
        </>
  )
}

export default Analytics;
