import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import Chart from 'react-apexcharts';

// project imports
import ChartSkeleton from 'ui-component/cards/Skeleton/ChartSkeleton';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';



// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const StockBarChart = ({ isLoading, data, series, title, name='Height(ft3)' }) => {
  const theme = useTheme();

  let chartSeries = [];
  if(!isLoading) {
    data.options.xaxis.categories = Object.keys(series?.data??[])
    chartSeries = [
      {
        name,
        data: Object.values(series?.data??[])
      }
    ]
  }

  return (
    <>
      {isLoading ? (
        <ChartSkeleton />
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="h5" style={{fontWeight: '500'}}>{title}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
               <Chart options={data.options} series={chartSeries} type={data.type} width={data.width} height={data.height} />

            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

StockBarChart.propTypes = {
  isLoading: PropTypes.bool
};

export default StockBarChart;
