import { useTheme } from '@emotion/react';
import { useRef } from 'react';
import { Box, CardHeader, Paper } from '@mui/material';
import Slider from 'react-slick';
import * as React from 'react';
import PropTypes from 'prop-types';
import { CarouselArrows } from 'components/carousel';
import AssignedRegionCard from './AssignedRegionCard';

AssignedRegions.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default function AssignedRegions({ regions }) {
  const theme = useTheme();
  const carouselRef = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: regions?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };
  return (
    <Paper elevation={4} sx={{ padding: 2 }}>
      <Box sx={{ padding : 1 }}>
        <CardHeader
          title="Assigned Regions"
          subheader="Here are regions assigned to you."
          action={
            <CarouselArrows
              customIcon={'ic:round-keyboard-arrow-right'}
              onNext={handleNext}
              onPrevious={handlePrevious}
              sx={{ '& .arrow': { width: 28, height: 28, p: 0 } }}
            />
          }
          sx={{
            p: 0,
            mb: 3,
            '& .MuiCardHeader-action': { alignSelf: 'center' },
          }}
        />
        <Slider ref={carouselRef} {...settings}>
          {regions.map((el, i) => (
            <AssignedRegionCard key={i} region={el} />
          ))}
        </Slider>
      </Box>
    </Paper>
  );
}
