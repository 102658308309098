import React, { useEffect, useRef, useState } from 'react';
import MapContext from './MapContext';
import * as ol from 'ol';
import '../styles.css';
import 'ol/ol.css';
import './assets/map.css';
import { transformExtent } from 'ol/proj';
import { getCenter } from 'ol/extent';
import Overlay from 'ol/Overlay';
import { defaults as defaultControls, ScaleLine, Zoom } from 'ol/control';
import ForestPopup from '../../../pages/MapExplore/ForestPopup';

const Map = ({
               children,
               zoom,
               center,
               bbox,
               padding = [50, 50, 50, 50],
               onMapClick,
               onMapSingleClick,
               getHoveredFeature,
               onMapChange,
               onHover,
             }) => {
  const mapRef = useRef();
  const popupRef = useRef(null);
  const [map, setMap] = useState(null);
  const [hoverDetails, setHoverDetails] = useState({});


  const initializeMap = async () => {

  };

  useEffect(() => {
    const options = {
      view: new ol.View({
        zoom,
        center,
        minZoom: 6,
        maxZoom: 18,
        enableRotation: false,
        animation: {
          duration: 1000,
          // Adjust the animation duration as needed
        },
      }),
      layers: [],
      controls: defaultControls().extend(
        [
          new Zoom({

          }),
          new ScaleLine({
              units: 'metric',
            },
          )]),
      overlays: [],
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
    };

    const overlay = new Overlay({
      element: popupRef.current,
      positioning: 'bottom-center',
      stopEvent: false,
    });


    const mapObject = map ?? new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    mapObject.addOverlay(overlay);

    mapObject.on('click', onMapClick);
    mapObject.on('singleclick', onMapSingleClick);
    mapObject.on('pointermove', (evt) => {
      if (!getHoveredFeature) return;
      const feature = getHoveredFeature(evt);
      if (!feature) {
        overlay.setPosition(undefined);
        setHoverDetails({});
        return;
      }
      const name = feature.get('name');
      setHoverDetails({
        id: feature.get('id'),
        name: name.toLowerCase().endsWith('cf') ? name : `${name} CF`,
        cfugCode: feature.get('cfug_code'),
        area: feature.get('area'),
        province: `${feature.get('province')}`,
        municipal: `${feature.get('palika')}`,
        status: `${feature.get('status')}`,
      });
      overlay.setPosition(evt.coordinate);
    });
    if (zoom) {
      mapObject.getView().setZoom(zoom);
    }
    if (center) {
      mapObject.getView().setCenter(center);
    }
    if (bbox) {
      let extent = [bbox.minLat, bbox.minLng, bbox.maxLat, bbox.maxLng];
      extent = transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
      mapObject.updateSize();
      mapObject.getView().fit(extent, { size: mapObject.getSize(), padding });
      mapObject.getView().setCenter(getCenter(extent));
    }
    setMap(mapObject);
    if (onMapChange) {
      onMapChange(mapObject);
    }
    return () => mapObject.setTarget(undefined);
  }, [children, zoom, center, bbox]);

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className='ol-map'>
        {children}
      </div>
      {hoverDetails && (
        <div ref={popupRef}>
          <ForestPopup details={hoverDetails} />
        </div>
      )}
    </MapContext.Provider>
  );
};

export default Map;
