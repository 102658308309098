import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  MenuItem,
  IconButton,
  Badge, Menu,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuPopover from 'components/MenuPopover';
import Iconify from 'components/Iconify';

import { useEffect, useState } from 'react';
import Scrollbar from 'components/Scrollbar';
import Label from 'components/Label';
import { dispatch, useSelector } from 'redux/store';
import { getUserList } from 'redux/actions/users';
import useAuth from 'hooks/useAuth';
import APIServices from 'apiUtils/APIServices';
import { useSnackbar } from 'notistack';
import { isPartner } from 'utils/cfUtils'
import AssignmentIcon from '@mui/icons-material/Assignment';
import { MoreVert } from '@mui/icons-material';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: ''},
  { id: 'action', label: 'Action', alignRight: true },
];
const ITEM_HEIGHT = 48;
export default function ReporterList({ onEdit }) {
  const authUser = useAuth();
  const reporters = useSelector((state) => {
    console.log("state");
    console.log(state.users);
    return state.users
  });


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getUserList());
  }, []);
  const [open, setOpen] = useState(false);
  const [activeReporter, setActiveReporter] = useState();
  const [action, setAction] = useState('');
  // const [openAction, setOpenAction] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const openAction = Boolean(anchorEl)
  const openActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const closeActionMenu = () => {
    setAnchorEl(null)
  }

  const confirmAction = async () => {
    if (action === 'changeStatus') {
      let api;
      if (activeReporter.status === 'UNVERIFIED_DE_ACTIVATED') {
        api = await new APIServices(`admin/user/${activeReporter.id}/status/unverify`);
      } else {
        api = await new APIServices(`admin/user/${activeReporter.id}/status/${activeReporter.status === 'DE_ACTIVATED' ? 'active' : 'deactive'}`);
      }
      const { success } = await api.post({});
      if (success) {
        enqueueSnackbar(`User ${activeReporter.status === 'ACTIVE' ? 'deactivated' : 'activated'} successfull!`, { variant: 'success' });
      } else {
        enqueueSnackbar('Could not change status of user!', { variant: 'error' });
      }
      setOpen(false);
      dispatch(getUserList());
    } else if (action === 'reverify') {
      let api;
      if (activeReporter.status === 'UNVERIFIED') {
        api = await new APIServices(`admin/user/${activeReporter.id}/resend-activation-link`);
      }
      const { success } = await api.post({});
      if (success) {
        enqueueSnackbar(`On boarding email sent successfully.`, { variant: 'success' });
      } else {
        enqueueSnackbar('Could not sent on boarding email!', { variant: 'error' });
      }
      setOpen(false);
    }
  };
  const onAction = (action, user) => {
    setAction(action)
    setActiveReporter(user);
    if (['changeStatus', 'reverify'].includes(action)) {
      toggleDialog();
    } else if (action === 'edit') {
      onEdit(user)
    }
  }
  const onStatusChange = (reporter) => {
    setAction('toggleStatus')
    setActiveReporter(reporter);
    toggleDialog();
  };
  const onVerificationMail = (reporter) => {
    setActiveReporter(reporter);
    setAction('reverify')
    toggleDialog();
  };

  const toggleDialog = (_) => {
    setOpen(!open);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader title={'User List'} />
      <CardContent>
        <Scrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.N</TableCell>
                {TABLE_HEAD.map(({ id, label, alignRight }) => (
                  <TableCell key={id} align={alignRight ? 'right' : 'left'}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reporters &&
                reporters.users.items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, i) => (
                  <TableRow key={user.id}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{`${user?.type}`}</TableCell>
                    <TableCell>
                      {' '}
                      <Label color={'info'}>{user.status}</Label>
                    </TableCell>
                    {authUser?.user?.userId === user?.operatorId || isPartner(authUser) ? (
                      <TableCell>
                        <Badge badgeContent={user.totalReports || 0} color="primary" showZero>
                          <AssignmentIcon color='disabled' />
                        </Badge>

                        <OperatorsListPopOver onAction={onAction} user={user} />

                      </TableCell>

                    ) : (
                      <TableCell/>
                    )}
                    <TableCell>
                      <div>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={openActionMenu}
                        >
                          <MoreVert/>
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={openAction}
                          onClose={closeActionMenu}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: '20ch',
                            },
                          }}
                        >
                            <MenuItem key={0} onClick={closeActionMenu}>
                              Update Password
                            </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TablePagination
                id="tablePagination"
                count={reporters?.items?.length || 1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
              />
            </TableFooter>
          </Table>
          <Dialog
            open={open}
            onClose={toggleDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Are you sure to perform the action?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to change user status?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog}>Cancel</Button>
              <Button onClick={confirmAction} autoFocus color="error" variant="outlined">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Scrollbar>
      </CardContent>
    </Card>
  );
}

function OperatorsListPopOver({ user, getReporters, onAction }) {
  const [open, setOpen] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const confirmDisable = async () => {
    let api;
    if (user.status === 'UNVERIFIED_DE_ACTIVATED') {
      api = await new APIServices(`admin/user/${user.id}/status/unverify`);
    } else {
      api = await new APIServices(`admin/user/${user.id}/status/${user.status === 'DE_ACTIVATED' ? 'active' : 'deactive'}`);
    }
    const { success } = await api.post({});
    if (success) {
      enqueueSnackbar(`User ${user.status === 'DE_ACTIVATED' ? 'activated' : 'deactivated'} successfull!`, { variant: 'success' });
    } else {
      enqueueSnackbar(`Could not ${user.status === 'DE_ACTIVATED' ? 'activate' : 'deactivate'} user!`, { variant: 'error' });
    }
    setOpen(false);
    dispatch(getReporters());
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }} onClick={() => { onAction('edit', user); handleClose(); }}>
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={() => { onAction('changeStatus', user); handleClose(); }}>
          {(user.status === 'DE_ACTIVATED' || user.status === 'UNVERIFIED_DE_ACTIVATED') ? 'Activate' : 'Deactivate'}
        </MenuItem>
        {user.status === 'UNVERIFIED' &&
          <MenuItem sx={{ color: 'error.main' }} onClick={() => onAction('reverify', user)}>
            Re-send on-boarding e-mail
          </MenuItem>
        }
      </MenuPopover>
    </>
  );
}
