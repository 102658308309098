import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { useDispatch } from 'redux/store';
import APIServices from 'apiUtils/APIServices';
import { OPERATOR } from 'backend/url_helper';

export default function AddOperator() {
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMountedRef();
  const dispatch = useDispatch();
  const newOperatorSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]*$/, t('forms.name'))
      .required(`${t('forms.person.name')} ${t('forms.is-required')}`),
    email: Yup.string().required(`${t('forms.person.email')} ${t('forms.is-required')}`)
    .matches(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/, 'Valid email address required.').required(t('Email is required')),
  });

  const defaultValues = {
    name: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(newOperatorSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    setError,
    handleSubmit,
    isSubmitting,
    reset,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const { success, data: response } = await new APIServices(`${OPERATOR}`).post(data);
      if (success) {
        enqueueSnackbar(`${t('users.operator-added')}`, { variant: 'success' });
      }
    } catch (error) {
      if (isMounted.current) {
        setError('afterSubmit', error);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };
  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name="name" label={t('person.name')} />
        <RHFTextField name="email" label={t('person.email')} />
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton sx={{ width: 160 }} type="submit" variant="contained" loading={isSubmitting}>
            {t('users.add-operator')}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
