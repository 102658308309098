import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'components/Iconify';
import { fShortenNumber } from 'utils/formatNumber';

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3),
}));


UsersStatWidget.propTypes = {
  title: PropTypes.string,
  stat: PropTypes.number,
  icon: PropTypes.string
  };
export default function UsersStatWidget({ title, stat, icon }) {
  return (
    <RootStyle>
      <div>
        <Typography variant="h3">{fShortenNumber(stat)}</Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
      </div>
      {icon && (
        <Iconify
          sx={{
            width: 120,
            height: 120,
            lineHeight: 0,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
          icon={icon}
          width={48}
          height={48}
        />
      )}
    </RootStyle>
  );
}
