import { useEffect, useState } from 'react';

// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import NepaliDate from 'nepali-date-converter';
import styled from '@emotion/styled';

import { FormControl, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------
import 'nepali-datepicker-reactjs/dist/index.css';

const RHFDateField = ({ name, label, ...other }) => {
  const { control, setValue, watch } = useFormContext();
  const [nepali, setNepali] = useState();
  const value = watch()?.[name];

  useEffect(() => {
    if (value && typeof nepali === 'undefined') {
      const nepaliDate = new NepaliDate(new Date(value));
      setNepali(nepaliDate.format('YYYY-MM-DD'));
    }
  }, [value]);

  const onNepaliDatechange = (val) => {
    const nepaliDate = new NepaliDate(val);
    const enDate = nepaliDate.getAD();
    const yearString = enDate.year;
    const monthString = `0${enDate.month + 1}`.slice(-2);
    const dateString = `0${enDate.date}`.slice(-2);
    const enDateString = `${yearString}-${monthString}-${dateString}`;
    setNepali(nepaliDate.format('YYYY-MM-DD'));
    setValue(name, enDateString);
  };

  return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <StyledDatePicker>

            <fieldset className="custom-date-picker">
              <legend className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-root MuiFormLabel-colorPrimary css-6dsyb7-MuiFormLabel-root-MuiInputLabel-root">
                {label}
              </legend>
              <NepaliDatePicker
                {...field}
                className="form-control"
                onChange={onNepaliDatechange} // send value to hook form
                value={nepali} // input value
                options={{ calenderLocale: 'en', valueLocale: 'en' }}
                disabled
                {...other}
              />
            </fieldset>

            {!!error && (
              <FormHelperText error sx={{ px: 2 }}>
                {error.message}
              </FormHelperText>
            )}
            </StyledDatePicker>
          </FormControl>
        )}
      />
  );
};

export default RHFDateField;

const StyledDatePicker = styled.div`
  .custom-date-picker {
    border-radius: 10px;
    border: 1px solid #cfcfcf;
    legend {
      padding: 0px 8px;
      background-color: #fff;
    }
    .month-day {
      :hover {
        background-color: #cfcfcf;
      }
    }
    input {
      width: 300px !important;
      border: 0;
      font: inherit;
      letter-spacing: inherit;
      color: currentColor;
      padding: 4px 0 5px;
      box-sizing: content-box;
      background: none;
      height: 1.4375em;
      margin: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      min-width: 0;
      width: 100%;
      animation-name: mui-auto-fill-cancel;
      animation-duration: 10ms;
      padding: 16.5px 14px;
      :focus-visible {
        outline: 0;
      }
    }
  }
`;
