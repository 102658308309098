import { HOST_API } from '../../config';


const API_URL = HOST_API;

const myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
myHeaders.append('Authorization', `Bearer ${window.localStorage.getItem('accessToken')}`);

export async function fetchApi(url, method, data, query){
  const furl = new URL(API_URL + url);
  if(query){
      Object.keys(query).forEach(key => furl.searchParams.append(key, query[key]))
  }
  const requestOptions = {
    method,
    headers: myHeaders
  };
  if(method !=="GET"){
    requestOptions.body = JSON.stringify(data);
  }
  const [response] = await Promise.all([fetch(furl, requestOptions)
    .then(response => response.json())
    .catch(error => error.json())]);
  return response;
}