import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
// type
import {  UploadAvatar, UploadMultiFile, UploadSingleFile } from '../upload';
import ImageUpload from 'components/upload/ImageUpload';

// ----------------------------------------------------------------------

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            {/* <UploadAvatar error={checkError} {...other} file={field.value} /> */}
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

RHFUploadSingleFile.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string
};

export function RHFUploadSingleFile({ name, accept, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        return (
          <UploadSingleFile
            file={field.value}
            accept={accept || ".zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

RHFUploadMultiFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}


// ----------------------------------------------------------------------

RHFImageUpload.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string
};

export function RHFImageUpload({ name, accept, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        return (
          <ImageUpload
            file={field.value}
            accept={accept || ".zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// export function RHFUpload({ name, multiple, helperText, ...other }) {
//   const { control } = useFormContext();

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field, fieldState: { error } }) =>
//         multiple ? (
//           <Upload
//             multiple
//             accept={{ 'image/*': [] }}
//             files={field.value}
//             error={!!error}
//             helperText={
//               (!!error || helperText) && (
//                 <FormHelperText error={!!error} sx={{ px: 2 }}>
//                   {error ? error?.message : helperText}
//                 </FormHelperText>
//               )
//             }
//             {...other}
//           />
//         ) : (
//           <Upload
//             accept={{ 'image/*': [] }}
//             file={field.value}
//             error={!!error}
//             helperText={
//               (!!error || helperText) && (
//                 <FormHelperText error={!!error} sx={{ px: 2 }}>
//                   {error ? error?.message : helperText}
//                 </FormHelperText>
//               )
//             }
//             {...other}
//           />
//         )
//       }
//     />
//   );
// }

// RHFUpload.propTypes = {
//   helperText: PropTypes.string,
//   multiple: PropTypes.bool,
//   name: PropTypes.string,
// };