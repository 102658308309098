import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import { withTranslation } from 'react-i18next';
import SimpleBackdrop from './SimpleBackdrop';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, t , title = '', meta, loading = false, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | ${t('app.title')}`}</title>
      {meta}
    </Helmet>

    <Box sx={{px: 5}} ref={ref} {...other}>
      {children}
    </Box>
    <SimpleBackdrop open={loading} />
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  t: PropTypes.any
};

export default withTranslation()(Page);
