import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gi: {
    id: '',
    name: '',
    fileNo: '',
    cfugCode: '',
    geoGraphicalRegion: '',
    regionId: '',
    wards: [],
    divisionForestOffice: '',
    subDivisionForestOffice: '',
    mapData: {
      attachmentId: 0,
      type: '',
    },
    minAltitude: 0,
    maxAltitude: 0,
    area: 0,
    membershipRenewalDate: '',
    forestId: 0,
    fecofunMember: true,
  },
  cd: {
    id: 0,
    date: '2022-03-06',
    total: 0,
    maleCount: 0,
    femaleCount: 0,
    othersCount: 0,
    ethnicGenderCount: [
      {
        id: 0,
        ethnicity: 'ALL',
        maleCount: 0,
        femaleCount: 0,
        othersCount: 0,
      },
    ],
    mainPostDetails: [
      {
        date: '2022-03-06',
        post: 'PRESIDENT',
        name: 'string',
        ethnicity: 'ALL',
        gender: 'MALE',
      },
    ],
    committeeProsperity: {
      levelACount: 0,
      levelBCount: 0,
      levelCCount: 0,
      levelDCount: 0,
    },
    subCommitteeGenderCount: [
      {
        name: 'string',
        date: '2022-03-06',
        maleCount: 0,
        femaleCount: 0,
        othersCount: 0,
      },
    ],
  },
  step: 0,
};

const slice = createSlice({
  name: 'giReport',
  initialState,
  reducers: {
    setGI(state, action) {
      return action.payload;
    },
    handleGINextStep(state) {
      return {
        ...state,
        step: state.step + 1,
      };
    },
    handleGIBackStep(state) {
      return {
        ...state,
        step: state.step - 1,
      };
    },
    handleGISubmit(state, action) {
      return {
        ...state,
        gi: action.payload,
      };
    },
    resetGIForm(state) {
      return {
        ...state,
        gi: {
          id: '',
          name: '',
          fileNo: '',
          cfugCode: '',
          geoGraphicalRegion: '',
          regionId: '',
          wards: [],
          divisionForestOffice: '',
          subDivisionForestOffice: '',
          handOverDate: '',
          mapData: {
            attachmentId: 0,
            type: '',
          },
          minAltitude: 0,
          maxAltitude: 0,
          area: 0,
          membershipRenewalDate: '',
          forestId: 0,
          infoOfficerContact: {
            id: 0,
            name: 'string',
            position: 'string',
            contactNumber: 'string',
          },
          groupOfficerContact: {
            id: 0,
            name: 'string',
            position: 'string',
            contactNumber: 'string',
          },
          fecofunMember: true,
        },
        step: 0,
      };
    },
    handleStep(state, action) {
      return {
        ...state,
        step: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setGI, handleGINextStep, handleGIBackStep, handleGISubmit, resetGIForm, handleStep } = slice.actions;
