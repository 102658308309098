import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

const QuestionnaireView = ({data}) => {
  console.log(data)
  return (
    <Stack direction='column' spacing={5}>
      { data.data.groups.map((g, gIndex) => (
        <Stack direction={'column'} spacing={3}>
          <Typography variant={'h5'}>{`${gIndex + 1}. ${g.title} (${g.code})`}</Typography>
          { g.questions.map((q, qIndex) => (
            <Stack direction={'column'}>
              <Typography variant={'h7'}>{`${qIndex + 1}. [${q.code}] ${q.text}`}</Typography>
              <FormControl disabled>
                <RadioGroup defaultValue={q.answer} row>
                  { q.choices.map(c => <FormControlLabel label={c.text} value={c.value} control={<Radio/>} />) }
                </RadioGroup>
              </FormControl>
            </Stack>

          )) }
        </Stack>
      )) }
    </Stack>
  )
}

export default QuestionnaireView;
