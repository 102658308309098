import { createSlice } from '@reduxjs/toolkit';
import * as actions from '../actions/operators'


const initialState = {
  operators: []
};

const slice = createSlice({
  name : "operators",
  initialState,
  extraReducers : (builder => {
    builder.addCase(actions.getOperators.pending, (state ) => {
      state.operatorsLoading = true;
      state.operatorsError = null;
    });
    builder.addCase(actions.getOperators.fulfilled, (state, action) => {
      state.operatorsLoading = false;
      state.operators = action.payload;
    });
    builder.addCase(actions.getOperators.rejected, (state, action) => {
      state.operatorsLoading = false;
      state.operatorsError = action;
    });
  })
})

export default slice.reducer;