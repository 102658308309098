import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Scrollbar from 'components/Scrollbar';
import Label from 'components/Label';
import { dispatch, useSelector } from 'redux/store';
import MenuPopover from 'components/MenuPopover';
import Iconify from 'components/Iconify';
import { getOperators } from 'redux/actions/operators';
import { useSnackbar } from 'notistack';
import APIServices from 'apiUtils/APIServices';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

function UsersTable(props) {
  return (
    <Scrollbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>S.N</TableCell>
            {TABLE_HEAD.map(props.callbackfn)}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.operators?.items
            ?.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
            .map(props.prop2)}
        </TableBody>
        <TableFooter>
          <TablePagination
            id="tablePagination"
            count={props.operators?.items?.length || 1}
            onPageChange={props.handleChangePage}
            rowsPerPage={props?.rowsPerPage}
            onRowsPerPageChange={props?.handleChangeRowsPerPage}
            page={props.page}
          />
        </TableFooter>
      </Table>
    </Scrollbar>
  );
}

UsersTable.propTypes = {
  callbackfn: PropTypes.func,
  operators: PropTypes.any,
  prop2: PropTypes.func,
};

function DTableRow(props) {
  return (
    <TableRow>
      <TableCell>{props.i + 1}</TableCell>
      <TableCell>{props.user.fullName}</TableCell>
      <TableCell>{props.user.username}</TableCell>
      <TableCell>{props.user?.role[0]}</TableCell>
      <TableCell>
        {' '}
        <Label color={'info'}>{props.user.status}</Label>
      </TableCell>
      <TableCell>
        <OperatorsListPopOver user={props.user} getOperators={props.getOperators}/>
      </TableCell>
    </TableRow>
  );
}

DTableRow.propTypes = {
  i: PropTypes.any,
  user: PropTypes.any,
};

export default function OperatorList() {
  const { operators } = useSelector((state) => state.operators);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getOperators());
  }, []);

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader title={'Operators List'} />
      <CardContent>
        <UsersTable
          callbackfn={({ id, label, alignRight }) => (
            <TableCell key={id} align={alignRight ? 'right' : 'left'}>
              {label}
            </TableCell>
          )}
          operators={operators}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          prop2={(user, i) => <DTableRow key={user.id} i={i} user={user} getOperators={getOperators}/>}
        />
      </CardContent>
    </Card>
  );
}

function OperatorsListPopOver({ user, getOperators }) {
  const [open, setOpen] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [action, setAction] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const confirmAction = () => {
    if(action === 'changeStatus') {
      changeStatus()
    } else if(action === 'reverify') {
      reverify()
    }
  }
  const onAction = (action)  => {
    setAction(action)
    if(['changeStatus', 'reverify'].includes(action)) {
      toggleDialog();
    }
    setOpen(!open);
  }
  const changeStatus = async () => {
    let api;
    if(user.status === 'UNVERIFIED_DE_ACTIVATED') {
      api = await new APIServices(`admin/user/${user.id}/status/unverify`);
    } else {
      api = await new APIServices(`admin/user/${user.id}/status/${user.status === 'DE_ACTIVATED' ? 'active': 'deactive'}`);
    }
    const { success } = await api.post({});
    if (success) {
      enqueueSnackbar(`User ${user.status === 'DE_ACTIVATED' ? 'activated': 'deactivated'} successfully!`, { variant: 'success' });
    } else {
      enqueueSnackbar(`Could not ${user.status === 'DE_ACTIVATED' ? 'activate': 'deactivate'} user!`, { variant: 'error' });
    }
    setDialogOpen(false);
    dispatch(getOperators());
  };

  const reverify = async () => {
    let api;
      if(user.status === 'UNVERIFIED') {
        api = await new APIServices(`admin/user/${user.id}/resend-activation-link`);
      }
      const { success } = await api.post({});
      if (success) {
        enqueueSnackbar(`On boarding email sent successfully.`, { variant: 'success' });
      } else {
        enqueueSnackbar('Could not sent on boarding email!', { variant: 'error' });
      }
      setDialogOpen(false);
  }
  const toggleDialog = (_) => {
    setDialogOpen(!dialogOpen);
  };
  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        {/* <MenuItem>
          <Iconify icon={'eva:download-fill'} sx={{ ...ICON }} />
          View
        </MenuItem>

        <MenuItem>
          <Iconify icon={'eva:printer-fill'} sx={{ ...ICON }} />
          Update
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} /> */}
        <MenuItem sx={{ color: 'error.main' }} onClick={() => {onAction('changeStatus'); handleClose();}}>
          {(user.status === 'DE_ACTIVATED' || user.status === 'UNVERIFIED_DE_ACTIVATED') ? 'Activate': 'Deactivate'}
        </MenuItem>
        { user.status === 'UNVERIFIED' &&
          <MenuItem sx={{ color: 'error.main' }} onClick={() => {onAction('reverify'); handleClose();}}>
            Re-send on boardin mail
          </MenuItem>
        }
      </MenuPopover>
      <Dialog
            open={dialogOpen}
            onClose={toggleDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Are you sure to perfom the action?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to change user status?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog}>Cancel</Button>
              <Button onClick={confirmAction} autoFocus color="error" variant="outlined">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
    </>
  );
}
