import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { PATH_AUTH } from 'routes/paths';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
// components
import Iconify from 'components/Iconify';
import { FormProvider, RHFCheckbox, RHFTextField } from 'components/hook-form';

// ----------------------------------------------------------------------

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
};

function LoginForm({ t }) {
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().email(t('auth.valid-email')).required(t('auth.email-required')),
    password: Yup.string().required(t('auth.password-required'))
  });

  const defaultValues = {
    username: '',
    password: ''
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const res = await login(data);
      // debugger;

    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        enqueueSnackbar(`Login Failed`, { variant: 'error' });
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField varaint name="username" label={t('person.email')} />
        <RHFTextField
          name="password"
          label={t('person.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack direction="row" fullWidth alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="rememberMe" label={t('auth.remember')} />
        <Link component={RouterLink} color={'black'} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          {t('auth.forgot-password')}
        </Link>
      </Stack>

      <LoadingButton fullWidth sx={{ padding: '10px' }} type="submit" variant="contained" loading={isSubmitting}>
        {t('auth.login')}
      </LoadingButton>
    </FormProvider>
  );
}

export default withTranslation()(LoginForm);
