import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from 'components/LoadingScreen';
import DMForms from '../pages/protected/reports/forms/GeneralDataCollectionForm/DevelopmentAndMangementForm';
import EditProfile from '../pages/protected/common/profile/Profile/EditProfile';
import Region from '../pages/protected/dataRegions/region/Region';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import DataOperators from '../pages/protected/users/DataOperators/DataOperators';
import DataReporters from '../pages/protected/users/DataReporters/DataReporters';
import UsersByAdmin from '../pages/protected/users/SuperAdmin/DataUsers';
import NewReports from '../pages/protected/reports/NewReports';
import Reports from '../pages/protected/reports/Reports';
import Users from '../pages/protected/users/Users';
import Tasks from '../pages/protected/tasks/Tasks';
import AssignedRegion from '../pages/protected/tasks/AssignedRegion';
import Analytics from '../pages/analytics';
import MapExplore from '../pages/MapExplore';
import Search from '../pages/protected/search';
import Municipal from '../pages/areas/municipal';
import AllCf from '../pages/areas/AllCf';
import PartnerList from '../pages/areas/Partners';
import Donate
 from '../pages/donate';
 import GetInvolved from '../pages/getinvolved';

 import Blogs from 'pages/protected/management/Blogs';
import AreaAnalytics from 'pages/areas/AreaAnalytics';
// import ReportDashboard from 'pages/Reports';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: <MainLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'register', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'confirmAccount/:userId/:confirmToken',
          element: <VerifyCode />,
        },
        {
          path: 'reset-password/:userId/:token',
          element: <VerifyAndResetPassword />,
        },
      ],
    },

    // Dashboard Routes
    { path: 'dashboard/tasks/report/:forestId', element: <ReportDashboard /> },
    { path: 'dashboard/tasks/report/:forestId/:type', element: <ReportDashboard /> },
    { path: '/dashboard/tasks/view-reports/:report-id', element: <Analytics title={'Report'} /> },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'app', element: <Dashboard /> },
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        { path: 'profile', element: <UserProfile /> },
        { path: 'profile/edit', element: <EditProfile /> },
        {
          path: 'users',
          children: [
            {
              path: 'overview',
              element: (
                <RoleBasedGuard accessibleRoles={'PARTNER'}>
                  <Users />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'operators',
              element: (
                <RoleBasedGuard accessibleRoles={'PARTNER'}>
                  <DataOperators />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'reporters',
              element: (
                <RoleBasedGuard accessibleRoles={'OPERATOR'}>
                  <DataReporters />
                </RoleBasedGuard>
              ),
            },
            {
              path: '',
              element: (
                <RoleBasedGuard accessibleRoles={'SUPER_ADMIN'}>
                  <UsersByAdmin />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'reports',
          element: <Reports />,
        },
        { path: 'species', element: <SpeciesList /> },
        { path: 'tasks', element: <Tasks /> },
        { path: 'blogs', element: (
          <RoleBasedGuard accessibleRoles={'SUPER_ADMIN'}>
          <Blogs />
          </RoleBasedGuard>
        ) },
        { path: 'search', element: <Search /> },
        { path: 'areas', element: <Municipal /> },
        { path: 'areas/municipal', element: <Municipal /> },
   
        { path: 'areas/cf', element: <AllCf  />  },
        { path: 'areas/partners', element:(<RoleBasedGuard accessibleRoles={'SUPER_ADMIN'}><PartnerList />   </RoleBasedGuard>) },
        { path: 'areas/analytics/:type/:id', element: <AreaAnalytics /> },
        { path: 'tasks/report/view/:forestId', element: <Analytics title={'View Detail'} /> },
        // { path: 'tasks/report/:forestId', element: <ReportDashboard /> },
        { path: 'tasks/:rId', element: <AssignedRegion /> },
        { path: 'tasks/:rId/new-report', element: <NewReports /> },
        { path: 'tasks/:rId/new-report/data-collection', element: <CFGeneralDataForm /> },
        {
          path: 'data-collection',
          children: [
            {
              path: 'general',
              element: <DMForms />,
            },
          ],
        },
        { path: 'data-regions', element: <DataRegions /> },
        { path: 'data-regions/assigned', element: <NewReports /> },
        { path: 'data-regions/:rId', element: <Region /> },
        { path: 'data-regions/:rId/reports/:reportId', element: 'Report View Page' },
        { path: 'data-regions/:rId/cfs', element: <Region /> },
        { path: 'data-regions/:rId/reporters', element: 'reporters' },
        { path: 'data-regions/:rId/new-report', element: <NewReports /> },
        { path: 'data-regions/:rId/new-report/g', element: <CFGeneralDataForm /> },
        { path: 'data-regions/:rId/cfs/:cfId/overview', element: <CFView /> },
        { path: 'data-regions/cf', element: <CFView /> },
        { path: 'data-regions/cf/form/1', element: <CFGeneralDataForm /> },
        { path: 'data-regions/cf/form/2', element: <DMForms /> },
        { path: 'forms/cf', element: <GIForm /> },
        { path: 'forms/dm', element: <DevelopmentAndManagementForm /> },
        { path: 'forms/bnpi', element: <BiologicalAndPhysicalInformation /> },
        { path: 'forms/ppcd', element: <ProfitableProgramAndCommunityDevelopment /> },
        { path: 'forms/iggi', element: <InstitutionalGoodGovernanceInformation /> },


      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'auth/register', element: <ComingSoon /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'analytics', element: <Analytics /> },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <LandingPage />, index: true },
        { path: '/partners', element: <Partners /> },
        { path: '/cf/dashboard/:forestId', element: <CfDashboard /> },
        { path: '/contact-us', element: <ContactUs /> },
        { path: '/about-us', element: <AboutUs /> },
        { path: '/privacy-policy', element: <PrivacyPolicy /> },
        { path: '/faqs', element: <Faqs /> },
        { path: '/blog', element: <Blog /> },
        { path: '/blog/:slug', element: <SignleBlog /> },
        { path: '/get-involved', element: <GetInvolved /> },
        { path: '/donate', element: <Donate /> },
      ],
    },
    { path: '/map', element: <MapExplore /> },
    { path: 'report-dashboard', element: <ReportDashboard /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/SetProfile/VerifyAndSetUser')));
const VerifyAndResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword/VerifyAndResetPassword')));

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ContactUs = Loadable(lazy(() => import('pages/Contact')));
const AboutUs = Loadable(lazy(() => import('../pages/About')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const Partners = Loadable(lazy(() => import('../pages/partners')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')))
const Blog = Loadable(lazy(() => import('../pages/Blog/index')))
const SignleBlog = Loadable(lazy(() => import('../pages/Blog/Single')))

// Protected Routes
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/protected/common/dashboard/dashboard')));

// Profile
const UserProfile = Loadable(lazy(() => import('../pages/protected/common/profile/UserProfile')));

const ReportDashboard = Loadable(lazy(() => import('../pages/Reports/index')));

const CfDashboard = Loadable(lazy(() => import('../pages/explore')));

// users
// const DpUsers = Loadable(lazy(() => import('../pages/protected/users/DpUsers')));

// date regions
const DataRegions = Loadable(lazy(() => import('../pages/protected/dataRegions/regions/Regions')));

// CF View
const CFView = Loadable(lazy(() => import('../pages/protected/common/cf/CFView')));

// CF Forms
const CFGeneralDataForm = Loadable(lazy(() => import('../pages/protected/reports/forms/GeneralDataCollectionForm')));
const GIForm = Loadable(
  lazy(() => import('../pages/protected/reports/forms/GeneralDataCollectionForm/GeneralInfo/GIForm'))
);

const DevelopmentAndManagementForm = Loadable(
  lazy(() => import('../pages/protected/reports/forms/GeneralDataCollectionForm/DevelopmentAndMangementForm'))
);
const BiologicalAndPhysicalInformation = Loadable(
  lazy(() => import('../pages/protected/reports/forms/GeneralDataCollectionForm/BiologicalAndPhysicalInformation'))
);
const ProfitableProgramAndCommunityDevelopment = Loadable(
  lazy(() =>
    import('../pages/protected/reports/forms/GeneralDataCollectionForm/ProfitableProgramAndCommunityDevelopment')
  )
);

const InstitutionalGoodGovernanceInformation = Loadable(
  lazy(() => import('../pages/protected/reports/forms/GeneralDataCollectionForm/InititutionalInformation'))
);
const SpeciesList = Loadable(lazy(() => import('../pages/protected/dropdowns/Species')));
