import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import { useForm } from 'react-hook-form';
import { Button, Card, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { FormProvider, RHFTextField, 
  RHFSwitch, 
  RHFEditor, 
  RHFUploadSingleFile 
} from 'components/hook-form';
import { useRouter } from 'routes/hooks';
import APIServices from 'apiUtils/APIServices';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import useResponsive from 'hooks/useResponsive';
import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add'
import { generateSlug } from 'utils/string.util';
import { getImgSrc } from 'utils/getImgSrc';
import { status } from 'nprogress';
import { FILE_SIZE } from 'store/constant';
import { fData } from 'utils/formatNumber';

const BlogButton = ({ LabelComponent, data }) => {
  const router = useRouter();
  const [id, setId] = useState(null);
  const [ currentPost, setCurrentPost ] = useState();
  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const fetchPost = async (pId) => {
    const { data, success } = await new APIServices(`/admin/post/${pId}`).get();
    if (success) {
      setCurrentPost(data?.data || []);
    };
   
  };

  const api = new APIServices('admin/post');

  const newBlogSchema = yup.object().shape({
    title: yup.string().required('Title is required').label('Title'),
    content: yup.string().required('Body is required').label('Body'),
    coverImage: yup.mixed().required('Cover is required').label('Cover Image'),
    status: yup.boolean().label('Status'),
    pinned: yup.boolean().label('Pinned'),
    authorName: yup.string().required('Author'),
    slug: yup.string().label('Slug'),
    excerpt: yup.string().label('Excerpt'),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentPost?.title || '',
      content: currentPost?.content || '',
      coverImageId: currentPost?.coverImage?.id,
      coverImage: currentPost?.coverImage?.id? getImgSrc(currentPost?.coverImage.id) : '',
      status: currentPost?.status === 'PUBLISHED',
      pinned: currentPost?.pinned || false,
      authorName: currentPost?.authorName || '',
      slug: currentPost?.slug || '',
      excerpt: currentPost?.excerpt || ''

    }),
    [currentPost]
  );

  const methods = useForm({
    resolver: yupResolver(newBlogSchema),
    defaultValues,
    mode: 'all',
  });

  const { setError,setValue, handleSubmit, watch, reset, formState: {isSubmitting, isValid} } = methods;

  useEffect(() => {
    if (currentPost) {
      reset(defaultValues);
    }
  }, [currentPost, defaultValues, reset]);

  const onSubmit = async (data) => {
    data.status = data.status? "PUBLISHED" : "DRAFT";
    data.category = 1;
    data.slug = generateSlug(data.title);
    data.excerpt = data.content.substring(0,250);
    data.coverImage = data.coverImageId;
    delete data.coverImageId;
    const { success, data: responseData } = await (currentPost?.id ? api.put(currentPost?.id, data) : api.post(data));
    if (success) {
      toggleModal();
      enqueueSnackbar(`Post ${currentPost?.id ? 'Updated' : 'Created'} Successfully`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      reset({});
    } else {
      const error = responseData?.data || {};
      error?.details?.forEach(({ errorMessage, field }) => {
        setError(field, { type: 'manual', message: errorMessage });
      });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (_, id=null) => {
    if (isModalOpen) reset({});
    setIsModalOpen(!isModalOpen);
    if(id){
      setId(id);
      fetchPost(id)
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const api = new APIServices('attachments');
            const data = new FormData();
            console.log(acceptedFiles[0])
            data.append('file', acceptedFiles[0]);
            data.append('qualifier', 'blog');
            const { success, data: responseData } = await api.post(data);
            if (success) {
                const { id } = responseData;
                 const file = acceptedFiles[0];

                const newFile = Object.assign(file, {
                  preview: URL.createObjectURL(file),
                });
                setValue('coverImage', newFile, { shouldValidate: true});
                // setSrc(URL.createObjectURL(acceptedFiles[0]))
                setValue('coverImageId', id, { shouldValidate: true });
            } else {
                enqueueSnackbar('Could not upload file!', { variant: 'error' });
            }
      // const file = acceptedFiles[0];

      // const newFile = Object.assign(file, {
      //   preview: URL.createObjectURL(file),
      // });
      // console.log(newFile);

      // if (file) {
      //   setValue('coverUrl', newFile, { shouldValidate: true });
      // }
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(() => {
    setValue('coverUrl', null);
  }, [setValue]);

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={10}>
          <Typography variant="h6" sx={{ mb: 0.5,mt:2 }}>
           {currentPost ? 'Update' : 'Add'} Blog 
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Title, Content, image...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={10}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="title" label="Post Title" />

            {/* <RHFTextField name="description" label="Description" multiline rows={3} /> */}

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Content</Typography>
              <RHFEditor simple name="content" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Cover</Typography>
              <RHFUploadSingleFile
                name="coverImage"
                maxSize={FILE_SIZE}
                onDrop={handleDrop}
                onDelete={handleRemoveFile}
                value={[defaultValues.coverImage]}
                accept="image/*" 
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'orange',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(FILE_SIZE)}
                  </Typography>
                }
              />
            </Stack>

            <RHFTextField name="authorName" label="Author name" />

            <RHFSwitch  key={'pinned'} name="pinned" label={`Featured post`} sx={{ m: 0 }} />
            <RHFSwitch  key={'publish'} name="status" label={`Publish`} sx={{ m: 0 }} />
            
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} justifyContent="right" sx={{ display: 'flex', alignItems: 'right', marginTop:'5%' }}>

        <Button color="inherit" variant="outlined" size="large" onClick={toggleModal}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentPost ? 'Create Post' : 'Save Changes'}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <>
      {isModalOpen ? (
        <Modal
          open={isModalOpen}
          onClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Add new Post
            </Typography> */}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container justifyContent="center">
              {renderDetails}
              {renderActions}
              </Grid>
             
            </FormProvider>
          </Box>
        </Modal>
      ) : null}
      {LabelComponent ? (
        LabelComponent(toggleModal)
      ) : (
        <Button size="large" startIcon={<AddIcon/> } variant="outlined" onClick={toggleModal} sx={{ minWidth: 140, marginX: 1 }}>
          Add New Post
        </Button>
      )}
    </>
  );
};
export default BlogButton;

const style = {
  p: 4,
  top: '50%',
  left: '50%',
  width: '60%',
  boxShadow: 24,
  position: 'absolute',
  bgcolor: 'background.paper',
  transform: 'translate(-50%, -50%)',
  borderRadius: 1, 
  height: '80%',
  overflow: 'scroll'
};
