import { Box, Card, CardContent, Grid } from '@mui/material'
import APIServices from 'apiUtils/APIServices';
import React, { useEffect, useState } from 'react'
import CardItemList from './CardItemList';
import Gis from 'pages/gis';

function MapCf({isLoading, reports, mapVisualization}) {

    // const [data, setData] = useState([]);

    // const fetchReport = async () => {
    //   const { data, success } = await new APIServices('partner/report-count').get();
    //   if (success) {
    //       setData(data.data)
    //   };
    // };

    // useEffect(() =>{
    //     fetchReport();
    // }, []);

    const totalCf = reports?.reduce((total, region) => total + region.reports, 0);


    const title = `Total CF : ${totalCf}`;
  return (
    <Grid container spacing={2} marginTop={'10px'}>
    <Grid item xs={6} md={8}>
    <Card  sx={{ bgcolor: '#cfe8fc', height: '53vh' }}>
                        <CardContent style={{padding:0}}>
                        <Box sx={{
                                width: '100%', height: '53vh'
                            }}>
                                <Gis mapVisualization={mapVisualization} />
                            </Box>

                        </CardContent>
                    </Card>
    </Grid>
    <Grid item xs={6} md={4}>
    <CardItemList title={`${title}`}  list={reports ?? []}  isLoading={false}/>
      {/* <Item>xs=6 md=4</Item> */}
    </Grid>
  </Grid>
  )
}

export default MapCf
