import DashContainer from './DashContainer';
import {
    ListItem,
    List,
    ListItemText,
} from "@mui/material";

const DashList = ({ title, data, ...props }) => {
    if (data.length <= 0) {
        return null;
    }

    return (
        <DashContainer title={title} {...props}>
            <List style={{ minWidth: 200, maxHeight: 300, overflow: "auto" }}>
                {data.map((value, index) => {
                    return (
                        <ListItem key={index}>
                            <ListItemText>{value}</ListItemText>
                        </ListItem>
                    );
                })}
            </List>
        </DashContainer>
    );
};

export default DashList;