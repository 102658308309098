import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import usersReducer from './slices/users';
import regionsReducer from './slices/regions';
import reportsReducer from './slices/reports';
import operatorReducer from './slices/operators';
import reporterReducer from './slices/reporters';
import giReportReducer from './slices/giReport';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const regionsPersistConfig = {
  key: 'regions',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['regions', 'region'],
};

const reportsPersistConfig = {
  key: 'reports',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['reports', 'report'],
};

const giReportPersistConfig = {
  key : 'giReport',
  storage,
  keyPrefix: 'report-data-collection-',
  whitelist: ['step', 'gi'],
}

const rootReducer = combineReducers({
  users : usersReducer,
  regions : persistReducer(regionsPersistConfig, regionsReducer),
  reports : persistReducer(reportsPersistConfig, reportsReducer),
  giReport : persistReducer(giReportPersistConfig, giReportReducer),
  operators : operatorReducer,
  reporters : reporterReducer,
});

export { rootPersistConfig, rootReducer };
