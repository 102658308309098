import { Button, Card, CardActions, CardContent, Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import * as React from 'react';
import Iconify from 'components/Iconify';

AssignedRegionCard.propTypes = {
  region: PropTypes.object.isRequired,
};

export default function AssignedRegionCard({ region }) {
  const ACTIONS = [
    {
      name: 'General Data Reports',
      icon: 'eva:file-text-fill',
      path: `${region?.id}/reports`,
    },
    {
      name: 'Enterprises',
      icon: 'eva:briefcase-fill',
      path: `${region?.id}/reports`,
    },
    {
      name: 'Questionnaires',
      icon: 'eva:file-text-outline',
      path: `${region?.id}/reports`,
    },
  ];

  return (
    <Card
      variant={'outlined'}
      sx={{
        padding: 1,
        margin: 1,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <CardContent
        sx={{
          pb: 0,
        }}
      >
        <Link to={`${region?.id}`} color="inherit" component={RouterLink}>
          <Iconify icon={'openmoji:authority-building'} sx={{ width: 44, height: 44 }} />
          <Typography variant="h6">{region?.name}</Typography>
        </Link>
      </CardContent>
      {/* <CardActions
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          {ACTIONS.map((el, i) => (
            <Grid item key={i}>
              <Link to={el.path} color="inherit" component={RouterLink}>
              <Button
                color="inherit"
                variant="outlined"
                size="medium"
                startIcon={<Iconify icon={el.icon} />}
                sx={{
                  flexShrink: 0,
                  justifyContent: 'space-between',
                }}
              >
                {el.name}
              </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardActions> */}
    </Card>
  );
}
