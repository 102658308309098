import React, { useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import { useForm } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import { FormProvider, RHFTextField, RHFSelect } from 'components/hook-form';

import APIServices from 'apiUtils/APIServices';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSpeciesSubTypes } from 'dataTypes';
import { useSnackbar } from 'notistack';

const SpecieButton = ({ fetchSpecies, LabelComponent, data: editData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const api = new APIServices('species');
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required().label('Species name'),
        npName: yup.string().required().label('Nepali Name'),
        scientificName: yup.string().required().label('Scientific Name'),
        type: yup.string().required().label('Type'),
        subType: yup.string().required().label('Subtype'),
      })
    ),
    defaultValues: editData,
    mode: 'all',
  });

  const { setError, handleSubmit, watch, reset } = methods;
  const values = watch();

  const onSubmit = async (data) => {
    const { success, data: responseData } = await (editData?.id ? api.put(editData?.id, data) : api.post(data));
    if (success) {
      toggleModal();
      enqueueSnackbar(`Species ${editData?.id ? 'Updated' : 'Created'} Successfully`, {
        variant: 'success',
        autoHideDuration: 2000,
      });
      fetchSpecies();
      reset({});
    } else {
      const error = responseData?.data || {};
      error?.details?.forEach(({ errorMessage, field }) => {
        setError(field, { type: 'manual', message: errorMessage });
      });
    }
  };
  const selectSpeciesType = !values ? '' : values.type;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (_) => {
    if (isModalOpen) reset({});
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {isModalOpen ? (
        <Modal
          open={isModalOpen}
          onClose={toggleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add new Species
            </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <RHFTextField name="name" label={t('Species Name')} />
                <RHFTextField name="npName" label={t('Nepali Name')} />
                <RHFTextField name="scientificName" label={t('Scientific Name')} />
                <RHFSelect name="type" label="Species Type" fullWidth>
                  <option value="" />
                  {['PLANT', 'ANIMAL']?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </RHFSelect>
                {selectSpeciesType ? (
                  <RHFSelect name="subType" label="Species Sub Type" fullWidth>
                    <option value="" />
                    {getSpeciesSubTypes(selectSpeciesType)?.map(({ id: value, value: label }, idx) => (
                      <option key={idx} value={value}>
                        {label}
                      </option>
                    ))}
                  </RHFSelect>
                ) : (
                  ''
                )}
                <Button fullWidth size="large" type="submit" variant="contained">
                  {editData?.id ? 'Update' : 'Save and Continue'}
                </Button>
              </Stack>
            </FormProvider>
          </Box>
        </Modal>
      ) : null}
      {LabelComponent ? (
        LabelComponent(toggleModal)
      ) : (
        <Button size="small" variant="outlined" onClick={toggleModal} sx={{ minWidth: 140, marginX: 1 }}>
          Add New Species
        </Button>
      )}
    </>
  );
};
export default SpecieButton;

const style = {
  p: 4,
  top: '50%',
  left: '50%',
  width: 400,
  boxShadow: 24,
  position: 'absolute',
  border: '2px solid #000',
  bgcolor: 'background.paper',
  transform: 'translate(-50%, -50%)',
};
