import { Card, Grid, Stack, Typography } from '@mui/material';

const GridKeyValueView = ({data}) => {
  return (
    <Grid container spacing={2} columns={data.numColumn}>
      {
        Object.keys(data.data).map(key => (
          <Grid sx={12/data.numColumn} item key={key}>
            <Card>
              <Stack spacing={3} direction={'column'} padding={3}>
                <Typography variant={'h5'}>{ key }</Typography>
                <Typography color={'gray'} variant={'h7'}>{ data.data[key] }</Typography>
              </Stack>
            </Card>
          </Grid>
        ))
      }
    </Grid>
  )
}

export default GridKeyValueView;
