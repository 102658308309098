import PropTypes from 'prop-types';
import * as React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, IconButton, List, ListItem, ListItemText, Tooltip, Typography,  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';

// project imports
import TypographySkeleton from 'ui-component/cards/Skeleton/TypographySkeleton';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { Icon } from '@iconify/react';



// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  

const MemberList = ({ isLoading, title, list }) => {
  const theme = useTheme();
const totalMember = list?.length;
  return (
    <>
      {isLoading ? (
        <TypographySkeleton noOfRows={6} />
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing} >
            <Grid item xs={6}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item style={{paddingTop:'5px'}}>
                      <Typography variant="button" display="block">

        {title}
                        
                      </Typography>
                     

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button variant="contained"> {totalMember}  Members added</Button>
            </Grid>
            <Grid item xs={12} style={{paddingTop:'5px'}}>
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell >Username</TableCell>
            <TableCell >Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list && list.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.fullName}
              </TableCell>
              <TableCell >{row.username}</TableCell>
              <TableCell >{row.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

MemberList.propTypes = {
  isLoading: PropTypes.bool,
};

export default MemberList;
