import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField, Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, variant, ...other }) {
  const { control } = useFormContext();

  const { label, tooltip} = other;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        /* eslint-disable */
        <>
          {
          variant ?
            (<TextField
              sx={{ borderRadius: '8px', border: '1px solid gray.400' }}
              size='small'
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              {...other}
              multiline
            />) : !variant && tooltip ?
                  
 (<Tooltip title={label} arrow>
            <TextField
              sx={{ borderRadius: '8px', border: '1px solid gray.400' }}
              {...field}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!error}
              helperText={error?.message}
              {...other}
              multiline
            />
          </Tooltip>)
          : 
          (<TextField
          sx={{ borderRadius: '8px', border: '1px solid gray.400' }}
          {...field}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={!!error}
          helperText={error?.message}
          {...other}
          
        />)
        
      }
        </>
      )}
    />
  );
}
