import { Stack } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect } from 'react';

import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import AddUser from './AddUser';
import UsersStat from './UserStats';
import UserList from './UserList';
import { useDispatch } from 'redux/store';
import { getOperators } from 'redux/actions/operators';
import { getReporters } from 'redux/actions/reporter';
import { getUserList } from 'redux/actions/users';
import ReporterList from './DataReporters/ReporterList';

export default function Users() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserList());
    dispatch(getOperators());
    dispatch(getReporters());
  }, [dispatch]);
  return (
    <Page title={t('users.title')}>
      <HeaderBreadcrumbs
        heading={t('users.title')}
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Users' }, { name: 'Operators' }]}
      />
      <Stack spacing={2}>
        {/* <UsersStat /> */}
        <AddUser />
        <ReporterList />
      </Stack>
    </Page>
  );
}
