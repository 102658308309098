import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useCallback, useState } from 'react';
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { genderTypes } from 'dataTypes';
import { fData } from 'utils/formatNumber';
import useAuth from 'hooks/useAuth';
import APIServices from 'apiUtils/APIServices';
import useUser from 'hooks/useUser';
import Iconify from 'components/Iconify';
import { FILE_SIZE } from 'store/constant';

function EditProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const userData = useUser();
  useEffect(() => {
    fetchUser(userData.userId);
  }, [userData])

  const api = new APIServices(`admin/user`)
  
  const fetchUser = async (userId) => {
    if(userId) {
      const { success, data: responseData } = await new APIServices(`admin/user/${userId}`).get();
      if(success) {
        reset(responseData);
      }
    }
  }
  const EditProfileSchema = Yup.object().shape({
    fullName: Yup.string().required('Required').nullable(),
    gender: Yup.string().required('Required').nullable(),
    // dob: Yup.date(),
    address: Yup.string().required('Required').nullable(),
    contactNumber: Yup.string().required('Required').nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(EditProfileSchema),
    defaultValues: userData,
    mode: 'onChange'
  });

  const {
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data) => {

      try {
        const { success, data: responseData } = await api.put(userData.userId, data);
        reset(responseData)
        if (success) {  
          enqueueSnackbar('Profile updated successfully!');
          reset();
        } else {
          // TODO: Finalize errors and populate to form as per the error returned form API
          enqueueSnackbar(responseData?.data?.message, { variant: 'error' });
          responseData?.data?.details?.forEach(({ errorMessage, field }) => {
            setError(field, { type: 'manual', message: errorMessage });
          });
          enqueueSnackbar(`Error !!!`, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'avatarUrl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 5 }}>
        <RHFUploadAvatar
          name="avatarUrl"
          accept="image/*"
          maxSize={FILE_SIZE}
          onDrop={handleDrop}
          helperText={
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                mx: 'auto',
                display: 'block',
                textAlign: 'center',
                color: 'text.secondary',
              }}
            >
              Allowed *.jpeg, *.jpg, *.png, *.gif
              <br /> max size of {fData(FILE_SIZE)}
            </Typography>
          }
        />
      </Box>
      <Stack spacing={3}>
        <RHFTextField name="username" label="Email" disabled />
        <RHFTextField name="fullName" label="Full Name" />
        <RHFSelect name="gender" label="Gender" placeholder="Gender">
          <option value="" />
          {genderTypes?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </RHFSelect>
        <RHFTextField name="address" label="Address" />
        <RHFTextField
          name="contactNumber"
          label="Phone Number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:phone-outline'} />
                <Typography variant="body1" sx={{ color: 'text.secondary', ml: 1 }}>
                  +977
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {'Save Changes'}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

export default EditProfile;
