import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  SectionType,
  Table,
  TableCell,
  TableRow,
  TextRun, WidthType,
} from 'docx';

export default class DocxUtil {
  constructor(data) {
    this.data = data;
  }

  generateDocx() {
    const sectionData = this.data && this.data.data || []
    const coverSection = {
      properties: {
        type: SectionType.CONTINUOUS
      },
      children: [
        DocxUtil.__createTitle(this.data.name),
        DocxUtil.__createSubtitle(this.data.cfugCode),
        DocxUtil.__createBadges(this.data.badges)
      ]
    }
    const sections = [coverSection, ...sectionData.map((section, index) => DocxUtil.__createSection(section, index))]
    const doc = new Document({
      title: this.data.name,
      background: {
        color: '#FFFFFF'
      },
      sections
    });
    Packer.toBlob(doc).then((blob) => {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const url = window.URL.createObjectURL(blob);
      a.href = url
      a.download = `${this.data.name}-report.docx`
      a.click()
      window.URL.revokeObjectURL(url)
    })
  }

  static __createSection (sectionData, index) {
    return {
      properties: {
        type: SectionType.NEXT_PAGE
      },
      children: [
        DocxUtil.__createSectionTitle(sectionData, index),
        ...sectionData.data.map(item => DocxUtil.__createDataItem(item)).filter(item => item).flat()
      ]
    }
  }

  static __createSectionTitle(sectionData, index) {
    return new Paragraph({
      text: `${index + 1}. ${sectionData.title.toUpperCase()}`,
      spacing: {
        after: 5
      },
      heading: HeadingLevel.HEADING_2
    })
  }

  static __createDataItem(dataItem) {
    const title = DocxUtil.__renderItemTitle(dataItem)
    switch (dataItem.type) {
      case 'GridKeyValue':
        return [new Paragraph(''), title, DocxUtil.__renderKeyValue(dataItem), new Paragraph(''),]
      case 'Table':
        return [new Paragraph(''), title, DocxUtil.__renderTable(dataItem), new Paragraph(''),]
      case 'QA':
        return DocxUtil.__renderQA(dataItem)
      case 'Questionnaire':
        return DocxUtil.__renderQuestionnaire(dataItem)
      default:
        return []
    }
  }

  static __createTitle(title ) {
    return new Paragraph({
      text: title,
      heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER
    })
  }

  static __createSubtitle(subtitle ) {
    return new Paragraph({
      text: subtitle,
      heading: HeadingLevel.HEADING_1,
      alignment: AlignmentType.CENTER
    })
  }

  static __createBadges(badgeData={data: []} ) {
    return new Paragraph({
      heading: HeadingLevel.HEADING_2,
      alignment: AlignmentType.CENTER,
      children: badgeData.data.map(badge => new TextRun(` *${badge}`))
    })
  }

  static __renderItemTitle(item) {
    return new Paragraph({
      heading: HeadingLevel.HEADING_3,
      alignment: AlignmentType.LEFT,
      text: item.title
    })
  }

  static __renderKeyValue(keyValueData = {data: {}}) {
    return new Table({
      columnWidths: [3000, 3000],
      rows: Object.keys(keyValueData.data || {}).map(key => new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA
            },
            children: [new Paragraph(key)],
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA
            },
            children: [
              new Paragraph(keyValueData.data[key])
            ],
          })
        ]
      }))
    })
  }

  static __renderTable(tableData){
    const headerRows = tableData.data.header.rows.map(row => DocxUtil.__renderTableRow(row, true))
    const headerColumnLengths = tableData.data.header.rows.map(row => row.columns.length)
    const maxColumns = Math.max(...headerColumnLengths)
    const rowWithMaxColumns = headerColumnLengths.indexOf(maxColumns)
    const columnWidths = tableData.data.header.rows[rowWithMaxColumns].columns.map(_ => 8400/maxColumns)
    const bodyRows = tableData.data.data.map(row => DocxUtil.__renderTableRow(row))
    const table =  new Table({
      columnWidths,
      rows: [...headerRows, ...bodyRows]
    })
    if (headerColumnLengths.length > 1){
      console.log(table)
    }
    return table
  }

  static __renderTableRow(row, isHeader = false) {
    return new TableRow({
      tableHeader: isHeader,
      children: row.columns.map(col => new TableCell({
        columnSpan: col.colSpan || 1,
        rowSpan: col.rowSpan || 1,
        children: [new Paragraph({
          alignment: AlignmentType.CENTER,
          text: col.content
        })]
      }))
    })
  }

  static __renderQA(qaItem){
    const title = new Paragraph({
      heading: HeadingLevel.HEADING_3,
      alignment: AlignmentType.LEFT,
      text: qaItem.title
    })

    const lineBreak = new Paragraph('')
    const answer = new Paragraph({
      indent: {
        start: 10
      },
      text: qaItem.data || '-'
    })
    return [title, answer, lineBreak]
  }

  static __renderQuestionnaire(qItem) {
    if (!qItem) {
      return null;
    }
    console.log('QN', qItem)
    return qItem.data.groups.map(group => {
      const title = new Paragraph({
        heading: HeadingLevel.HEADING_3,
        alignment: AlignmentType.LEFT,
        text: group.title
      })

      const data = group.questions.map((q, index) => {
        const title = new Paragraph({
          heading: HeadingLevel.HEADING_4,
          alignment: AlignmentType.LEFT,
          text: `${index + 1}. ${q.text} (${q.code})`
        })

        const lineBreak = new Paragraph('')
        const choice = q.choices.find(c => c.value === q.answer)
        const answer = new Paragraph({
          text: choice ? choice.text : '-'
        })
        return [title, answer, lineBreak]
      }).flat()
      const lineBreak = new Paragraph('')
      return [title, lineBreak, ...data]
    }).flat()
  }

}
