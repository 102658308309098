import React from 'react'
import { Box, Button, Modal, Stack, Typography, styled } from '@mui/material'

const StyledModalContainer = styled(Box)(({ theme }) => ({
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: '430px',
    borderRadius: ' 12px',
    backgroundColor: '#FFF',
    boxShadow: '0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
    padding: '40px'
}));

export default function DevelopmentAndManagementDeleteModal({ deleteModal, toggleDeleteModal, deletePhoto, selectedPhoto }) {
    return (
        <Modal
            open={deleteModal}
            onClose={toggleDeleteModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <StyledModalContainer>
                <Typography textAlign={'center'} fontSize={'20px'} fontWeight={'700'}>
                    Are you sure you want to delete image?
                </Typography>
                <Stack marginTop={3} spacing={2} direction={'row'} justifyContent={'center'}>
                    <Button sx={{ paddingX: '2rem' }} onClick={toggleDeleteModal} type="submit" variant="outlined" >
                        No
                    </Button>

                    <Button sx={{ paddingX: '2rem' }} onClick={() => deletePhoto(selectedPhoto)} type="submit" variant="contained" >
                        Yes
                    </Button>
                </Stack>
            </StyledModalContainer>
        </Modal>
    )
}
