
const ethnicityPieChartData = {
    type: 'donut',
    height: 360,
    options: {
      chart: {
        type: 'donut',
        width:350
      },
      plotOptions: {
        pie:{
          donut: {
            size: '60%'
          }
        }
      },
      labels: ['Indigenous', 'Dalit', 'Bramin/Kshetri/Thakuri/Dasnami', 'Madhesi', 'Muslim', 'Others'],
      /*eslint-disable*/
     legend:{
        formatter: function(val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex]
      },
      position: 'bottom'
    },
    responsive: [{
        breakpoint: 400,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          },
          dataLabels: {
            enabled: false
          },
        }
      }]
    },
};

const populationPieChartData = {
  type: 'donut',
  height: 430,
  options: {
      chart: {
        type: 'donut',
        dropShadow: {
          enabled: true,
          color: '#111',
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2
        }
      },
      stroke: {
        width: 0,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      // labels: ["Male", "Female", "Other"],
      legend: {
        formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex] 
          },
        position: 'bottom'
      },
      dataLabels: {
        enabled: false
      },
      states: {
        hover: {
          filter: 'none'
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  };

const growingStocksColumnChartdata = {
    height: 315,
    type: 'bar',
    options: {
      chart: {
        id: 'bar-chart',
        // toolbar: {
        //   show: true
        // },
        // zoom: {
        //   enabled: true
        // }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%'
        }
      },
      xaxis: {
        type: 'category',
        categories: ['Sal', 'Sallo', 'Khayar']
      },
      yaxis: {
        title: {
          text: 'ft3'
        },
        labels: {
          enabled: false,
          formatter: (val) => {
            return val / 1000000 + 'M'
          }
        }
      },
      // legend: {
      //   show: false,
      //   fontSize: '14px',
      //   fontFamily: `'Roboto', sans-serif`,
      //   position: 'bottom',
      //   // offsetX: 1,
      //   labels: {
      //     useSeriesColors: false
      //   },
      //   markers: {
      //     width: 16,
      //     height: 16,
      //     radius: 5
      //   },
      //   // itemMargin: {
      //   //   horizontal: 15,
      //   //   vertical: 8
      //   // }
      // // },
      // fill: {
      //   type: 'solid'
      // },
      dataLabels: {
        formatter: (val) => {
          return val / 1000 + 'K'
        }
        // enabled: false
      }
    },
  };

  const mappingRightsColumnChartdata = {
    height: 315,
    type: 'bar',
    options: {
      chart: {
        id: 'bar-chart-2',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          distributed: true,
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        type: 'category',
        categories: ['Sal', 'Sallo', 'Khayar']
      },
      colors: [
        '#FEB019', '#00E396', '#FF4560', '#775DD0', '#546E7A',
        '#26a69a', '#D10CE8', '#6610F2', '#FF9F00', '#00D084',
        '#6E757C', '#7A36DA', '#4D5AFF', '#ED5BFF', '#8E7CFA'
      ],
      yaxis: {
        title: {
          text: 'value(%)'
        },
        labels: {
          enabled: false,
          formatter: (val) => {
            return val + '%'
          }
        }
      },
      dataLabels: {
        // formatter: (val) => {
        //   return val / 1000 + 'K'
        // }
        // enabled: false
      }
    },
  };
 

export {
  populationPieChartData, 
  ethnicityPieChartData, 
  growingStocksColumnChartdata,
  mappingRightsColumnChartdata

}