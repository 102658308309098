// hooks
import * as React from 'react';
import { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Link, Popper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useIsMountedRef from 'hooks/useIsMountedRef';
// utils
// routes
// components
import Iconify from 'components/Iconify';
import InputStyle from 'components/InputStyle';
import SearchNotFound from 'components/SearchNotFound';

// ----------------------------------------------------------------------

const PopperStyle = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

// ----------------------------------------------------------------------

QuickSearch.propTypes = {
  regions: PropTypes.array,
  setRegions: PropTypes.func,
};

export default function QuickSearch({ regions }) {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const handleChangeSearch = async (value) => {
    try {
      setSearchQuery(value);
      if (value) {
        const values = regions.filter((region) => region.name.toLowerCase().includes(value.toLowerCase()));
        if (isMountedRef.current) {
          setSearchResults(values);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (id) => {
    navigate(`${id}`);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleClick(searchQuery);
    }
  };

  return (
    <Autocomplete
      size="small"
      autoHighlight
      popupIcon={null}
      PopperComponent={PopperStyle}
      options={searchResults}
      onInputChange={(event, value) => handleChangeSearch(value)}
      getOptionLabel={(product) => product.name}
      noOptionsText={<SearchNotFound searchQuery={searchQuery} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <InputStyle
          {...params}
          stretchStart={200}
          placeholder="Search ..."
          onKeyUp={handleKeyUp}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, region, { inputValue }) => {
        const { id, name } = region;
        const matches = match(name, inputValue);
        const parts = parse(name, matches);

        return (
          <li {...props}>
            <Iconify icon={'openmoji:authority-building'} sx={{ width: 44, height: 44, mt: '3px' }} />
            <Link underline="none" onClick={() => handleClick(id)}>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  component="span"
                  variant="subtitle2"
                  color={part.highlight ? 'primary' : 'textPrimary'}
                >
                  {part.text}
                </Typography>
              ))}
            </Link>
          </li>
        );
      }}
    />
  );
}
