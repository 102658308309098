import React from 'react';
import { t } from 'i18next';
import {
  Button,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { MultipleSelect, RHFTextField } from 'components/hook-form';
import SpecieButton from './SpecieButton';

const WoodSaleDetail = ({ PLANTS = [], values, fetchSpecies }) => (
  <>
    <Typography variant={'h6'} padding={1}>
      3. Sales and Distribution of Timber by CF
    </Typography>
    <TableContainer
      sx={{
        my: 1,
      }}
    >
      <Table>
        <TableHead>
          <TableCell>Types</TableCell>
          <TableCell
            sx={{
              maxWidth: 300,
            }}
          >
            Quantity (q. feet)
          </TableCell>
        </TableHead>
        <TableBody>
          {salesType.map((wc, i) => (
            <TableRow key={i}>
              <TableCell
                sx={{
                  minWidth: 250,
                }}
              >
                {wc.name}
              </TableCell>
              <TableCell>
                {wc.id === 'mainSpecies' ? (
                  <Stack direction={{ xs: 'column', sm: 'row' }}>
                      {/* <InputLabel>Plant Species</InputLabel> */}
                    <MultipleSelect
                      name="woodSales.mainSpeciesIds"
                      label="Plant Species"
                      value={values.woodSales?.mainSpeciesIds}
                      defaultvalues={[]}
                    >
                      <option />

                      {PLANTS?.map(({ id: value, name: label }, idx) => (
                        <MenuItem key={`program-dropdown-${idx}`} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </MultipleSelect>
                    <SpecieButton fetchSpecies={fetchSpecies} />
                  </Stack>
                ) : (
                  <RHFTextField
                    label={t('Quantity')}
                    fullWidth
                    name={`woodSales.${wc.id}`}
                    type="number"
                    min={0}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography color={'text.secondary'}>
                            ft<sup>3</sup>
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
export default WoodSaleDetail;

const salesSpecies = [{ id: 1, name: 'Test' }];

const salesType = [
  {
    id: 'internalDistribution',
    name: 'Internal Distribution (Grant)',
  },
  {
    id: 'internalSales',
    name: 'Internal Sales',
  },
  {
    id: 'externalSales',
    name: 'External Sales',
  },
  {
    id: 'mainSpecies',
    name: 'Main Species',
  },
];
