import {
  Button, Card,
  CardContent, Checkbox, CircularProgress,
  Container, Divider, FormControlLabel, Grid,
  Icon,
  Stack,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination, TableRow, TextField, Typography,
} from '@mui/material';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import * as React from 'react';
import { useEffect, useState } from 'react';
import APIServices from '../../apiUtils/APIServices';
import TableView from '../analytics/components/TableView';
import SearchField from '../../components/Search';
import Backdrop from '@mui/material/Backdrop';
import { Link } from 'react-router-dom';
import Iconify from 'components/Iconify';
import { objectToQueryString } from 'utils/string.util';

const AllCf = () => {
  const [forests, setForests] = useState([])
  const [size, setSize] = useState(10)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [query,setQuery] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const params = {query, page, size}
    const paramQuery = objectToQueryString(params)
    const {data, success} = await new APIServices(`browse/forests?${paramQuery}`).get(params)
    setLoading(false)
    if (success){
      setForests(data?.data || [])
      setTotal(data?.total)
    }
  }



  useEffect(() => {
    fetchData()
  }, [size, page, query])

  
  return (
    <Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction={'column'} spacing={2}>
        <HeaderBreadcrumbs
          heading="All Communicty Forest"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.app },
            { name: 'Search', href: PATH_DASHBOARD.search.root },
          ]}
        />

        <Grid item xs={3}>
          <TextField
            style={{width: '100%'}}
            variant={'outlined'}
            placeholder={'Search'}
            onChange={({ target }) => {
              setQuery(target.value)
              if (target.value && target.value.length >= 3){
                fetchData()
              }
            }
            }
          />
        </Grid>

        <TableContainer component={'div'}>
          <Table
            sx={{minWidth: 750}}>
            <TableHead>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>CF Code </TableCell>
              <TableCell>Local Body</TableCell>
              <TableCell>Customer Forest</TableCell>
              <TableCell>Verified</TableCell>
              <TableCell>Publlished</TableCell>
              <TableCell width={150}>Action</TableCell>
            </TableHead>
            <TableBody>
              {
                forests.map((forest,index) => (
                  <TableRow key={index}>
                    <TableCell>{page * size + (index + 1) }</TableCell>
                    <TableCell>{forest.name}</TableCell>
                    <TableCell>{forest.cfugCode}</TableCell>
                    <TableCell>{forest.localRegion.name}({forest.localRegion.npName})</TableCell>
                    <TableCell>{
                    forest.isCustomary?
                    <Iconify icon="simple-line-icons:check" color="#33A961" width="30" height="30" /> :
                    <Iconify icon="carbon:close-outline" width="30" height="30" />
}
                    </TableCell>
                    <TableCell>{forest.verified ?
                    <Iconify icon="simple-line-icons:check" color="#33A961" width="30" height="30" /> :
                    <Iconify icon="carbon:close-outline" width="30" height="30" />
}
                    </TableCell>
                    <TableCell>{forest.published?
                    <Iconify icon="simple-line-icons:check" color="#33A961" width="30" height="30" /> : 
                    <Iconify icon="carbon:close-outline"  width="30" height="30" />
}
                    </TableCell>
                    <TableCell width={150}>
                      <Link to={`/dashboard/areas/analytics/forest/${forest.id}?name=${forest.name}`}> 
                      Analytics
                      {/* <Iconify icon="clarity:analytics-line" width="30" height="30" /> */}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 500]}
          component="div"
          count={total}
          rowsPerPage={size}
          page={page}
          onPageChange={(_, page) => {
            setPage(page );
            fetchData()
          }}
          onRowsPerPageChange={({target}) => {
            setSize(target.value);
            fetchData()
          }}
        />
      </Stack>
    </Container>
  )
}

export default AllCf
