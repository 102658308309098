import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLVectorTile from "ol/layer/VectorTile";

const VectorTileLayer = ({ source, style, zIndex = 0 }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) return;

        const vectorTileLayer = new OLVectorTile({
            source,
            style
        });

        map.addLayer(vectorTileLayer);
        vectorTileLayer.setZIndex(zIndex);

        return () => {
            if (map) {
                map.removeLayer(vectorTileLayer);
            }
        };
    }, [map]);

    return null;
};

export default VectorTileLayer;