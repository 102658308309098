import { PersonOutline } from '@mui/icons-material'
import { Avatar, Box, Button, Grid, Stack, Typography, styled, useTheme } from '@mui/material'
import React from 'react'
import { fShortMonthDate } from 'utils/formatTime'
import { getImgSrc } from 'utils/getImgSrc'
import { stringAvatar } from 'utils/string.util'

const NewsAndArticleCard = styled(Box)(({ theme }) => ({
    borderRadius: "8px",
    backgroundColor: 'white',
    width: '596px',
    'img': {
        width: "100%",
        height: '368px',
        zIndex: '100',
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    '.MuiBox-root': {
        borderRadius: "8px",
        padding: '24px',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',

    }
}))

export default function MainBlog({post}) {

    const theme = useTheme()
    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 2, md: 4 }}>
            <NewsAndArticleCard>
                {/* <img src='/static/img/main-blog.jpeg' alt='iuagsduyg' /> */}
                <img src={getImgSrc(post?.coverImage)} alt={post?.slug} sx={{ height: 0.5, borderRadius: 1.5 }} />
                <Box>
                    <Stack spacing={2} direction="row" justifyContent="left" alignItems="flex-start" sx={{ mb: '2%' }}>
                        <Stack>
                        <Avatar
                            alt={post?.authorName}
                            // sx={{ height: 20, width: 20 }}
                            sx={{ position: 'absolute', top: 10, right: 10, zIndex: 9, height: 14, width: 14 }}
                            {...stringAvatar(post?.authorName)}
                        />
                        </Stack>
                        <Stack sx={{ minWidth: 0 }}>
                        <Typography
                            fontSize={'14px'}
                            fontWeight={'400'}
                            color={theme.palette.grey[600]}
                            display={'flex'}
                            gap={2}
                            alignItems={'center'}
                            sx={{marginTop:'1%', paddingLeft: '1rem'}}
                        >
                            {post?.authorName} &nbsp; | &nbsp; {fShortMonthDate(post?.createdOn)}
                        </Typography>
                        </Stack>
                    </Stack>

                    <Typography fontSize={'20px'} fontWeight={'600'} marginY={1} letterSpacing={0}>
                    {post.title}
                    </Typography>
                    <Typography fontSize={'20px'} marginY={2} letterSpacing={0}  dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                    <span>
                        <Button variant="contained" rel="noopener" sx={{ padding: ".7rem 2rem" }} href={`/blog/${post?.slug}`}>
                            Read More
                        </Button>
                    </span>
                </Box>
            </NewsAndArticleCard>
        </Grid>
    )
}
