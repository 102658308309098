import { Box, Button, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import React from 'react'

export default function DonateHeading() {

    const theme = useTheme()

    return (
      <Box
        paddingX={{ xs: '30px', sm: '10px', lg: '112px' }}
        sx={{ pt: '48px', pb: '40px' }}
        bgcolor={theme.palette.primary[50]}
      >
        <Typography component={'h2'} fontWeight={'600'} fontSize={'38px'} textAlign={'center'}>
          Donate to CFDP
        </Typography>
        <Box paddingX={{ xs: 2, md: 23 }} marginY={2}>
          <Typography fontSize={'16px'} textAlign={'center'}>
            Lorem ipsum dolor sit amet consectetur. Lacus condimentum nisi aliquet sed iaculis pharetra. Tortor sed sed
            lorem facilisis risus morbi lectus congue id. Commodo eleifend laoreet aenean dolor vulputate massa. Etiam
            et lorem justo dui in at tincidunt integer aliquam. Elementum egestas amet non interdum pretium ullamcorper
            vestibulum. Et risus elementum magnis diam turpis proin dolor bibendum.
          </Typography>
        </Box>

        {/* <Stack direction={'row'} marginX={'auto'} marginTop={'32px'} spacing={4} alignItems={'center'} width={'620px'}>
          <ToggleButtonGroup color="primary"  aria-label="Platform" >
            <ToggleButton value="web">Web</ToggleButton>
            <ToggleButton value="android">Android</ToggleButton>
            <ToggleButton value="ios">iOS</ToggleButton>
          </ToggleButtonGroup>
        </Stack> */}
      </Box>
    );
}
