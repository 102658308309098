import { Grid } from '@mui/material'
import React from 'react'
// import StartCardDark from './StartCardDark'

import TotalIncomeDarkCard from '../../../../../pages/explore/Components/TotalIncomeDarkCard';

function WetHumanStock({isLoading, wetLandData, humanData, stockData}) {
  return (
    <Grid container  style={{marginTop: '5px'}} spacing={{ xs: 2, md: 5 }} rowSpacing={5}> {/* Wet/Human/Growing */}
    <Grid item xs={12} sm={6} md={4}>
        <TotalIncomeDarkCard isLoading={isLoading}  title={`Wetlands`} icon={ {
        "title": "wetland",
        "iconName":"maki:wetland",
        "color":"#70C290",
        "size": ""

    }} value={wetLandData} bgColor={'#E6F4EB'} textColor={`#000`}  />
    </Grid>
    <Grid item xs={12} sm={6} md={4} >
        <TotalIncomeDarkCard isLoading={isLoading} title={`Human/Wildlife Conflicts`} icon={{
        "title": "human-wildlife-conflicts",
        "iconName":"teenyicons:paws-outline",
        "color":"#ccd7f5",
        "size": ""

    }} value={humanData} bgColor={`#ccd7f5`} textColor={`#000`} />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
        <TotalIncomeDarkCard isLoading={isLoading} title={`Growing stocks of Firewood`} icon={{
        "title": "firewood",
        "iconName":"game-icons:log",
        "color":"#efbe5e",
        "size": ""

    }} value={stockData} bgColor={'#f3dfb8'} textColor={`#000`} />
    </Grid>
</Grid>
  )
}

export default WetHumanStock