import { Link as RouterLink, useParams } from 'react-router-dom';
import * as React from 'react';
import { Button } from '@mui/material';
import useAuth from 'hooks/useAuth';
import Page from 'components/Page';
import RegionCover from '../dataRegions/region/RegionCover';
import RegionTaskOverview from './RegionTaskOverview';
import { PATH_DASHBOARD } from 'routes/paths';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import ReportsList from '../dataRegions/region/ReportsList';

export default function AssignedRegion() {
  const params = useParams();
  const { user } = useAuth();
  const region = user?.regions.find((region) => region.id === parseInt(params.rId, 10));
  const { name } = region;
  return (
    <Page title="Tasks">
      <HeaderBreadcrumbs
        heading="Assigned Regions"
        links={[{ name: 'Tasks', href: PATH_DASHBOARD.tasks.root }, { name: 'Assigned Regions' }, { name }]}
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to={`${PATH_DASHBOARD.tasks.root}/${params.rId}/new-report/data-collection`}
            startIcon={<Iconify icon={'eva:file-text-fill'} />}
          >
            Report CF.
          </Button>
        }
      />
      <RegionCover name={name} consortiumPartner={user?.partner} icon={'openmoji:authority-building'} />
      <RegionTaskOverview />
      <br />
      <ReportsList />
    </Page>
  );
}
