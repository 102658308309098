import DashContainer from './DashContainer';
import {
    PieChart,
    Pie,
    Tooltip,
    Cell,
    Legend
} from "recharts";

const DashPieChart = ({ title, data, ...props }) => {

    const COLORS = ["#52D726", "#FFEC00", "#FF7300", "#FF0000", " #007ED6", " #7CDDDD"]
    const customData = Object.entries(data)
      .filter(val => val[1] && !Number.isNaN(val[1]))
      .map((val, i) => {
        const foo = {};
        foo.name = val[0];
        foo.value = Math.round(val[1]*100)/100;
        return foo;
    });

    return (
        <DashContainer title={title} {...props}>
            <PieChart width={250} height={350}>
                <Pie
                    data={customData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    innerRadius={50}
                    fill="#8884d8"
                    dataKey="value"
                    label
                >
                    {customData.map((entry, index) => {
                        return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    })}
                </Pie>
                <Tooltip />
                <Legend layout="vertical" verticalAlign="bottom" align="center" formatter={(value, entry, index) => <span style={{color: 'black'}}>{value}</span>} />
            </PieChart>
        </DashContainer>
    );
};

export default DashPieChart;
