// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  partners: '/partners',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),
  user: {
    root: path(ROOTS_DASHBOARD, '/profile'),
    edit: path(ROOTS_DASHBOARD, '/profile/edit'),
  },
  dataRegions: {
    root: path(ROOTS_DASHBOARD, '/data-regions'),
    regionOverview: path(ROOTS_DASHBOARD, '/data-regions/:rId/overview'),
    regionReporters: path(ROOTS_DASHBOARD, '/data-regions/:rId/reporters'),
    addCF: path(ROOTS_DASHBOARD, '/data-regions/:rId/add-cf'),
    assignedRegions: path(ROOTS_DASHBOARD, '/data-regions/assigned'),
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users/overview'),
    operators: path(ROOTS_DASHBOARD, '/users/operators'),
    reporters: path(ROOTS_DASHBOARD, '/users/reporters'),
    index: path(ROOTS_DASHBOARD, '/users'),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    viewDetails: path(ROOTS_DASHBOARD, '/view-report')
  },
  tasks: {
    root: path(ROOTS_DASHBOARD, '/tasks'),
    newReport: path(ROOTS_DASHBOARD, '/tasks/:rId/new-report'),
    newDataCollectionReport: path(ROOTS_DASHBOARD, '/tasks/:rId/new-report/data-collection'),
  },
  search: {
    root: path(ROOTS_DASHBOARD, '/search')
  },
  areas: {
    root: path(ROOTS_DASHBOARD, '/areas/municipal'),
    allCf: path(ROOTS_DASHBOARD, '/areas/analytics/forest/all'),
    partners: path(ROOTS_DASHBOARD, '/areas/partners'),
    indCf: path(ROOTS_DASHBOARD, '/areas/cf')
  },
  blogs: {
    root: path(ROOTS_DASHBOARD, '/blogs')
  },
  events: {
    root: path(ROOTS_DASHBOARD, '/events')
  },
  dataCollection: {
    root: path(ROOTS_DASHBOARD, '/data-collection'),
    dropdown: {
      species: path(ROOTS_DASHBOARD, '/species'),
    },
    general: {
      root: path(ROOTS_DASHBOARD, '/general'),
      dm: path(ROOTS_DASHBOARD, '/dm'),
      bnpi: path(ROOTS_DASHBOARD, '/bnpi'),
      ppcd: path(ROOTS_DASHBOARD, '/ppcd'),
      iggi: path(ROOTS_DASHBOARD, '/iggi'),
      cm: path(ROOTS_DASHBOARD, '/cm'),
    },
    questionnaire: {
      root: path(ROOTS_DASHBOARD, '/questionnaire'),
      rightsMapping: path(ROOTS_DASHBOARD, '/questionnaire/rights-mapping'),
    },
    enterprises: {
      root: path(ROOTS_DASHBOARD, '/data-collection/GeneralDataCollectionForm/enterprises'),
    },
  },
  cf: {
    root: path(ROOTS_DASHBOARD, '/cf/:cfId'),
    cf: path(ROOTS_DASHBOARD, '/data-regions/cf'),
  },
  forms: {
    root: path(ROOTS_DASHBOARD, '/forms'),
    newCF: path(ROOTS_DASHBOARD, '/forms/cf'),
    dm: path(ROOTS_DASHBOARD, '/forms/dm'),
    bnpi: path(ROOTS_DASHBOARD, '/forms/bnpi'),
    ppcd: path(ROOTS_DASHBOARD, '/forms/ppcd'),
    iggi: path(ROOTS_DASHBOARD, '/forms/iggi'),
    cm: path(ROOTS_DASHBOARD, '/forms/cm'),
    rights: path(ROOTS_DASHBOARD, '/forms/rights'),
    governance: path(ROOTS_DASHBOARD, '/forms/governance'),
  },
};
