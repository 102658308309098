import { styled } from '@mui/material/styles';
import './styles.css';

import Page from 'components/Page';
import React, { useEffect, useState } from 'react';

import { Card, Checkbox, FormControlLabel, FormGroup, IconButton, Slide, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LayersIcon from '@mui/icons-material/Layers';

import Map from 'components/openlayer/Map';
import VectorTileLayer from 'components/openlayer/Layers/VectorTileLayer';
import { ImageLayer, Layers, TileLayer, VectorLayer } from 'components/openlayer/Layers';
import { Controls, FullScreenControl, ZoomControl } from 'components/openlayer/Controls';
import * as olSource from 'ol/source';
import { MVT } from 'ol/format';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Fill, Stroke, Style, Text } from 'ol/style';

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  minWidth: 600,
  minHeight: 600,
  width: '100%',
}));
const forestBorderColor = (status) => {
  if (status === 'APPROVED' || status === 'PUBLISHED') {
    return '#00AB55';
  }
  if (status === 'DRAFT' || status === 'UNDER_REVIEW') {
    return '#6495ED';
  }
  return '#E0115F';
};

const forestFillColor = (status) => {
  if (status === 'APPROVED' || status === 'PUBLISHED') {
    return '#00AB55AA';
  }
  if (status === 'DRAFT' || status === 'UNDER_REVIEW') {
    return '#6495EDAA';
  }
  return '#E0115FAA';
};

// const vectorStyle =
const Gis = ({ title, mapVisualization }) => {
  const [center, setCenter] = React.useState([85.2911132, 27.7089559]);
  const [zoom, setZoom] = React.useState(6);
  const [showLayerSwitcher, setShowLayerSwitcher] = React.useState(false);
  const [layers, setLayers] = useState([]);

  const getStyle = (feature, styleData) => {
    return new Style({
      stroke: new Stroke({
        color: styleData?.strokeColor ?? forestBorderColor(feature.get('status')),
        width: styleData?.borderWidth ?? 1,
        lineDash: styleData?.dashedBorder ? [6, 12] : undefined,
      }),
      fill: new Fill({
        color: styleData?.fillColor ?? forestFillColor(feature.get('status')),
      }),
      text: new Text({
        font: styleData?.font ?? '14px Calibri,sans-serif',
        text: styleData?.text ? feature.get(styleData.text) : feature.get('name'),
        fill: new Fill({
          color: styleData?.fontColor ?? 'white',
        }),
      }),
    });
  };

  const getForest = (evt) => {
    const features = evt.map.getFeaturesAtPixel(evt.pixel);
    if (features.length === 0) {
      return undefined;
    }
    const feature = features.find(feat => feat.get('local_region_id'));
    if (feature) {
      // console.log(feature)
      // setHoveredForest(feature)
    }
    return feature;
  };

  const onSingleClick = (evt) => {
    const features = evt.map.getFeaturesAtPixel(evt.pixel);
    if (features.length === 0) {
      return;
    }
    const forestFeature = features.find(feat => feat.get('local_region_id'));
    if (!forestFeature) {
      const feature = features.find(feat => feat.getGeometry());
      if (feature && !feature.get('ignoreClick')) {
        evt.map.getView().fit(feature.getGeometry().getExtent(), evt.map.getSize());
      }
    } else {
      window.location.href = `/cf/dashboard/${forestFeature.get('forest_id')}?type=FOREST`;
      evt.preventDefault();
    }
  };


  const osm = (options = {}) => {
    return new olSource.OSM(options);
  };

  useEffect(() => {
    let _layers = [];

    if (mapVisualization) {
      _layers = mapVisualization?.data?.staticLayers;
      _layers = _layers.concat(mapVisualization?.data?.dynamicLayers);

      const tempLayer = _layers.map((layer, index) => {
        const layerObj = {};
        const layerUrl = new URL(
          layer.url ||
          `${process.env.REACT_APP_GEOSERVER_BASE}/wms?service=WMS&version=1.1.0&request=GetMap&bbox=86.0%2C27.0%2C87.0%2C28.0&width=768&height=768&srs=EPSG%3A4326&styles=&format=application/openlayers`,
        );
        // add layer param
        layerUrl.searchParams.set('layers', layer?.name);
        /* if (mapVisualization.bbox != null) {
            layerUrl.searchParams.set("bbox", mapVisualization.bbox.string)
        } */
        layerUrl.searchParams.set('CRS', 'EPSG:4326');
        // add viewparam if exists
        if (layer?.params && Object.prototype.hasOwnProperty.call(layer?.params, 'viewparams')) {
          layerUrl.searchParams.set('viewParams', layer?.params?.viewparams);
        }

        layerObj.id = index + 1;
        layerObj.name = layer.title;
        layerObj.type = layer.type;
        layerObj.toggle = layer.toggle;
        layerObj.source = layer.type === 'Vector' ? 'VECTOR' : 'IMAGE_WMS';
        layerObj.url = layerUrl;
        layerObj.maxZoom = layer.maxZoom;
        layerObj.minZoom = layer.minZoom;

        layerObj.visible = layer.visible;
        layerObj.style = layer.style;
        return layerObj;
      });
      setLayers(tempLayer);
    }
  }, [mapVisualization]);

  const getFormat = (format) => {
    switch (format) {
      case 'MVT':
        return new MVT();
      default:
        return null;
    }
  };

  const getSource = (data) => {
    const option = {};

    if (Object.prototype.hasOwnProperty.call(data, 'format')) {
      option.format = getFormat(data.format);
    }
    if (Object.prototype.hasOwnProperty.call(data, 'url')) {
      option.url = data.url;
    }

    switch (data.source) {
      case 'OSM':
        return new olSource.OSM({ ...option });
      case 'VECTOR_TILE':
        return new olSource.VectorTile({ ...option });
      case 'XYZ':
        return new olSource.XYZ({ ...option });
      case 'TILE_JSON':
        return new olSource.TileJSON({ ...option });
      case 'TILE_WMS':
        return new olSource.TileWMS({ ...option });
      case 'IMAGE_WMS': {
        const layerUrl = new URL(option.url);
        option.url = `${layerUrl.protocol}//${layerUrl.hostname}${layerUrl.pathname}`;

        return new olSource.ImageWMS({
          ...option,
          ratio: 1,
          serverType: 'geoserver',
          params: {
            layers: layerUrl.searchParams.get('layers'),
            viewparams: layerUrl.searchParams.get('viewParams'),
          },
        });
      }
      case 'VECTOR': {
        return new VectorSource({
          format: new GeoJSON(),
          url: data.url,
        });
      }
      default:
        return null;
    }
  };

  const getLayer = (layer, index) => {
    const source = getSource(layer);
    const zIndex = (index + 1) * 10;

    if (!layer.visible) {
      return null;
    }
    switch (layer.type) {
      case 'Tile':
        return <TileLayer key={index} source={source} zIndex={zIndex} />;
      case 'VectorTile':
        return <VectorTileLayer key={index} source={source} zIndex={zIndex} />;
      case 'Image':
        return <ImageLayer key={index} source={source} zIndex={zIndex} />;
      case 'Vector':
        return <VectorLayer key={index} source={source} zIndex={zIndex} maxZoom={layer.maxZoom} minZoom={layer.minZoom}
                            style={(feature) => getStyle(feature, layer.style)} />;
      default:
        return null;
    }
  };

  return (
    <Page sx={{ width: '100%', height: '100%' }} title='GIS Map'>
      <Stack sx={{ width: '100%', height: '100%', position: 'relative' }} direction='row'>
        <Map zoom={zoom} onMapSingleClick={onSingleClick}
             bbox={mapVisualization && mapVisualization.bbox ? mapVisualization.bbox : undefined}
             padding={mapVisualization?.padding ?? undefined} getHoveredFeature={getForest}>
          <Layers>
            <TileLayer source={new olSource.OSM()} />
            {layers.map((layer, index) => {
              return getLayer(layer, index);
            })}
          </Layers>
          <Controls>
            <FullScreenControl />
            <ZoomControl />
          </Controls>
        </Map>
        <Stack direction='column' sx={{ position: 'absolute', top: 45, right: '1em', backgroundColor: 'white' }}>
          {/* <IconButton
            size="small"
            onClick={() => {
              setShowLayerSwitcher(!showLayerSwitcher);
            }}
          >
            <LayersIcon fontSize="small" />
          </IconButton> */}
        </Stack>
        <Slide direction='left' in={showLayerSwitcher} mountOnEnter unmountOnExit>
          <Card
            sx={{ position: 'absolute', width: 200, height: '98%', right: 5, top: 5, borderRadius: 0 }}
            variant='outlined'
          >
            <IconButton
              aria-label='close'
              onClick={() => {
                setShowLayerSwitcher(!showLayerSwitcher);
              }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
            <Stack direction='column' paddingX={1.5}>
              <Typography>Layers</Typography>

              <FormGroup>
                {layers.map((layer, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={layer.visible}
                          disabled={!layer.toggle}
                          onChange={() => {
                            const tempLayers = layers.map((l, i) => {
                              if (i === index) {
                                l.visible = !l.visible;
                              }
                              // else {
                              //     l.visible = false;
                              // }
                              return l;
                            });
                            setLayers([...tempLayers]);
                          }}
                        />
                      }
                      label={layer.name}
                    />
                  );
                })}
              </FormGroup>
            </Stack>
          </Card>
        </Slide>
      </Stack>
    </Page>
  );
};

export default Gis;

/**
 * name: Layer name
 * layer: TILE_LAYER, VECTOR_TILE_LAYER, VECTOR_LAYER
 * source: XYZ, OSM, STAMEN, VECTOR_TILE, MVT
 * url: String
 */

// const mapData = [
// {
//     id: 3,
//     name: "Country",
//     type: "IMAGE_LAYER",
//     source: "IMAGE_WMS",
//     url: "http://node5974-cfproject.ktm.yetiappcloud.com/cfportal/wms?service=WMS&version=1.1.0&request=GetMap&layers=cfportal%3Acountry&bbox=80.060147827%2C26.3474236440001%2C88.2042985210001%2C30.4731109750001&width=768&height=389&srs=EPSG%3A4326&styles=&format=application/openlayers"
// },
// {
//     id: 4,
//     name: "Province",
//     type: "IMAGE_LAYER",
//     source: "IMAGE_WMS",
//     url: "http://node5974-cfproject.ktm.yetiappcloud.com/cfportal/wms?service=WMS&version=1.1.0&request=GetMap&layers=cfportal%3Aprovince&bbox=80.060147827%2C26.3474236440001%2C88.2042985210001%2C30.4731109750001&width=768&height=389&srs=EPSG%3A4326&styles=&format=application/openlayers"
// },
// {
//     id: 5,
//     name: "District",
//     type: "IMAGE_LAYER",
//     source: "IMAGE_WMS",
//     url: "http://node5974-cfproject.ktm.yetiappcloud.com/cfportal/wms?service=WMS&version=1.1.0&request=GetMap&layers=cfportal%3Adistrict&bbox=80.060147827%2C26.3474236440001%2C88.2042985210001%2C30.4731109750001&width=768&height=389&srs=EPSG%3A4326&styles=&format=application/openlayers"
// },
// {
//     id: 6,
//     name: "Local Region",
//     type: "IMAGE_LAYER",
//     source: "IMAGE_WMS",
//     url: "http://node5974-cfproject.ktm.yetiappcloud.com/cfportal/wms?service=WMS&version=1.1.0&request=GetMap&layers=cfportal%3Alocal_region&bbox=80.060147827%2C26.3474236440001%2C88.2042985210001%2C30.4731109750001&width=768&height=389&srs=EPSG%3A4326&styles=&format=application/openlayers"
// },
// {
//     id: 7,
//     name: "Reports",
//     type: "IMAGE_LAYER",
//     source: "IMAGE_WMS",
//     url: "http://node5974-cfproject.ktm.yetiappcloud.com/cfportal/wms?service=WMS&version=1.1.0&request=GetMap&layers=cfportal%3Areports&bbox=86.0%2C27.0%2C87.0%2C28.0&width=768&height=768&srs=EPSG%3A4326&styles=&format=application/openlayers"
// },
// {
//     id: 8,
//     name: "Special Area",
//     type: "IMAGE_LAYER",
//     source: "IMAGE_WMS",
//     url: "http://node5974-cfproject.ktm.yetiappcloud.com/cfportal/wms?service=WMS&version=1.1.0&request=GetMap&layers=cfportal%3Aspecial_area&bbox=80.0990443260001%2C26.567273893%2C87.0779657690001%2C29.465930771&width=768&height=330&srs=EPSG%3A4326&styles=&format=application/openlayers"
// },

const mapData = [
  {
    name: 'cfportal:country',
    title: 'Nepal',
    params: {},
    type: 'Tile',
    subType: null,
    url: null,
  },
  {
    name: 'cfportal:province',
    title: 'Sudur Paschimanchal',
    params: {
      viewparams: 'id:7',
    },
    type: 'Tile',
    subType: null,
    url: null,
  },
  {
    name: 'cfportal:district',
    title: 'Doti',
    params: {
      viewparams: 'id:73',
    },
    type: 'Tile',
    subType: null,
    url: null,
  },
  {
    name: 'cfportal:local_region',
    title: 'Dipayal Silgadhi Municipality',
    params: {
      viewparams: 'id:7',
    },
    type: 'Tile',
    subType: null,
    url: null,
  },
  {
    name: 'cfportal:forest',
    title: 'Dipayal Silgadhi Municipality',
    params: {
      viewparams: 'id:299744',
    },
    type: 'Tile',
    subType: null,
    url: null,
  },
];
