import DashContainer from './DashContainer';
import {
  ListItem,
  List,
  ListItemText,
} from "@mui/material";
import { Link } from 'react-router-dom';

const DashLinkedList = ({ title, data, ...props }) => {
  if (data.length <= 0) {
    return null;
  }

  return (
    <DashContainer title={title} {...props}>
      <List style={{ minWidth: 200, maxHeight: 300, overflow: "auto" }}>
        {data.map((item, index) => {
          return (
            <ListItem key={index}>
              <ListItemText>
                <Link to={item.link || '#'}>{`${index + 1}. ${item.text}`}</Link>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </DashContainer>
  );
};

export default DashLinkedList;
