import { Box, Grid, useTheme } from '@mui/material'
import Page from 'components/Page'
import React from 'react'
import AllPosts from 'sections/blog/AllPosts'
import DonateHeading from 'sections/donate/Heading'
import MainBlog from 'sections/blog/MainBlog'
import PopularBlogs from 'sections/blog/PopularBlogs'



export default function Donate() {


    return (
        <Page sx={{ px: 0 }} title="Donate">
            <DonateHeading />
            <Box paddingX={{ xs: '30px', sm: '10px', lg: "112px" }} sx={{ pt: '48px', pb: '50px' }} >
                <Grid container spacing={'24px'} >
                    <Grid item xs>
                        {/* <MainBlog /> */}
                    </Grid>

                    <Grid item xs>
                        {/* <PopularBlogs /> */}
                    </Grid>
                </Grid>
            </Box>
        </Page>
    )
}
