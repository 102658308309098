import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from 'ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  image: {
    width: '70%',
    height: '100%',
    objectFit: 'cover',
    filter: 'blur(15px)', // Adjust the blur radius as needed
    margin: '0 auto',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    // width: '100%',
    // height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the overlay color and opacity
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
}));

const PieChart = ({ isLoading, data, series, title }) => {
  const theme = useTheme();

  const classes = useStyles();

  if (!isLoading) {
    data.options.labels = Object.keys(series?.data ?? []);
  }

  return (
    <>
      {isLoading ? (
        <SkeletonTotalGrowthBarChart />
      ) : (
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    {/* <Grid item>
                      <Typography variant="subtitle2">Total Growth</Typography>
                    </Grid> */}
                    <Grid item>
                      <Typography variant="h5" style={{ fontWeight: '500' }}>
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {series?.data && Object.values(series.data).length > 0 ? (
                <Chart
                  options={data.options}
                  series={Object.values(series.data)}
                  type={data.type}
                  width={data.width}
                  height={data.height}
                />
              ) : (
                <img src="/static/img/donut-chart-blur.png" alt="Blurred Background" className={classes.image} />
              )}
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

PieChart.propTypes = {
  isLoading: PropTypes.bool,
};

export default PieChart;
