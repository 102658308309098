import React, { useState } from 'react';
import { t } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'redux/store';
import { Card, CardContent, CardHeader, Stack, MenuItem, InputAdornment, IconButton, TextField } from '@mui/material';
import { FormProvider, MultipleSelect, RHFSelect, RHFTextField } from 'components/hook-form';
import RHFAutocomplete from 'components/hook-form/RHFAutocomplete';
import Iconify from 'components/Iconify';

const types = ['PARTNER', 'MUNICIPAL'];

export default function AddReporter({ onSubmit, methods }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { regions: municipalities } = useSelector((state) => state.regions);

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    setValue,
    reset,
  } = methods;
  const values = watch() || {};
  return (
    <Card>
      <CardHeader title={values?.id ? 'Update User' : 'Add User'} />
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            
            <RHFSelect name="type" label="Type" placeholder="Type">
              <option value="" />
              {types?.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </RHFSelect>

            <RHFTextField name="fullName" label={`Fullname`} disabled={values?.id} />
            <RHFTextField name="username" label={t('Email')} disabled={values?.id} />
            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="confirmPassword"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFSelect name="municipal" label="Municipal">
            <option value="" />
              {municipalities?.map((municipal) => (
                <option key={municipal?.id} value={municipal?.id}>
                  {municipal?.name}
                </option>
              ))}
            </RHFSelect>

            <Stack alignItems="flex-end" sx={{ mt: 3 }} direction={{ ms: 'row' }} justifyContent="flex-end">
              <LoadingButton sx={{ width: 160, xs: 'column', sm: 'row' }} variant="text" onClick={(_) => reset({})}>
                {t('Reset')}
              </LoadingButton>
              <LoadingButton sx={{ width: 160 }} type="submit" variant="contained" loading={isSubmitting}>
                {`Submit`}
              </LoadingButton>
            </Stack>
          </Stack>
        </FormProvider>
      </CardContent>
    </Card>
  );
}
