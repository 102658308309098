import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import UserList from './UserList';

import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useDispatch, useSelector } from 'redux/store';
import { getUserList, updateUser, addUser } from 'redux/actions/users';
import AddUser from './AddUser';

export default function DataUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMountedRef();
  const dispatch = useDispatch();
  const defaultValues = {
    fullName: '',
    username: '',
    type: '',
    partner: '',
    municipal: '',
    password: '',
    confirmPassword: '',
  };
  const onSubmit = async (data) => {
  
    try {
      delete data.confirmPassword;
      const res = await dispatch(
        data?.id ? updateUser({ id: data?.id, regions: data?.regionIds }) : addUser(data)
      );
      if (res.error) {
        setError('afterSubmit', res.error);
        enqueueSnackbar(`Error while adding user.`, { variant: 'error' });
      } else {
        enqueueSnackbar(`User ${data.fullName} with type ${data.type} is added successfully.`, { variant: 'success' });
        dispatch(getUserList());
        reset(defaultValues);
      }
    } catch (error) {
      if (isMounted.current) {
        setError('afterSubmit', error);
        reset(defaultValues);
        enqueueSnackbar(`Error while adding user.`, { variant: 'error' });
      }
    }
  };

  const handleEdit = (reporter) => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    const { fullName, id, regions, username } = reporter;
    const data = { name: fullName, id, regionIds: regions.map(r => r.id), email: username };
    Object.entries(data).forEach(([key, value]) => setValue(key, value));
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(newReporterSchema),
    mode: 'onChange'
  });
  const { reset, watch, setError, setValue } = methods;
  return (
    <Stack spacing={2}>
      <AddUser onSubmit={onSubmit} methods={methods} />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <UserList onEdit={handleEdit} />
      </Stack>

    </Stack>
  );
}

const newReporterSchema = Yup.object().shape({
  fullName: Yup.string()
    .matches(/^[a-zA-Z\s]*$/, t('forms.name'))
    .required(`${t('forms.person.name')} ${t('forms.is-required')}`),
  username: Yup.string()
  .matches(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/, 'Valid email address required.').required(t('Email is required')),
  password: Yup.string()
  .min(6, 'Password must be at least 6 characters')
  .required('Password is required'),
confirmPassword: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required('Confirm Password is required'),
  municipal: Yup.string().required('Municipal is required'),
  type: Yup.string().required('Type is required'),
});
