import * as React from 'react';
import { useState } from 'react';
import { Box, Card, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import RegionCover from './RegionCover';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import Page from 'components/Page';
import { useSelector } from 'redux/store';
import Iconify from 'components/Iconify';
import ReportsList from './ReportsList';
import RegionOverview from './RegionOverview';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  justifyContent: 'center',
}));

export default function Region() {
  const params = useParams();
  const regions = useSelector((state) => state.regions?.regions);
  const { reports } = useSelector((state) => state.reports);
  const region = regions?.find((r) => r.id === parseInt(params.rId, 10));
  const { name, partner } = region;
  const [currentTab, setCurrentTab] = useState('Overview');
  const handleChangeTab = (newValue) => {
    setCurrentTab(newValue);
  };

  const PROFILE_TABS = [
    {
      value: 'Overview',
      icon: <Iconify icon={'ant-design:pie-chart-filled'} width={20} height={20} />,
      component: <RegionOverview region={region} />,
    },
    {
      value: 'Reports',
      icon: <Iconify icon={'eva:file-fill'} width={20} height={20} />,
      component: <ReportsList reports={reports} />,
    },
    {
      value: 'Community Forests',
      icon: <Iconify icon={'healthicons:community-meeting'} width={20} height={20} />,
      component: 'Published',
    },
    {
      value: 'Reporters',
      icon: <Iconify icon={'eva:people-fill'} width={20} height={20} />,
      component: 'Reporters',
    },
  ];
  return (
    <Page title="Regions ">
      <HeaderBreadcrumbs
        heading="Data Portal Users."
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Data Regions', href: PATH_DASHBOARD.dataRegions.root },
          { name },
        ]}
      />
      <Card
        sx={{
          mb: 3,
          height: 290,
          position: 'relative',
        }}
      >
        <RegionCover name={name} consortiumPartner={partner} icon={'openmoji:authority-building'} />
        <TabsWrapperStyle>
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(e, value) => handleChangeTab(value)}
          >
            {PROFILE_TABS.map((tab) => (
              <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
            ))}
          </Tabs>
        </TabsWrapperStyle>
      </Card>
      {PROFILE_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </Page>
  );
}
