import { Box, Typography} from '@mui/material'
import React from 'react'
import { styled, useTheme } from '@mui/material/styles';

function Heading() {
    const theme = useTheme();
  return (
    <>
    <Box
          paddingX={{ xs: '30px', sm: '10px', lg: '112px' }}
          sx={{ pt: '48px', pb: '20px' }}
          bgcolor={theme.palette.primary[50]}
        >
          <Typography component={'h2'} fontWeight={'600'} fontSize={'38px'} textAlign={'center'}>
            Get Involved in Our Program
          </Typography>
          <Box paddingX={{ xs: 2, md: 23 }} marginY={2}>
            <Typography fontSize={'16px'} textAlign={'center'}>
              Lorem ipsum dolor sit amet consectetur. Lacus condimentum nisi aliquet sed iaculis pharetra. Tortor sed
              sed lorem facilisis risus morbi lectus congue id. Commodo eleifend laoreet aenean dolor vulputate massa.
              Etiam et lorem justo dui in at tincidunt integer aliquam. Elementum egestas amet non interdum pretium
              ullamcorper vestibulum. Et risus elementum magnis diam turpis proin dolor bibendum.
            </Typography>
          </Box>
        </Box></>
  )
}

export default Heading