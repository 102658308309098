import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';

const IconifyIcon = ({ iconName, color, size }) => {
  return <Icon icon={iconName} color={color} height={size} />;
};

IconifyIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconifyIcon;
