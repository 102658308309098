// material-ui
import { Card, CardContent, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

// project imports
import { gridSpacing } from 'store/constant';

export default function TypographySkeleton({noOfRows}) {
    const totalRows = noOfRows?? 5;

    // Create an array with 10 elements
  const rows = Array.from({ length: totalRows }, (value, index) => index);

  return (
    <Card>
    <CardContent>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
            <Grid item xs zeroMinWidth>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Skeleton variant="text"  height={50}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
                { 
                rows && rows.map(item => (
                    <Skeleton animation="wave" key={item}  height={35}/>
                ))
                }        
                </Grid>
      </Grid>
    </CardContent>
  </Card>
      
  );
}
