import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSession } from 'utils/jwt';
import APIServices from 'apiUtils/APIServices';
import { fetchApi } from './fetchApi';

export const verifyAndSetUserProfile = createAsyncThunk('users.verifyAndSetUserProfile', async (newUser, thunkAPI) => {
  try {
    const response = await fetchApi(`auth/confirmAccount/${newUser.userId}/${newUser.confirmToken}`, 'POST', newUser);
    if (response.accessToken) {
      setSession(response.accessToken, response);
      return thunkAPI.fulfillWithValue(response);
    }
    return thunkAPI.rejectWithValue(response);
  } catch (err) {
    throw thunkAPI.rejectWithValue(err);
  }
});

export const getUserList = createAsyncThunk('users.getUsersList', async (data, thunkAPI) => {
  const api = new APIServices('admin/user');
  try {
    const { success, data: responseData } = await api.get(data);
    // alert(JSON.stringify(responseData));
    if (success) {
      return thunkAPI.fulfillWithValue(responseData);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
  return thunkAPI.rejectWithValue('Something went wrong');
});

export const addUser = createAsyncThunk('users.addUser', async (data, thunkAPI) => {
  try {
    const api = new APIServices(`admin/user`);
    const { success, data: responseData } = await api.post(data);
    if (success) {
      return thunkAPI.fulfillWithValue(responseData);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return thunkAPI.rejectWithValue('Something Went wrong.');
});

export const updateUser = createAsyncThunk('users.updateUser', async (data, thunkAPI) => {
  try {
    const api = new APIServices(`admin/user/${data?.id}`);
    delete data.id;
    const { success, data: responseData } = await api.patch(0, data);
    if (success) {
      return thunkAPI.fulfillWithValue(responseData);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
  return thunkAPI.rejectWithValue('Something Went wrong.');
});


export const uploadFile = createAsyncThunk('users.uploadFile', async (data, thunkAPI) => {
  try {
    const api = new APIServices('attachments');
    const { success, data: responseData } = await api.post(data);
    if (success) {
      return thunkAPI.fulfillWithValue(responseData);
    }
  } catch (err) {
    throw thunkAPI.rejectWithValue(err);
  }
  return thunkAPI.rejectWithValue('Something went wrong');
});

export const resetPassword = createAsyncThunk('resetPassword', async (data, thunkAPI) => {
  try {
    const api = new APIServices('auth/password-reset');
    const { success, data: responseData } = await api.post(data);
    if (success) {
      return thunkAPI.fulfillWithValue('');
    }
  } catch (err) {
    throw thunkAPI.rejectWithValue(err);
  }
  return thunkAPI.rejectWithValue('Something went wrong');
});

// export const getUserList = createAsyncThunk(
//   'users.getUserList',
//   async () => {
//     const res = await getUsersList();
//     return res;
//   }
// )
//
// export const addNewDpUser = createAsyncThunk('users.addNewDpUser', async (addInfo) => {
//   const { user, currentUser, region } = addInfo;
//   const role = currentUser?.authorities[0];
//   try {
//     const newUser = role === 'PARTNER' ? await postOperator(user): await postReporter(user, region);
//     return newUser;
//   } catch (error) {
//     throw new Error(error);
//   }
// });
//
// export const addOperator = createAsyncThunk('users.addOperator', async (addInfo) => {
//   const { user, currentUser } = addInfo;
//   const role = currentUser?.authorities[0];
//   try {
//     const newUser = role === 'PARTNER' ? await postOperator(user): await postReporter(user);
//     return newUser;
//   } catch (error) {
//     throw new Error(error);
//   }
// });
//
// export const getDatOperators = createAsyncThunk('users.getDatOperators', async () => {
//   try {
//     const res = await getDatOperatorList();
//     return res;
//   } catch (error) {
//     throw new Error(error);
//   }
// });
//
// export const getDataReporters = createAsyncThunk('users.getDataReporters', async () => {
//   try {
//     const res = await getDataReporterList();
//     return res;
//   } catch (error) {
//     throw new Error(error);
//   }
// });
