import { Grid } from '@mui/material'
import React from 'react'
import TotalIncomeDarkCard from '../../../../../pages/explore/Components/TotalIncomeDarkCard';

function EmploymentEnterprise({isLoading, employmentsData, enterpriseEmploymentData, enterprisesData  }) {
  return (
    <Grid container  style={{marginTop: '5px'}} spacing={{ xs: 2, md: 5 }} rowSpacing={5}> {/* Wet/Human/Growing */}
    <Grid item xs={12} sm={6} md={4}>
        <TotalIncomeDarkCard isLoading={isLoading}  title={`Employements`} icon={   {
        "title": "employment",
        "iconName":"uit:bag",
        "color":"#70C290",
        "size": ""

    }} value={employmentsData} bgColor={'#E6F4EB'} textColor={`#000`}  />
    </Grid>
    <Grid item xs={12} sm={6} md={4} >
        <TotalIncomeDarkCard isLoading={isLoading} title={`Employement by Enterprises`} icon={{
         "title": "enterprise",
         "iconName":"carbon:enterprise",
         "color":"#efbe5e",
         "size": ""

    }} value={enterpriseEmploymentData} bgColor={`#ccd7f5`} textColor={`#000`} />
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
        <TotalIncomeDarkCard isLoading={isLoading} title={`Enterprise Number`} icon={{
           "title": "forest-enterprise",
           "iconName":"uit:bag",
           "color":"#ccd7f5",
           "size": ""

    }} value={enterprisesData} bgColor={'#f3dfb8'} textColor={`#000`} />
    </Grid>
</Grid>
  )
}

export default EmploymentEnterprise