import React from 'react';
import { t } from 'i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  InputAdornment,
  Tooltip,
} from '@mui/material';

import { statuses } from '.';

import { RHFSelect, RHFRadioGroup, RHFTextField } from 'components/hook-form';
import { nepaliEthnicityB } from 'dataTypes';
import { LabelStyle } from '../StyledComponents';
import Iconify from 'components/Iconify';

const BurnOutDetail = ({ values }) => (
  <>
    <Typography variant={'h6'} padding={1}>
      8. Fire Hazards
    </Typography>
    <Stack spacing={2} paddingX={3}>
      <RHFRadioGroup name="hasFireOccurred" options={[true, false]} getOptionLabel={statuses} />
      {values?.hasFireOccurred === 'true' ? (
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={2}
          paddingY={2}
        >
          <RHFTextField
            name="fireBurntOut.burntOutCount"
            label="Fire Hazards Count (in last 3 years)"
            fullWidth
            type="number"
            min={0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Iconify icon="mdi:fire" />
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            name="fireBurntOut.affectedArea"
            label="Affected Area"
            fullWidth
            type="number"
            min={0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography color={'text.secondary'}>HA</Typography>
                </InputAdornment>
              ),
            }}
          />
          <RHFSelect
            name="fireDamages.type"
            label="Fire Type"
            value={values?.fireDamages?.type}
            placeholder="Fire Type"
          >
            <option value="" />
            {fireTypes.map((option, i) => (
              <option key={i} value={option.key}>
                {option.label}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect name="fireDamages.naturalCause" label="Burnt out Reason" placeholder="Burnt Out Reason">
            <option value="" />
            {burntOutReason.map((option, i) => (
              <option key={i} value={option.key}>
                {option.label}
              </option>
            ))}
          </RHFSelect>
        </Stack>
      ) : null}
    </Stack>
    <br />
    {values?.hasFireOccurred === 'true' ? (
      <>
        <Typography variant={'h6'} padding={1}>
          9. Damage details caused by Fire
        </Typography>
        <Stack spacing={2} paddingX={3}>
          <LabelStyle paddingX={2}>Humanitarian damage</LabelStyle>
          <TableContainer
            sx={{
              my: 1,
            }}
          >
            <Table>
              <TableHead>
                <TableCell>Ethnicity</TableCell>
                <TableCell>Male</TableCell>
                <TableCell>Female</TableCell>
                <TableCell>Others</TableCell>
              </TableHead>
              <TableBody>
                {nepaliEthnicityB.map((wc, i) => (
                  <TableRow key={i}>
                    <TableCell
                      sx={{
                        minWidth: 250,
                      }}
                    >
                      {wc.name}
                    </TableCell>
                    <TableCell colSpan={1}>
                      <RHFTextField
                        label={t('Number')}
                        type="number"
                        min={0}
                        name={`fireDamages.humanLosses.${i}.maleCount`}
                      />
                    </TableCell>
                    <TableCell>
                      <RHFTextField
                        label={t('Number')}
                        type="number"
                        min={0}
                        name={`fireDamages.humanLosses.${i}.femaleCount`}
                      />
                    </TableCell>
                    <TableCell>
                      <RHFTextField
                        label={t('Number')}
                        type="number"
                        min={0}
                        name={`fireDamages.humanLosses.${i}.othersCount`}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={2}
          >
            <Stack width={'25%'}>
              <LabelStyle>Homes</LabelStyle>
              <RHFTextField
                label={t('Number')}
                fullWidth
                name={`fireDamages.otherLosses[0].quantity`}
                type="number"
                min={0}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Iconify icon="mdi:home" />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width={'25%'}>
              <LabelStyle>Pets</LabelStyle>
              <RHFTextField
                label={t('Number')}
                fullWidth
                name={`fireDamages.otherLosses[1].quantity`}
                type="number"
                min={0}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Iconify icon="mdi:cow" />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width={'25%'}>
              <LabelStyle>Agricultural Damage [Quintal]</LabelStyle>
              <RHFTextField
                label={t('Number')}
                fullWidth
                name={`fireDamages.otherLosses[2].quantity`}
                type="number"
                min={0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color={'text.secondary'}>Q</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack width={'25%'}>
              <Tooltip title="Agricultural Damage ( Hectare )">
                <LabelStyle>Agricultural Damage</LabelStyle> 
              </Tooltip>
              <Tooltip title="Agricultural Damage ( Hectare )">
                <RHFTextField
                  label={t('Number')}
                  fullWidth
                  name={`fireDamages.otherLosses[3].quantity`}
                  type="number"
                  min={0}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography color={'text.secondary'}>HA</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                 </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </>
    ) : null}
  </>
);

export default BurnOutDetail;

const fireTypes = [
  {
    key: 'TOP_BURNT_OUT',
    label: 'Crown',
  },
  { key: 'SURFACE_BURNT_OUT', label: 'Surface' },
  { key: 'GROUND_BURNT_OUT', label: 'Ground' },
];
const burntOutReason = [
  {
    key: true,
    label: 'Natural Fire',
  },
  { key: false, label: 'Man made' },
  { key: false, label: 'खोरिया फडनी' },
  { key: false, label: 'पालुवा पलाउन' },
  { key: false, label: 'रुख दाउरा' },
  { key: false, label: 'पड्के चियाउ र बनभोज' },
];

