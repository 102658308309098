import React, { useState, useEffect } from 'react';

const useCountUp = ({ start, end=0, duration = 2, interval = 50, separator = ',' }) => {
  const [count, setCount] = useState(start);
  const increment = (end - start) / (duration * 1000 / interval);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count < end) {
        setCount((prevCount) => prevCount + increment);
      } else {
        setCount(end);
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer); // Cleanup the interval on component unmount

  }, [count, end, increment, interval]);

  return count.toLocaleString();
};

export default useCountUp;