import { Stack } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import Page from 'components/Page';
import AddOperator from './AddOperator';
import OperatorList from './OperatorList';

export default function DataOperators() {
  return (
    <Page title="CF Data Portal Users">
      <HeaderBreadcrumbs
        heading="Data Portal Operators"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Users' }, { name: 'Operators' }]}
      />
      <Stack spacing={4}>
        <AddOperator />
        <OperatorList />
      </Stack>
    </Page>
  );
}
