import { IconButton } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const SocialMediaIcons = styled(IconButton)(({ theme }) => ({
  borderRadius: '100%',
  padding: '8px',
  backgroundColor: theme.palette.primary[400],
  transitionDuration: '.5s',
  '& img': {
    transitionDuration: '.5s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary[400],
  },
}));

const socialLinks = [
  {
    to: 'https://www.facebook.com/cfdataportal',
    imgScr: '/icons/facebook.svg',
    name: 'Facebook'
  },
  {
    to: 'https://www.linkedin.com/company/community-forest-data-portal',
    imgScr: '/icons/linkedin-fill.svg',
    name: 'LinkedIn'
  },
  {
    to: '#',
    imgScr: '/icons/twitter.svg',
    name: 'Twitter'
  },
];

const Social = () => {
  return (
    <>
      {socialLinks.map((link) => (
        <SocialMediaIcons
          key={link.name}
          component="a"
          href={link.to} // Absolute path
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={link.imgScr} alt={link.name} width={'20px'} />
        </SocialMediaIcons>
      ))}
    </>
  );
};

export default Social;
