import React from 'react';
import './styles.css'
import { Badge, Card, CardContent, Typography } from '@mui/material';

const ForestPopup = ({details}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', background: '#FFFFFF00', alignItems: 'center'}}>
      <Card style={{width: 300}}>
        <CardContent style={{ paddingBottom: -30 }}>
          <Typography variant='h5'>{details.name}</Typography>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{`ID: `}</Typography>
            <Typography style={{ fontSize: 14, marginLeft: 5 }}>{details.id}</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{`CFUG Code: `}</Typography>
            <Typography style={{ fontSize: 14, marginLeft: 5 }}>{details.cfugCode}</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{`Area: `}</Typography>
            <Typography style={{ fontSize: 14, marginLeft: 5 }}>{`${details.area} Ha.`}</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2, }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{`Province: `}</Typography>
            <Typography style={{ fontSize: 14, marginLeft: 5 }}>{details.province}</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2, marginBottom: -20 }}>
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{`Status: `}</Typography>
            <Badge style={{ fontSize: 14, marginLeft: 5 }}>{details.status}</Badge>
          </div>
        </CardContent>
        <div style={{ width: 30, height: 30 }} />
      </Card>
      <div style={{
        width: 20,
        height: 20,
        background: '#FFFFFF',
        borderRadius: 0,
        transform: 'rotate(45deg)',
        marginTop: -10,
      }} />
    </div>

  );
};

export default ForestPopup;
