// @mui
import { styled } from '@mui/material/styles';
import { Alert, Box, Card, Container } from '@mui/material';
// routes
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
import useResponsive from 'hooks/useResponsive';
// components
import Page from 'components/Page';
import Logo from 'components/Logo';
import Image from 'components/Image';
// sections
import { LoginForm } from '../../../sections/auth/login';
import NoAccount from './NoAccount';
import LoginInfo from './LoginIntro';
import OtherWaysToLogin from './OtherWaysToLogin';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));


const SectionStyle = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '60px 30px'
}));

// ----------------------------------------------------------------------

Login.propTypes = {
  t: PropTypes.func.isRequired,
};

function Login({ t }) {
  const auth = useAuth();
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login" sx={{px:0}}>
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Image alt="login" src="/static/bg/png/group_plantation.png" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <LoginInfo />
            {auth.error && (
              <>
                <Alert severity="error" variant="outlined" sx={{ mt: 2 }}>
                  {t('auth.login-error')}
                </Alert>
                <br />
              </>
            )}
            <LoginForm />
            {/* <OtherWaysToLogin /> */} {/* TODO: Social Media login  */}
            {/* <NoAccount /> */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

export default withTranslation()(Login);
